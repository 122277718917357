<template>
    <div :class="cssClass">
        <input type="button" :class="cssClassButton" :value="libelleTraduit" :disabled="isDisabled" />
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'

    interface IPropsButton {
        text?: string
        disabled?: boolean
        cssClass?: string
        cssClassButton?: string
    }

    const props = withDefaults(defineProps<IPropsButton>(), {
        text: '',
        disabled: false,
        cssClass: '',
        cssClassButton: '',
    })

    const isDisabled = ref(props.disabled)

    const libelleTraduit = computed(function (): string {
        return i18n.t(props.text)
    })
</script>
