<template>
    <div :class="cssClass" v-show="visible">
        <cogi-label :required="required" :label="label"></cogi-label>
        <input type="email" :class="{ cogirequired: required, 'cogi-error': hasError }" v-model="text" placeholder="utilisateur@yahoo.com" @blur="blur" />
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsTelephone {
        required?: boolean
        modelValue?: string
        valideNow?: boolean
        label: string
        cssClass?: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsTelephone>(), {
        required: false,
        valideNow: false,
        label: '',
        cssClass: '',
        visible: true,
    })

    const hasError = ref(false)
    const text = ref(props.modelValue)

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [string]
    }>()

    function blur() {
        hasError.value = false
    }
    function validate() {
        hasError.value = false

        if (!props.visible) {
            emitValidated(false)
            return
        }

        var emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (props.required && !props.modelValue) {
            hasError.value = true
        } else if (!props.required && !props.modelValue) {
            hasError.value = false
        } else {
            if (!emailRegex.test(String(props.modelValue).toLowerCase())) {
                hasError.value = true
            }
        }
        emitValidated(hasError.value)
    }
    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    watch(
        () => text.value,
        newValue => {
            emit('update:modelValue', newValue as string)
        },
    )

    watch(
        () => props.modelValue,
        newValue => {
            text.value = newValue as string
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (newValue) validate()
        },
    )
</script>
