<template>
    <div ref="template">
        <div class="cogi-subtitle">
            <cogi-label-only label="PiecesJustificativesAJoindre" />
            <cogi-info id="infoPieceJustificative" :dontTranslate="true" :tooltip="tooltipPiecesJustificatives"></cogi-info>
        </div>

        <div class="grid">
            <cogi-piecejustificative class="grid-full" v-model="pieceJustificativePocos"></cogi-piecejustificative>

            <hr class="grid-full" />
            <cogi-fileupload
                cssClass="grid-full"
                v-model="files"
                :fichiersJointsMaxBytes="modelData.fichiersJointsMaxBytes"
                :fichierJointMaxLongueurNom="modelData.fichierJointMaxLongueurNom"></cogi-fileupload>
        </div>

        <div class="cogi-subtitle">
            <cogi-label-only label="Commentaires" />
        </div>

        <div class="grid">
            <cogi-label cssClass="grid-full" label="SiVotreConditionNecessiteUnSoutient"></cogi-label>
            <textarea class="grid-full" cols="50" rows="10" v-model="modelData.encadrementNecessaire"></textarea>

            <cogi-label cssClass="grid-full" label="PourToutAutreCommentaireRelatifAVotreDemande"></cogi-label>
            <textarea class="grid-full" cols="50" rows="10" v-model="modelData.autreCommentaire"></textarea>
        </div>

        <cogi-yesno
            cssClass="cogi-subtitle"
            @validated="validated"
            :valideNow="valideNow"
            :required="true"
            v-model="etesVousDemandeur"
            label="CompletezVousCetteDemandePourUneTiercePersonne"></cogi-yesno>

        <div class="grid">
            <cogi-regex
                cssClass="grid-half"
                :visible="showPanelTiercePersonne"
                @validated="validated"
                :valideNow="valideNow"
                label="VotrePrenom"
                :required="true"
                v-model="modelData.prenomDemandeurPour"></cogi-regex>
            <cogi-regex
                cssClass="grid-half"
                :visible="showPanelTiercePersonne"
                @validated="validated"
                :valideNow="valideNow"
                label="VotreNom"
                :required="true"
                v-model="modelData.nomDemandeurPour"></cogi-regex>
            <cogi-dropdownlist
                cssClass="grid-half"
                :culture="modelData.culture"
                :visible="showPanelTiercePersonne"
                class="grid-half"
                @validated="validated"
                :valideNow="valideNow"
                label="VotreLienAvecLeDemandeur"
                :required="true"
                :showEmptyItem="true"
                v-model="modelData.lienDemandeurPour"
                :sourceItems="sourceItemLienDemandeur"></cogi-dropdownlist>
            <cogi-telephone
                cssClass="grid-half"
                :required="true"
                :visible="showPanelTiercePersonne"
                @validated="validated"
                label="VotreTelephone"
                v-model="modelData.telephoneDemandeurPour"
                :valideNow="valideNow"></cogi-telephone>
            <cogi-email
                cssClass="grid-half"
                :visible="showPanelTiercePersonne"
                label="VotreAdresseCourriel"
                v-model="modelData.courrielDemandeurPourEmail"></cogi-email>
            <cogi-label
                v-show="showPanelTiercePersonne"
                cssClass="gridspan13"
                cssClassLabel="text-plus-gros"
                label="JeConfirmeAvoirEuLautorisation"></cogi-label>
            <cogi-checkbox
                cssClass="gridspan3 gras"
                :required="true"
                :visible="showPanelTiercePersonne"
                @validated="validated"
                :valideNow="valideNow"
                label="JAccepte"></cogi-checkbox>
        </div>

        <div class="cogi-subtitle">
            <cogi-label-only label="AttestationEtAutorisationDeVerification" />
        </div>

        <div class="grid">
            <cogi-label cssClass="gridspan13" cssClassLabel="text-plus-gros" label="JeCertifieQueLesRenseignementSontVeridiques"></cogi-label>
            <cogi-checkbox
                cssClass="gridspan3 gras"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                label="JAccepte"
                v-model="modelData.accepteRenseignements"></cogi-checkbox>
            <hr class="grid-full" />
            <cogi-label cssClass="gridspan13" cssClassLabel="text-plus-gros" :dontTranslate="true" :label="getDescriptionOffice()"></cogi-label>
            <cogi-checkbox
                cssClass="gridspan3 gras"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                label="JAccepte"
                v-model="modelData.accepteVerificationsOmh"></cogi-checkbox>
            <hr class="grid-full" />
            <cogi-label cssClass="grid-full" cssClassLabel="text-plus-gros" label="TouteDeclarationFausseOuTrompeuse"></cogi-label>
        </div>

        <div class="grid">
            <cogi-button cssClass="gridspan15 right" cssClassButton="precedent" text="EtapePrecedente" @click="precedent"></cogi-button>
            <cogi-button cssClass="gridspan1 right" cssClassButton="suivant" text="SoumettreVotreDemande" @click="soumettreclick"></cogi-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, getCurrentInstance } from 'vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { PieceJustificativePoco, PieceJustificativeEmitPoco } from './pocos/PieceJustificativePoco'
    import { i18n } from '../../../locales/i18n-wc'

    import CogiRegex from './CogiRegex.vue'
    import CogiDropdownlist from './CogiDropdownlist.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiButton from './CogiButton.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import CogiTelephone from './CogiTelephone.vue'
    import CogiInfo from './CogiInfo.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import CogiYesno from './CogiYesno.vue'
    import CogiEmail from './CogiEmail.vue'
    import CogiPiecejustificative from './CogiPiecejustificative.vue'
    import CogiFileupload from './CogiFileupload.vue'
    import type { del } from '../clients/delclient'

    interface IPropsCogiEtape5 {
        modelValue: any
        sourceItemPointServices: del.ListItem[]
        sourceItemLienDemandeur: del.ListItem[]
    }

    const props = withDefaults(defineProps<IPropsCogiEtape5>(), {})

    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const modelData = ref(props.modelValue)
    const hasError = ref(false)
    const etesVousDemandeur = ref(undefined)
    const showPanelTiercePersonne = ref(false)
    const valideNow = ref(false)
    const isInsuivantMode = ref(false)
    const validateCallback = ref(defaultValidateCallback)

    const files = defineModel<Array<File>>('files')

    const emit = defineEmits<{
        reculerUnEtape: [number]
        soumettre: [number]
    }>()

    const pieceJustificativePocos = computed({
        get() {
            return getPieceJustificativePocos()
        },
        set(emitMessage: any) {
            if (!emitMessage.Id) {
                if (emitMessage.propertyChanged == PieceJustificativeEmitPoco.propertyPiecePreuveAge)
                    modelData.value.possedePiecePreuveAge = !modelData.value.possedePiecePreuveAge
                if (emitMessage.propertyChanged == PieceJustificativeEmitPoco.propertyPieceRevenus)
                    modelData.value.possedePieceRevenus = !modelData.value.possedePieceRevenus
                return
            }

            let personsModifie: del.Person[] = modelData.value.persons.filter(function (item: del.Person) {
                return item.id == emitMessage.Id
            })

            if (personsModifie.length <= 0) return

            let person: del.Person = personsModifie[0]

            if (emitMessage.propertyChanged == PieceJustificativeEmitPoco.propertyPiecePreuveAge) person.possedePiecePreuveAge = !person.possedePiecePreuveAge
            if (emitMessage.propertyChanged == PieceJustificativeEmitPoco.propertyPieceRevenus) person.possedePieceRevenus = !person.possedePieceRevenus
        },
    })

    const tooltipPiecesJustificatives = computed(() => {
        return (
            i18n.t('PourConnaitreLaListeDesPiecesJustificatives') +
            "<a target='_blank' href='" +
            modelData.value.dicUrlDocumentPiecesJustificatives['fr'] +
            "'> " +
            i18n.t('ConsulterCeDocument') +
            '</a>'
        )
    })

    const nomOrganisme = computed(() => {
        return modelData.value.dicNomOrganisme[modelData.value.culture]
    })

    function defaultValidateCallback(newValue: boolean) {}

    function getDescriptionOffice() {
        return i18n.t('JAutorise') + ' ' + nomOrganisme.value + ' ' + i18n.t('AEffectuerToutesLesVerifications')
    }

    function precedent() {
        emit('reculerUnEtape', 4)
    }
    const instance = getCurrentInstance()

    function validate() {
        hasError.value = false

        if (!modelData.value.pointService || !etesVousDemandeur.value) {
            hasError.value = true
        }

        valideNow.value = validatorAccumulator.value.startValidation(instance)
    }

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        hasError.value = validationStatut.hasError
        valideNow.value = false
        if (hasError.value) return

        soumettre()
        validateCallback.value(hasError.value)
        validateCallback.value = defaultValidateCallback
    }

    function soumettreclick() {
        isInsuivantMode.value = true
        validate()
    }

    function soumettre() {
        if (!isInsuivantMode.value) return

        isInsuivantMode.value = false
        if (hasError.value) return
        else emit('soumettre', 5)
    }

    function getPieceJustificativePocos() {
        let pieceJustificativePocos: PieceJustificativePoco[] = []

        pieceJustificativePocos.push(
            createPieceJustificativePoco(
                '',
                modelData.value.prenom,
                modelData.value.nom,
                modelData.value.possedePiecePreuveAge,
                modelData.value.possedePieceRevenus,
            ),
        )

        let persons: del.Person[] = modelData.value.persons

        persons.forEach((person: del.Person) =>
            pieceJustificativePocos.push(
                createPieceJustificativePoco(person.id!, person.prenom!, person.nom!, person.possedePiecePreuveAge!, person.possedePieceRevenus!),
            ),
        )

        return pieceJustificativePocos
    }

    function createPieceJustificativePoco(Id: string, prenom: string, nom: string, piecePreuveAge: boolean, pieceRevenus: boolean) {
        let pieceJustificativePoco: PieceJustificativePoco = new PieceJustificativePoco()
        pieceJustificativePoco.Id = Id
        pieceJustificativePoco.prenom = prenom
        pieceJustificativePoco.nom = nom
        pieceJustificativePoco.piecePreuveAge = piecePreuveAge
        pieceJustificativePoco.pieceRevenus = pieceRevenus
        pieceJustificativePoco.piecePreuveAge = pieceJustificativePoco.pieceRevenus = false
        return pieceJustificativePoco
    }

    watch(
        () => etesVousDemandeur.value,
        newValue => {
            showPanelTiercePersonne.value = newValue == '1'
        },
    )
</script>
