<template>
    <div :class="cssClass">
        <span class="invisible">
            <input type="file" ref="myFiles" v-on:change="loadSelectedFiles" multiple />
        </span>

        <div class="grid">
            <template v-for="(file, index) in model" :key="index">
                <cogi-label cssClass="grid-begining-rest" :label="file.name" :dontTranslate="true"></cogi-label>
                <cogi-button cssClass="grid-end" cssClassButton="effacer" text="Effacer" v-on:click="deleteFile(index)"></cogi-button>
            </template>
            <cogi-label cssClass="grid-begining-rest" label="AucunFichierChoisi" :onClick="parcourirClick"></cogi-label>
            <cogi-button cssClass="grid-end" cssClassButton="parcourir" text="Parcourir" v-on:click="parcourirClick"></cogi-button>
            <cogi-label cssClass="grid-full redText" label="FichierJointExtensionInvalide" v-if="aFichierExtensionInvalide"></cogi-label>
            <cogi-label
                cssClass="grid-full redText"
                label="FichierJointNomTropLong"
                :labelParametres="[fichierJointMaxLongueurNom]"
                v-if="aFichierNomTropLong"></cogi-label>
            <cogi-label
                cssClass="grid-full redText"
                label="FichierJointTailleTropGrosse"
                :labelParametres="[fichierJointMaxMegBytes]"
                v-if="aFichierTailleInvalide"></cogi-label>
            <cogi-label cssClass="grid-full redText" label="FichiersJointsTailleTropGrosse" v-if="aFichiersTailleInvalide"></cogi-label>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiButton from './CogiButton.vue'

    interface IPropsFileUpload {
        cssClass: string
        fichierJointMaxBytes?: number
        fichiersJointsMaxBytes: number
        fichierJointMaxLongueurNom: number
    }

    const props = withDefaults(defineProps<IPropsFileUpload>(), {
        cssClass: '',
        fichiersJointsMaxBytes: 41943040, // 40 MB
        fichierJointMaxLongueurNom: 104,
    })

    const model = defineModel<Array<File>>()

    const myFiles = ref(null)

    const aFichierExtensionInvalide = ref(false)
    const aFichierTailleInvalide = ref(false)
    const aFichiersTailleInvalide = ref(false)
    const aFichierNomTropLong = ref(false)

    const fichierJointMaxBytesOrDefault = computed(() => {
        return props.fichierJointMaxBytes ?? props.fichiersJointsMaxBytes
    })

    const fichierJointMaxMegBytes = computed(() => {
        return Number(fichierJointMaxBytesOrDefault.value) / 1048576
    })

    function getExtensionsValide() {
        return [
            'pdf',
            'xls',
            'doc',
            'dot',
            'docx',
            'dotx',
            'docm',
            'dotm',
            'xlt',
            'xla',
            'xlsx',
            'xltx',
            'xlsm',
            'xltm',
            'xlam',
            'xlsb',
            'ppt',
            'pot',
            'pps',
            'ppa',
            'pptx',
            'potx',
            'ppsx',
            'ppam',
            'pptm',
            'potm',
            'ppsm',
            'wav',
            'jpg',
            'jpeg',
            'gif',
            'png',
        ]
    }

    function getFiles() {
        return (myFiles.value as unknown as HTMLInputElement).files as FileList
    }

    function loadSelectedFiles() {
        enleverErreurs()
        if (!validerFiles(getFiles())) return
        for (let i = 0; i < getFiles().length; i++) {
            if (validerFile(getFiles()[i])) model.value!.push(getFiles()[i])
        }
        // On enlève les fichiers à l'élément "<input type="file" ..." pour éviter que Chrome empêche
        // l'ajout d'un fichier déjà ajouté, à l'occasion (DL-374).
        ;(myFiles.value as unknown as HTMLInputElement).value = ''
    }

    function validerFiles(filesAjoutes: FileList) {
        let tailleTotale = 0

        for (let i = 0; i < model.value!.length; i++) {
            tailleTotale += model.value![i].size
        }
        for (let i = 0; i < filesAjoutes.length; i++) {
            tailleTotale += filesAjoutes[i].size
        }

        if (tailleTotale > props.fichiersJointsMaxBytes) {
            aFichiersTailleInvalide.value = true
            return false
        }

        return true
    }

    function validerFile(file: File) {
        let estValide: boolean = true

        if (!isExtensionValide(file.name)) {
            aFichierExtensionInvalide.value = true
            estValide = false
        }

        if (file.size > Number(fichierJointMaxBytesOrDefault.value)) {
            aFichierTailleInvalide.value = true
            estValide = false
        }

        if (file.name.length > props.fichierJointMaxLongueurNom) {
            aFichierNomTropLong.value = true
            estValide = false
        }

        return estValide
    }

    function deleteFile(index: number) {
        enleverErreurs()
        model.value!.splice(index, 1)
    }

    function parcourirClick() {
        ;(myFiles.value as unknown as HTMLInputElement).click()
    }

    function isExtensionValide(filename: string) {
        const matchs = filename.match(/\.([^.]+)$/)
        if (matchs === null || matchs.length < 1) return false
        const ext = matchs[1].toLocaleLowerCase()
        return getExtensionsValide().indexOf(ext) >= 0
    }

    function enleverErreurs() {
        aFichierExtensionInvalide.value = false
        aFichierTailleInvalide.value = false
        aFichiersTailleInvalide.value = false
        aFichierNomTropLong.value = false
    }
</script>
