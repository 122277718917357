<template>
    <div class="grid" ref="template">
        <div class="grid-full cogi-subtitle">
            <cogi-label-only label="Territoires" />
        </div>

        <a class="grid-full-margin" target="_blank" :href="urlDocumentTerritoires" v-if="aUrlDocumentTerritoires">
            <cogi-image :image="imageTerritoire" />
        </a>
        <cogi-image v-else :image="imageTerritoire" class="grid-full-margin" />

        <cogi-checkboxgroup
            :culture="modelData.culture"
            cssClass="grid-full"
            :required="true"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData.territoiresSelectionnes"
            label="SelectionnezUnOuPlusieursTerritoires"
            :sourceItems="sourceItemsTerritoire"
            :codeVisible="true"
            :tousVisible="true"
            :nbColonnesAffichage="3"></cogi-checkboxgroup>

        <div class="grid-full cogi-subtitle" v-show="utiliseAuMoinsUnModeleHabitation">{{ choixDeModelesDhabitationLabel }}</div>
        <cogi-modelehabitation
            :culture="modelData.culture"
            :visible="utiliseAuMoinsUnModeleHabitation"
            cssClass="grid-full"
            :required="true"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData"
            :sourceItemsResponsabiliteCoop="sourceItemsResponsabiliteCoop"
            :sourceItemsImplicationOrganisme="sourceItemsImplicationOrganisme"
            :sourceItemsServiceDesires="sourceItemsServiceDesires"></cogi-modelehabitation>

        <cogi-button cssClass="gridspan15 right" cssClassButton="precedent" text="EtapePrecedente" @click="precedent"></cogi-button>
        <cogi-button cssClass="gridspan1 right" cssClassButton="suivant" text="EtapeSuivante" @click="suivant"></cogi-button>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, getCurrentInstance } from 'vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { i18n } from '../../../locales/i18n-wc'
    import type { del } from '../clients/delclient'

    import CogiLabelOnly from './CogiLabelOnly.vue'
    import CogiButton from './CogiButton.vue'
    import CogiCheckboxgroup from './CogiCheckboxgroup.vue'
    import CogiImage from './CogiImage.vue'
    import CogiModelehabitation from './CogiModelehabitation.vue'

    interface IPropsCogiEtape4 {
        sourceItemsTerritoire: del.ListItem[]
        modelValue: any
        sourceItemsImplicationOrganisme: del.ListItem[]
        sourceItemsServiceDesires: del.ListItem[]
        sourceItemsResponsabiliteCoop: del.ListItem[]
    }

    const props = withDefaults(defineProps<IPropsCogiEtape4>(), {})

    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const modelData = ref(props.modelValue)
    const hasError = ref(false)
    const valideNow = ref(false)
    const isInsuivantMode = ref(false)
    const validateCallback = ref(defaultValidateCallback)

    const emit = defineEmits<{
        avancerUnEtape: [number]
        reculerUnEtape: [number]
    }>()

    const aUrlDocumentTerritoires = computed(() => {
        return modelData.value.dicUrlDocumentTerritoires['fr']
    })

    const utiliseAuMoinsUnModeleHabitation = computed(() => {
        return modelData.value.UtiliseProgrammeHlm || modelData.value.UtiliseProgrammeCoop || modelData.value.UtiliseProgrammeObnl
    })

    const urlDocumentTerritoires = computed(() => {
        return modelData.value.dicUrlDocumentTerritoires['fr']
    })

    const imageTerritoire = computed(() => {
        return modelData.value.dicImageTerritoire[modelData.value.culture]
    })

    const choixDeModelesDhabitationLabel = computed(() => {
        return i18n.t('ChoixDeModelesDhabitation')
    })

    function defaultValidateCallback(newValue: boolean) {}

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        hasError.value = validationStatut.hasError
        valideNow.value = false
        if (hasError.value) return

        doSuivant()
        validateCallback.value(hasError.value)
        validateCallback.value = defaultValidateCallback
    }

    function suivant() {
        isInsuivantMode.value = true
        validate()
    }

    function doSuivant() {
        if (!isInsuivantMode.value) return

        isInsuivantMode.value = false
        if (hasError.value) return
        else {
            emit('avancerUnEtape', 3)
        }
    }

    function precedent() {
        emit('reculerUnEtape', 3)
    }

    const instance = getCurrentInstance()
    function validate() {
        hasError.value = false
        valideNow.value = validatorAccumulator.value.startValidation(instance)
    }
</script>
