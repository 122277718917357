<template>
    <span>
        <label>{{ libelleTraduit }}</label>
    </span>
</template>
<script setup lang="ts">
    import { computed } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'

    export interface IPropsLabelOnly {
        label: string
        dontTranslate?: boolean
        labelParameters?: object
    }

    const props = withDefaults(defineProps<IPropsLabelOnly>(), {
        label: '',
        dontTranslate: false,
    })

    const libelleTraduit = computed(() => {
        return props.dontTranslate ? props.label : i18n.t(props.label, props.labelParameters)
    })
</script>
