<template>
    <div :class="cssClass" v-show="visible">
        <div class="grid">
            <cogi-checkbox
                v-for="item in model"
                :key="item.valeur"
                :cssClass="item.css"
                :label="item.label"
                :id="item.valeur"
                v-model="item.selectionne"
                :hasError="hasError"></cogi-checkbox>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import type { CheckboxlistPoco } from '@del/shared/pocos/CheckboxlistPoco'
    import CogiCheckbox from './CogiCheckbox.vue'

    interface IPropsCheckBoxList {
        required?: boolean
        cssClass?: string
        valideNow?: boolean
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsCheckBoxList>(), {
        required: false,
        cssClass: '',
        validateNoe: false,
        visible: true,
    })

    const model = defineModel<CheckboxlistPoco[]>()

    const hasError = ref(false)

    const emit = defineEmits<{
        validated: [boolean]
    }>()

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )

    function validate() {
        hasError.value = false

        if (!props.visible) {
            emitValidated(false)
            return
        }

        hasError.value = (<CheckboxlistPoco[]>model.value).every(function (item: CheckboxlistPoco) {
            return !item.selectionne
        })

        emitValidated(hasError.value)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }
</script>
