import { defineStore } from 'pinia'

interface State {
    username: string
    email: string,
    state: string,
    codeOrganisme: string
    noSuccursale: number
    lang: string
    codeChallenge: string  
    nextRoute: string  
}

export const authStore = defineStore('auth', {
    state: (): State => ({
        username: '',        
        email: '',
        state: '',
        codeOrganisme: '003055', //todo aller chercher avec le token
        noSuccursale: 230, //todo aller chercher avec le token
        lang: 'fr', //todo aller chercher avec le token
        codeChallenge: '',        
        nextRoute: ''
    }),
    persist: {
        storage: sessionStorage,
    },
    getters: {
        isAuthenticated: (state: State) => state.username !== null && state.username.length > 1,
    },
    actions: {        
        pushNextRoute(route: string){
          this.nextRoute = route;
        },
        connect(username : string, email: string, state : string) {            
            this.username = username
            this.email = email;
            this.state = state;
        },
        disconnect() {
            this.username = ''
        },
        switchLang() {
            const newLang = this.lang === 'fr' ? 'en' : 'fr'
            this.lang = newLang
        }        
    },
})
