<template>
    <span :class="cssClass">
        <span ref="parentDiv">
            <span class="cogiTooltip loge info loge-info" title="" v-on:mouseenter="mouseEnter()" v-on:mouseleave="mouseLeave($event)"></span>
        </span>
    </span>
</template>
<script setup lang="ts">
    import tippy from 'tippy.js'
    import type { Props } from 'tippy.js'
    import { i18n } from '../../../locales/i18n-wc'
    import { onMounted, ref, reactive, watch, computed } from 'vue'

    interface ITippy {
        show(): void
        hide(): void
    }

    class ElementWithTippy {
        _tippy!: ITippy
    }

    const parentDiv = ref(<HTMLSpanElement | null>null)
    let virtualReference = ref(<HTMLDivElement | ElementWithTippy | null>null)

    interface IPropsInfo {
        tooltip: string
        showtext?: boolean
        cssClass?: string
        dontTranslate?: boolean
    }

    const props = withDefaults(defineProps<IPropsInfo>(), {
        tooltip: '',
        showtext: false,
        cssClass: '',
        dontTranslate: false,
    })

    const cursorVisited = ref(<boolean>false)
    const options = reactive({
        animation: 'fade',
        arrow: true,
        allowHTML: true,
        delay: [100, 1000],
        interactive: true,
        interactiveBorder: 400,
        inertia: false,
        content: {} as HTMLElement,
        popperOptions: {
            modifiers: [
                {
                    name: 'preventOverflow',
                    enabled: false,
                },
                {
                    name: 'hide',
                    enabled: false,
                },
            ],
        },
        maxWidth: '350px',
        trigger: 'manual',
    } as Partial<Props>)

    const tooltipTraduit = computed(() => {
        if (props.dontTranslate) return props.tooltip

        return i18n.t(props.tooltip)
    })

    onMounted(() => {
        virtualReference.value = document.createElement('div') as HTMLDivElement
        parentDiv.value!.appendChild(virtualReference.value)
        ;(virtualReference.value as HTMLDivElement).style.width = '30'

        options.content = createContent()
        tippy(virtualReference.value as Element, options as Partial<Props>)
    })

    watch(tooltipTraduit, newValue => {
        ;(options.content! as HTMLElement).innerHTML = newValue
    })

    function mouseEnter() {
        updatePosition()
        ;(virtualReference.value as ElementWithTippy)._tippy.show()

        cursorVisited.value = true
    }

    function mouseLeave(event: MouseEvent) {
        event.stopPropagation()
        if (cursorVisited.value) {
            close()
        }
    }

    function parentDivGetBoundingClientRect(): DOMRect {
        return (parentDiv.value as HTMLSpanElement).getBoundingClientRect()
    }

    function updatePosition() {
        let rect = parentDivGetBoundingClientRect()
        ;(virtualReference.value as HTMLSpanElement).getBoundingClientRect = () => {
            return rect
        }
    }

    function createContent(): HTMLElement {
        let ttcont = document.createElement('div') as HTMLElement
        ttcont.classList.add('cogiTooltip')
        ttcont.innerHTML = tooltipTraduit.value
        ttcont.setAttribute('style', 'text-align: left;')
        ttcont.addEventListener('mouseenter', e => {
            e.stopPropagation()
            cursorVisited.value = true
        })
        ttcont.addEventListener('mouseleave', e => {
            e.stopPropagation()
            if (cursorVisited.value) {
                self.close()
            }
        })

        ttcont.addEventListener('click', () => {
            self.close()
        })

        return ttcont
    }

    function close() {
        if (!virtualReference.value) return

        if (!(virtualReference.value as ElementWithTippy)._tippy) return
        ;(virtualReference.value as ElementWithTippy)._tippy.hide()
    }
</script>
