<template>
    <div>
        <div class="lay-section">
            <input type="text" class="lay-col-2 code-demande" @keydown.enter="Envoyer" v-model="codeDemande" />
        </div>
        <div class="lay-section">
            <button class="lay-col-2 bouton" @click="Envoyer">{{ t('Envoyer') }}</button>
        </div>
        <div class="lay-section">
            <br />
            <br />
        </div>
        <div v-if="resultatsDisponibles">
            <div class="lay-section ligne">
                <label class="lay-col-4 label-row-bold">{{ t('NomDeFamille') }}</label>
                <span class="lay-col-12 label-row">{{ nom }}</span>
            </div>
            <div class="lay-section ligne">
                <label class="lay-col-4 label-row-bold">{{ t('Demande') }}</label>
                <span class="lay-col-12 label-row">{{ resultatCodeDemande }}</span>
            </div>
            <div class="lay-section spacer"><br /></div>
            <table class="table-responsive">
                <thead>
                    <tr>
                        <th scope="col header">{{ t('Programme') }}</th>
                        <th scope="col header">{{ t('Catégorie logement') }}</th>
                        <th scope="col header">{{ t('Territoire') }}</th>
                        <th scope="col header">{{ t('Sous-catégorie logement') }}</th>
                        <th scope="col header">{{ t('Rang') }}</th>
                        <th scope="col header">{{ t('Pondération') }}</th>
                    </tr>
                </thead>
                <tbody v-for="(rang, index) in rangs" :key="index">
                    <tr>
                        <td :data-label="t('Programme')">{{ rang.programme }}</td>
                        <td :data-label="t('Catégorie logement')">{{ rang.categorie }}</td>
                        <td :data-label="t('Territoire')">{{ rang.territoire }}</td>
                        <td :data-label="t('Sous-catégorie logement')">{{ rang.sousCategorie }}</td>
                        <td :data-label="t('Rang')" class="centrer">{{ rang.rangEffectif }}</td>
                        <td :data-label="t('Pondération')" class="centrer">{{ rang.ponderation }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="afficherMessageAucunResultat">
            <p class="wc-message">{{ t('message Aucune information trouvée') }}</p>
            <p class="wc-message">{{ t('message 30 jours') }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, onMounted } from 'vue'
    import { webcom } from '@clients/webcom'
    import { i18n } from '../../locales/i18n-wc'
    import { settings } from '@shared/settings'

    const nom = ref('')
    const codeDemande = ref('')
    const resultatsDisponibles = ref(false)
    const afficherMessageAucunResultat = ref(false)
    const resultatCodeDemande = ref('')
    const rangs = ref<webcom.Rang[]>([])

    interface IPropsRang {
        codeOrganisme: string
        langue?: string
    }

    const props = withDefaults(defineProps<IPropsRang>(), {
        langue: 'fr',
    })

    watch(
        () => props.langue,
        function (newValue) {
            i18n.locale = newValue
        },
    )

    onMounted(() => {
        i18n.locale = props.langue
    })

    function Envoyer() {
        const client = new webcom.Client(settings.bffBaseUrl)

        client
            .rang(codeDemande.value, props.codeOrganisme)
            .then((result: webcom.RangResponse) => {
                resultatCodeDemande.value = result.codeDemande!
                nom.value = result.nomFamille!
                rangs.value = result.rangs!
                afficherMessageAucunResultat.value = false
                resultatsDisponibles.value = true
            })
            .catch(() => {
                afficherMessageAucunResultat.value = true
                resultatsDisponibles.value = false
            })
    }
    //on traduit avec cette fonction parce que ce component est aussi utilisé dans un web component
    function t(key: string) {
        return i18n.t(key)
    }
</script>

<style scoped>
    @import '../../css/rang.less';
</style>
