/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import type { AxiosError } from 'axios'

export class ValidationProblemDetails implements IValidationProblemDetails {
    type?: string | undefined
    title?: string | undefined
    status?: number | undefined
    detail?: string | undefined
    instance?: string | undefined
    extensions?: { [key: string]: any } | undefined
    errors?: { [key: string]: string[] } | undefined

    constructor(data?: IValidationProblemDetails) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.type = _data['type']
            this.title = _data['title']
            this.status = _data['status']
            this.detail = _data['detail']
            this.instance = _data['instance']
            if (_data['extensions']) {
                this.extensions = {} as any
                for (const key in _data['extensions']) {
                    if (_data['extensions'].hasOwnProperty(key)) (<any>this.extensions)![key] = _data['extensions'][key]
                }
            }
            if (_data['errors']) {
                this.errors = {} as any
                for (const key in _data['errors']) {
                    if (_data['errors'].hasOwnProperty(key)) (<any>this.errors)![key] = _data['errors'][key] !== undefined ? _data['errors'][key] : []
                }
            }
        }
    }

    static fromJS(data: any): ValidationProblemDetails {
        data = typeof data === 'object' ? data : {}
        const result = new ValidationProblemDetails()
        result.init(data)
        return result
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {}
        data['type'] = this.type
        data['title'] = this.title
        data['status'] = this.status
        data['detail'] = this.detail
        data['instance'] = this.instance
        if (this.extensions) {
            data['extensions'] = {}
            for (const key in this.extensions) {
                if (this.extensions.hasOwnProperty(key)) (<any>data['extensions'])[key] = (<any>this.extensions)[key]
            }
        }
        if (this.errors) {
            data['errors'] = {}
            for (const key in this.errors) {
                if (this.errors.hasOwnProperty(key)) (<any>data['errors'])[key] = (<any>this.errors)[key]
            }
        }
        return data
    }
}

export interface IValidationProblemDetails {
    type?: string | undefined
    title?: string | undefined
    status?: number | undefined
    detail?: string | undefined
    instance?: string | undefined
    extensions?: { [key: string]: any } | undefined
    errors?: { [key: string]: string[] } | undefined
}

export class ApiException extends Error {
    override message: string
    status: number
    response: string
    headers: { [key: string]: any }
    result: any

    constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
        super()

        this.message = message
        this.status = status
        this.response = response
        this.headers = headers
        this.result = result
    }

    protected isApiException = true

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any }, result?: any): any {
    if (result !== null && result !== undefined) throw result
    else throw new ApiException(message, status, response, headers, null)
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true
}
