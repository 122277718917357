/* eslint-disable no-var */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// declare var BFF_BASE_URL: string
// declare var UI_BASE_URL: string
// declare var currentScript: string | undefined

export var settings = {
    bffBaseUrl: GetBffUrl(),
    uiBaseUrl: GetOrigin()
}

function GetOrigin() : string {
    const src = import.meta.url;
    const url = new URL(src);
    return url.origin;
}
function GetBffUrl(): string {

    const origin = GetOrigin()        
    if(origin.startsWith('http://localhost') || origin.startsWith("https//localhost"))
        return 'http://localhost:5255'
    else
        return new URL('/bff',origin).href
}
