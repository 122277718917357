<template>
    <div :class="cssClass">
        <div class="grid">
            <cogi-vehicule
                v-for="(vehicule, index) in model!.vehicules"
                cssClass="grid-full"
                @validated="vehiculeValidated"
                :culture="culture"
                :valideNow="valideNow"
                v-model="model!.vehicules![index]"
                :key="vehicule.id"
                @retirerVehicule="removeVehicule(vehicule!.id!)"
                :sourceItems="sourceItems"
                :index="index"
                :visible="visible!">
            </cogi-vehicule>
            <cogi-button cssClass="grid-full right" v-show="visible" cssClassButton="suivant" text="AjouterUnVehicule" v-on:click="addVehicule"></cogi-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'

    import CogiVehicule from './CogiVehicule.vue'
    import CogiButton from './CogiButton.vue'

    import { ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { del } from '../clients/delclient'
    import type DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IPropsVehicules {
        sourceItems: del.ListItem[]
        valideNow: boolean
        cssClass?: string
        culture: string
    }

    const props = withDefaults(defineProps<IPropsVehicules>(), {
        cssClass: '',
        culture: 'fr',
    })
    const validator = ref(new ValidatorAccumulator())

    const model = defineModel<DemandeEnLigneVM>()

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [Array<del.Vehicule>]
    }>()

    const visible = computed(() => {
        return model.value?.possedeVoiture
    })

    function vehiculeValidated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function addVehicule() {
        let vehicule = new del.Vehicule()
        model.value!.vehicules!.push(vehicule)
    }

    function removeVehicule(id: string) {
        model.value!.vehicules = model.value!.vehicules!.filter(function (vehicule: del.Vehicule) {
            return vehicule.id != id
        })
    }

    watch(
        () => model.value?.possedeVoiture,
        newValue => {
            if (newValue && model.value!.vehicules!.length === 0) {
                addVehicule()
            }
        },
    )

    // watch(
    //     () => model.value?.vehicules,
    //     () => {
    //         emit('update:modelValue', model.value?.vehicules), { deep: true }
    //     }
    // )

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let nbVehicules = model.value?.possedeVoiture ? model.value!.vehicules!.length : 0
            let startValidation = validator.value.startValidation(nbVehicules)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
