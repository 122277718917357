import type { AxiosInstance, AxiosRequestConfig, AxiosProgressEvent, AxiosResponse, CancelToken, AxiosError } from 'axios'
import { ValidationProblemDetails } from './ValidationProblemDetails'
import { authStore } from '@stores/auth'

export class BaseClient {    
    langue = 'fr'
    codeOrganisme = ''
    noSuccursale = ''
    lastCode = ''
    surProgression: ((progressEvent: AxiosProgressEvent) => void) | undefined

    protected constructor() {}

    setSurProgression(callback: (progressEvent: AxiosProgressEvent) => void) {
        this.surProgression = callback
    }

    setContexte(langue: string, codeOrganisme: string, noSuccursale: string) {
        if (this.stringIsNullOrEmpty(langue) || this.stringIsNullOrEmpty(codeOrganisme) || this.stringIsNullOrEmpty(noSuccursale))
            console.warn('setContexte() : Un parametre est vide')

        this.langue = langue
        this.codeOrganisme = codeOrganisme
        this.noSuccursale = noSuccursale
    }

    setContexteFromStore() {
        const contexte = authStore()

        if (this.stringIsNullOrEmpty(contexte.lang) || this.stringIsNullOrEmpty(contexte.codeOrganisme) || contexte.noSuccursale <= 0)
            console.warn('setContexteFromStore() : Un parametre est vide')

        const codeOrganisme = contexte.codeOrganisme
        const noSuccursale = contexte.noSuccursale
        const lang = contexte.lang!

        this.setContexte(lang, codeOrganisme, noSuccursale.toString())
    }

    protected transformOptions(options: AxiosRequestConfig): Promise<AxiosRequestConfig> {        
        options.withCredentials = true

        if (this.codeOrganisme !== '') {
            options.headers!['X-CodeOrganisme'] = this.codeOrganisme
        }

        if (this.noSuccursale !== '') {
            options.headers!['X-NoSuccursale'] = this.noSuccursale
        }

        options.headers!['X-Langue'] = this.langue

        if (this.surProgression) options.onUploadProgress = this.surProgression

        return Promise.resolve(options)
    }
    
    protected transformResult<T>(url: string, response: AxiosResponse, callback: (response: AxiosResponse) => Promise<T>): Promise<T> {
                

        return callback(response)
    }

    private stringIsNullOrEmpty(value: string): boolean {
        return value == null || value == ''
    }
}
