<template>
    <div>
        <div class="cogi-tab">
            <ul class="flex">
                <li v-for="tab in tabs" :class="{ 'is-active': tab.props.selected }" @click="tabClicked(tab)" :key="tab.props">
                    <a href="#">
                        <div class="cogi-tab-title">
                            {{ traduire(tab.props.title) }}
                        </div>
                        <div class="cogi-tab-subtitle">{{ traduire(tab.props.subtitle) }}</div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-details">
            <slot></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'
    import type CogiTab from './CogiTab.vue'

    interface IPropsTabs {
        validerSurOnglet: boolean
    }

    const props = withDefaults(defineProps<IPropsTabs>(), { validerSurOnglet: false })

    const tabs = ref(<Array<typeof CogiTab>>[])

    //TODO.. CORRIGER... LA REACTIVITE DU COMPUTED NE FONCTIONNE PAS BIEN ICI...
    //LE CURRENTTAB N'EST PAS BON
    function getCurrentTab(): typeof CogiTab {
        const selectedTab = tabs.value.filter(tab => tab.props.selected)

        if (selectedTab[0]) return selectedTab[0]

        return tabs.value[0]
    }

    const emit = defineEmits<{
        tabClicked: [typeof CogiTab, number, boolean]
        tabSelected: [typeof CogiTab]
    }>()

    function traduire(label: string) {
        return i18n.t(label)
    }

    function tabClicked(tab: typeof CogiTab) {
        const currentTab = getCurrentTab()
        if (props.validerSurOnglet) {
            const futureTabIndex = tabs.value.indexOf(tab)
            emit('tabClicked', currentTab, futureTabIndex, estAvant(futureTabIndex))
        } else {
            selectTab(tab)
        }
    }

    function estAvant(futureTabIndex: number): boolean {
        const currentTab = getCurrentTab()
        return tabs.value.indexOf(currentTab) > futureTabIndex
    }

    function selectTab(selectedTab: typeof CogiTab) {
        tabs.value.forEach(tab => {
            const isActive = tab.props.title === selectedTab.props.title
            tab.isActive = isActive
            tab.props.selected = isActive
        })
        //emit('tabSelected', selectedTab)
    }

    defineExpose({ tabs, selectTab, getCurrentTab })
</script>
