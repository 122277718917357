<template>
    <div ref="template">
        <div class="cogi-subtitle">
            <cogi-label-only label="IdentificationDuDemandeur" />
        </div>

        <div class="grid">
            <cogi-regex
                cssClass="grid-half"
                @validated="validated"
                label="PrenomDuDemandeur"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.prenom"></cogi-regex>

            <cogi-regex
                cssClass="grid-half"
                @validated="validated"
                label="NomDuDemandeur"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.nom"></cogi-regex>

            <cogi-telephone
                cssClass="grid-half"
                :required="true"
                @validated="validated"
                label="Telephone"
                v-model="model!.telephoneDemandeur"
                :valideNow="valideNow"></cogi-telephone>
            <cogi-telephone
                cssClass="grid-half"
                @validated="validated"
                label="TelephoneAutre"
                v-model="model!.telephoneAutre"
                :valideNow="valideNow"></cogi-telephone>
            <cogi-email class="grid-half" @validated="validated" label="Courriel" :valideNow="valideNow" v-model="model!.courrielEmail"></cogi-email>
            <cogi-date
                cssClass="grid-half"
                :culture="model!.culture"
                @validated="validated"
                label="DateDeNaissance"
                :valideNow="valideNow"
                v-model="model!.dateNaissance"
                :required="true"></cogi-date>
            <cogi-dropdownlist
                cssClass="grid-half"
                @validated="validated"
                :culture="model!.culture!"
                label="Sexe"
                :valideNow="valideNow"
                :sourceItems="sourceItemsSexe"
                v-model="model!.sexe"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-nas
                cssClass="grid-half"
                @validated="validated"
                label="NAS"
                :valideNow="valideNow"
                v-model="model!.nas"
                :required="false"
                tooltip="<img src='/images/del/NAS.png' />"></cogi-nas>

            <hr class="grid-full" />
            <cogi-regex
                cssClass="grid-half"
                @validated="validated"
                label="PersonneAJoindreAbsence"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.personneAJoindre"></cogi-regex>
            <cogi-telephone
                cssClass="grid-half"
                @validated="validated"
                :required="true"
                :valideNow="valideNow"
                label="Telephone"
                v-model="model!.personneAJoindreTelephone"></cogi-telephone>
        </div>
        <div class="cogi-subtitle">
            <cogi-label-only label="StatutDeResidence" />
        </div>
        <div class="grid">
            <cogi-dropdownlist
                cssClass="gridspan5"
                @validated="validated"
                :culture="model!.culture!"
                label="EtesVous"
                :valideNow="valideNow"
                :sourceItems="sourceItemsStatutResidence"
                v-model="model!.statutDeResidence"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-dropdownlist
                cssClass="gridspan6"
                @validated="validated"
                :culture="model!.culture!"
                label="PaysDeNaissance"
                :valideNow="valideNow"
                :sourceItems="sourceItemsPaysNaissance"
                v-model="model!.paysDeNaissance"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-dropdownlist
                cssClass="gridspan5"
                @validated="validated"
                :culture="model!.culture!"
                label="LangueParlee"
                :valideNow="valideNow"
                :sourceItems="sourceItemsLangueParlee"
                v-model="model!.langue"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
        </div>

        <div class="cogi-subtitle">
            <cogi-label-only label="Adresse" />
        </div>

        <cogi-adresse title="AdresseActuelle" v-model="model!.adresseActuelle!" @validated="validated" :valideNow="valideNow"></cogi-adresse>

        <cogi-date
            :required="true"
            :culture="model!.culture"
            @validated="validated"
            label="DepuisQuandHabitezVousCetteAdresse"
            :valideNow="valideNow"
            v-model="model!.adresseActuelleDepuis"></cogi-date>
        <cogi-adresses
            v-model="model!"
            :visible="showAdressePrecedenteSection"
            @validated="validated"
            :adressePrecAinitiale="adressePrecAinitiale"
            :valideNow="valideNow"></cogi-adresses>

        <div class="cogi-subtitle grid">
            <cogi-label cssClass="gridspan4" :required="true" label="EtesVous"></cogi-label>
            <cogi-radiolist
                cssClass="gridspan12"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                :items="radiolistPocosEtesvous"
                v-model="model!.typeDemandeur!.value"
                groupename="etesVous"></cogi-radiolist>
        </div>
        <div v-show="showLocataireSection">
            <div class="cogi-block-title">
                <cogi-label-only label="Locataire" />
            </div>
            <div class="grid">
                <cogi-dropdownlist
                    cssClass="grid-half"
                    :culture="model!.culture!"
                    @validated="validated"
                    label="NombreDePieceLogementActuel"
                    :valideNow="valideNow"
                    :required="true"
                    :showEmptyItem="true"
                    v-model="model!.nombrePieces"
                    :sourceItems="sourceItemsTypeLogement"
                    :visible="showLocataireSection"></cogi-dropdownlist>
                <cogi-monetaire
                    cssClass="grid-half"
                    @validated="validated"
                    label="LoyerMensuel"
                    :valideNow="valideNow"
                    v-model="model!.loyerMensuel!.value"
                    :required="true"
                    :visible="showLocataireSection"></cogi-monetaire>
                <div class="grid-full">
                    <div class="DisplayInBlock">
                        <cogi-label cssClass="grid-full" :required="true" label="ServiceInclus"></cogi-label>
                        <cogi-info id="infoServicesInclus" tooltip="CocherLesServicesInclusDansCoutDeLoyer"></cogi-info>
                    </div>
                </div>

                <cogi-checkboxlist
                    cssClass="grid-full"
                    :required="true"
                    @validated="validated"
                    :valideNow="valideNow"
                    v-model="services"
                    :visible="showLocataireSection"></cogi-checkboxlist>

                <cogi-regex
                    cssClass="grid-half"
                    @validated="validated"
                    label="NomProprietaireActuel"
                    :valideNow="valideNow"
                    :required="true"
                    v-model="model!.nomProprietaire"
                    :visible="showLocataireSection"></cogi-regex>
                <cogi-telephone
                    cssClass="grid-half"
                    label="Telephone"
                    v-model="model!.noProprietaire"
                    @validated="validated"
                    :valideNow="valideNow"></cogi-telephone>
            </div>
        </div>
        <div class="grid" v-show="showProprietaireSection">
            <div class="grid-full cogi-block-title"><cogi-label-only label="Proprietaire" /></div>
            <cogi-dropdownlist
                class="grid-half"
                @validated="validated"
                :culture="model!.culture!"
                label="SoldeDeVotreHypotheque"
                :valideNow="valideNow"
                :required="true"
                :showEmptyItem="true"
                v-model="model!.soldeHypotheque"
                :sourceItems="sourceItemsSoldeHypotheque"
                :visible="showProprietaireSection"></cogi-dropdownlist>
        </div>

        <div v-show="showChambreurSection" class="grid">
            <cogi-label cssClass="grid-full" label="QuelTypeDeChambreOccupezVous" :required="true"></cogi-label>
            <cogi-radiolist
                :visible="showChambreurSection"
                cssClass="grid-full"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                :items="radiolistPocosTypeChambre"
                v-model="typeChambreurAsString"
                groupename="typeChambreur"></cogi-radiolist>
        </div>
        <div class="grid">
            <cogi-button class="grid-full right" cssClassButton="suivant" text="EtapeSuivante" @click="suivant"></cogi-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, reactive, getCurrentInstance } from 'vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiDropdownlist from './CogiDropdownlist.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiButton from './CogiButton.vue'
    import CogiNas from './CogiNas.vue'
    import CogiMonetaire from './CogiMonetaire.vue'
    import CogiDate from './CogiDate.vue'
    import CogiRadiolist from './CogiRadiolist.vue'
    import CogiCheckboxlist from './CogiCheckboxlist.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import CogiTelephone from './CogiTelephone.vue'
    import CogiAdresse from './CogiAdresse.vue'
    import CogiAdresses from './CogiAdresses.vue'
    import CogiEmail from './CogiEmail.vue'
    import CogiInfo from './CogiInfo.vue'

    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { DateUtil } from './helpers/dateutil'
    import { CheckboxlistPoco } from './pocos/CheckboxlistPoco'
    import { RadiolistPoco } from './pocos/RadiolistPoco'
    import { del } from './../clients/delclient'
    import DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IPropsEtape1 {
        sourceItemsSexe: del.ListItem[]
        sourceItemsTypeLogement: del.ListItem[]
        sourceItemsSoldeHypotheque: del.ListItem[]
        sourceItemsPaysNaissance: del.ListItem[]
        sourceItemsLangueParlee: del.ListItem[]
        sourceItemsStatutResidence: del.ListItem[]
    }

    const props = defineProps<IPropsEtape1>()

    const model = defineModel<DemandeEnLigneVM>() //props.modelValue

    const showAdressePrecedenteSection = ref(false)
    const typeChambreur = ref(new del.TypeChambreur())
    const hasError = ref(false)
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const valideNow = ref(false)
    const isInsuivantMode = ref(false)
    const serviceAucun = ref(false)
    const radiolistPocosEtesvous = ref(getRadiolistPocosEtesvous())
    const radiolistPocosTypeChambre = ref(getRadiolistPocosTypeChambre())
    const validateCallback = ref(defaultValidateCallback)
    const adressePrecAinitiale = ref(undefined as Date | undefined)

    let services = getCheckboxlistPocosService()

    const instance = getCurrentInstance()

    const emit = defineEmits<{
        avancerUnEtape: [number]
    }>()

    //fonctionne à moitier
    watch(services, newValue => {
        let serviceAucunNewValue = newValue[0]
        let serviceChauffage = newValue[1]
        let serviceElectricite = newValue[2]
        let serviceEauChaude = newValue[3]
        let serviceAutre = newValue[4]

        if (serviceAucun.value && serviceAucunNewValue.selectionne) {
            serviceAucun.value = true
            model.value!.serviceChauffage = serviceChauffage.selectionne = false
            model.value!.serviceElectricite = serviceElectricite.selectionne = false
            model.value!.serviceEauChaude = serviceEauChaude.selectionne = false
            model.value!.serviceAutre = serviceAutre.selectionne = false
        } else {
            model.value!.serviceChauffage = serviceChauffage.selectionne
            model.value!.serviceElectricite = serviceElectricite.selectionne
            model.value!.serviceEauChaude = serviceEauChaude.selectionne
            model.value!.serviceAutre = serviceAutre.selectionne
        }
    })

    const typeChambreurAsString = computed({
        get() {
            if (!typeChambreur.value.value) return
            return typeChambreur.value.value!.toString()
        },
        set(value) {
            ;(typeChambreur.value as del.TypeChambreur).value = del.TypeChambreurEnum[value as keyof typeof del.TypeChambreurEnum]
        },
    })

    const showProprietaireSection = computed(() => model.value!.typeDemandeur!.value!.toString() === del.TypeDemandeurEnum.Proprietaire)
    const showLocataireSection = computed(() => model.value!.typeDemandeur!.value!.toString() === del.TypeDemandeurEnum.Locataire)
    const showChambreurSection = computed(() => model.value!.typeDemandeur!.value!.toString() === del.TypeDemandeurEnum.Chambreur)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function defaultValidateCallback(hasError: boolean) {}

    function monthDiff(d1: Date, d2: Date) {
        let months
        months = (d2.getFullYear() - d1.getFullYear()) * 12
        months -= d1.getMonth()
        months += d2.getMonth()
        return months <= 0 ? 0 : months
    }

    function suivant() {
        isInsuivantMode.value = true
        validate()
    }

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)
        valideNow.value = false
        hasError.value = validationStatut.hasError
        if (validationStatut.validationCompleted) {
            doSuivant()

            validateCallback.value(hasError.value)
            validateCallback.value = defaultValidateCallback
        }
    }

    function validate() {
        hasError.value = false
        valideNow.value = validatorAccumulator.value.startValidation(instance)
    }

    function doSuivant() {
        if (!isInsuivantMode.value) return

        isInsuivantMode.value = false

        if (hasError.value) {
            return
        } else {
            emit('avancerUnEtape', 0)
        }
    }

    function getRadiolistPocosEtesvous() {
        let radiolists: RadiolistPoco[] = reactive([])
        let css = 'gridspan5'
        radiolists.push(new RadiolistPoco('Locataire', 'Locataire', css))
        radiolists.push(new RadiolistPoco('Proprietaire', 'Proprietaire', css))
        radiolists.push(new RadiolistPoco('Chambreur', 'Chambreur', css))
        return radiolists
    }

    function getRadiolistPocosTypeChambre() {
        let radiolists: RadiolistPoco[] = reactive([])
        let css = 'gridspan5'
        radiolists.push(new RadiolistPoco('MaisonDeChambre', 'MaisonChambre', css))
        radiolists.push(new RadiolistPoco('HebergementChezUnProche', 'HebergementChezUnProche', css))
        radiolists.push(new RadiolistPoco('CentreHebergement', 'CentreHebergement', css))
        return radiolists
    }

    function getCheckboxlistPocosService() {
        let checkboxlists: CheckboxlistPoco[] = reactive([])
        let css = 'gridspan3'
        checkboxlists.push(new CheckboxlistPoco('Aucun', 'serviceAucun', serviceAucun.value, css))
        checkboxlists.push(new CheckboxlistPoco('Chauffage', 'serviceChauffage', model.value!.serviceChauffage, css))
        checkboxlists.push(new CheckboxlistPoco('Electricite', 'serviceElectricite', model.value!.serviceElectricite, css))
        checkboxlists.push(new CheckboxlistPoco('EauChaude', 'serviceEauChaude', model.value!.serviceEauChaude, css))
        checkboxlists.push(new CheckboxlistPoco('Autre', 'serviceAutre', model.value!.serviceAutre, css))
        return checkboxlists
    }

    watch(
        () => typeChambreur.value.value,
        newValue => {
            if (newValue === del.TypeChambreurEnum.MaisonChambre) {
                model.value!.typeChambreur!.value = del.TypeChambreurEnum.MaisonChambre
            } else if (newValue === del.TypeChambreurEnum.ChezUnProche) {
                model.value!.typeChambreur!.value = del.TypeChambreurEnum.ChezUnProche
            } else if (newValue === del.TypeChambreurEnum.CentreHebergement) {
                model.value!.typeChambreur!.value = del.TypeChambreurEnum.CentreHebergement
            }
        },
    )

    watch(
        () => model.value!.adresseActuelleDepuis,
        newValue => {
            //model.value!.adresseActuelleDepuis = newValue as Date
            if (newValue != null) {
                const diff = monthDiff(newValue, new Date())
                showAdressePrecedenteSection.value = !Number.isNaN(diff) && diff < 24
                //model.value!.showAdressePrecedenteSection = showAdressePrecedenteSection.value
                if (showAdressePrecedenteSection.value) {
                    //if (!adressePrecAinitiale.value) {
                    adressePrecAinitiale.value = DateUtil.addDays(newValue, -1)
                    model.value!.adressePrecA = adressePrecAinitiale.value
                    //}
                } else {
                    model.value!.adressesPrecedentes!.splice(0, model.value!.adressesPrecedentes!.length)
                    model.value!.adressePrec = undefined
                }
            }
        },
        { deep: true },
    )

    defineExpose({ validate, hasError })
</script>
