<template>
    <div :class="cssClass" v-show="visible">
        <div class="grid">
            <cogi-label
                class="verticalCenter"
                :tooltip="tooltip"
                :dontTranslate="dontTranslate"
                :class="cssClassLabelComputed"
                :required="required"
                :label="label"></cogi-label>

            <div :class="cssClassRadioComputed" class="radiobtn container PaddingMinCOGI">
                <cogi-radio label="Oui" v-model="selectedValue" :groupename="groupename" valeur="1" :required="required" :hasError="hasError"></cogi-radio>
                <cogi-radio label="Non" v-model="selectedValue" :groupename="groupename" valeur="0" :required="required" :hasError="hasError"></cogi-radio>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue'
    import CogiRadio from './CogiRadio.vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsYesNo {
        modelValue: boolean | undefined
        groupename?: string
        required?: boolean
        valideNow?: boolean
        cssClass?: string
        label?: string
        isLabelOnTop?: boolean
        cssClassLabel?: string
        cssClassRadio?: string
        visible?: boolean
        tooltip?: string
        dontTranslate?: boolean
    }

    const props = withDefaults(defineProps<IPropsYesNo>(), {
        groupename: 'ouinon',
        required: false,
        valideNow: false,
        cssClass: '',
        label: '',
        isLabelOnTop: false,
        cssClassLabel: '',
        cssClassRadio: '',
        visible: true,
        tooltip: '',
        dontTranslate: false,
    })

    const selectedValue = ref(<string | undefined>undefined)
    const hasError = ref(false)

    const labelVisible = computed(() => {
        return !!props.label
    })

    const cssClassLabelComputed = computed(() => {
        return props.cssClassLabel ? props.cssClassLabel : props.isLabelOnTop ? 'grid-full' : labelVisible.value ? 'gridspan12' : ''
    })

    const cssClassRadioComputed = computed(() => {
        return props.cssClassRadio ? props.cssClassRadio : props.isLabelOnTop ? 'grid-full' : labelVisible.value ? 'gridspan4radiobtn' : 'gridspan16'
    })

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [boolean | undefined]
    }>()

    if (props.modelValue === true) selectedValue.value = '1'
    if (props.modelValue === false) selectedValue.value = '0'

    function baseValidate(funcEmitValidated: () => void = () => {}) {
        hasError.value = false

        if (props.visible && props.required) {
            if (!selectedValue.value || selectedValue.value == '') {
                hasError.value = true
            }
        }

        funcEmitValidated()
    }

    function validate() {
        baseValidate(() => emit('validated', hasError.value))
    }

    watch(selectedValue, newValue => {
        baseValidate()

        if (newValue === '1') emit('update:modelValue', true)
        else if (newValue === '0') emit('update:modelValue', false)
        else emit('update:modelValue', undefined)
    })

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )
</script>
