<template>
    <div :class="cssClass" v-show="visible">
        <div class="grid">
            <cogi-label class="grid-full" :required="required" :label="label"></cogi-label>
            <input
                class="grid-full"
                type="text"
                :placeholder="placeholder"
                :disabled="disabled"
                :class="{ cogirequired: required, 'cogi-error': hasError }"
                v-model="textFormated"
                v-on:blur="blur" />
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsRegex {
        required?: boolean
        regex?: string
        placeholder?: string
        disabled?: boolean
        valideNow?: boolean
        cssClass?: string
        label?: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsRegex>(), {
        required: false,
        regex: '',
        placeholder: '',
        disabled: false,
        valideNow: false,
        cssClass: '',
        label: '',
        visible: true,
    })

    const hasError = ref(false)

    const model = defineModel<string>()
    const text = ref(model.value)

    const emit = defineEmits<{
        validated: [boolean]
    }>()

    const textFormated = computed({
        get: () => model.value,
        set: val => {
            model.value = val as string
            //emit('update:modelValue', val as string);
        },
    })

    function blur() {
        hasError.value = false
    }
    function validate() {
        hasError.value = false

        if (!props.visible) {
            emitValidated(false)
            return
        }

        if (!props.required && model.value == null) {
            emitValidated(hasError.value)
            return
        } else if (props.required && model.value == null) hasError.value = true
        else if (props.required && model.value!.length == 0) hasError.value = true
        else if (props.regex != '') {
            var pattern = new RegExp(props.regex as string)

            if (!pattern.test(String(model.value).toLowerCase())) {
                hasError.value = true
            }
        }

        emitValidated(hasError.value)
    }
    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    watch(
        () => model.value,
        newValue => {
            text.value = newValue as string
        },
        { deep: true },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (newValue) validate()
        },
    )
</script>
