<template>
    <div class="grid" ref="template">
        <div class="grid-full cogi-block-title">
            <cogi-label-only label="ChefDeMenage" />
        </div>
        <cogi-personne
            cssClass="grid-full"
            v-model="chefMenage"
            :estChefMenage="true"
            :sourceItemsSexe="sourceItemsSexe"
            :sourceItemsLienParente="sourceItemsLienParente"
            :sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible="sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible"
            :sourceItemsPaysNaissance="sourceItemsPaysNaissance"
            :sourceItemsStatutResidence="sourceItemsStatutResidence"
            :lienParentePourGardePartagee="modelData.lienParentePourGardePartagee"
            :utiliseGardePartagee="modelData.utiliseGardePartagee!"
            :urlPiece="urlDocumentPiecesJustificativesTraduit"
            :pourcentageGardePartage="modelData.pourcentageGardePartage"
            @validated="validated"
            :valideNow="valideNow"
            :culture="modelData.culture!">
        </cogi-personne>

        <hr class="grid-full" />

        <cogi-personnes
            cssClass="grid-full"
            v-model="modelData.persons!"
            :sourceItemsSexe="sourceItemsSexe"
            :sourceItemsPaysNaissance="sourceItemsPaysNaissance"
            :sourceItemsLienParente="sourceItemsLienParente"
            :sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible="sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible"
            :sourceItemsStatutResidence="sourceItemsStatutResidence"
            :lienParentePourGardePartagee="modelData.lienParentePourGardePartagee as string"
            :utiliseGardePartagee="modelData.utiliseGardePartagee as boolean"
            :urlPiece="urlDocumentPiecesJustificativesTraduit"
            :pourcentageGardePartage="modelData.pourcentageGardePartage"
            @validated="validated"
            :valideNow="valideNow"
            :culture="modelData.culture as string">
        </cogi-personnes>

        <cogi-label cssClass="grid-full text-center" cssClassLabel="text-italic-small noteImportante" label="IlEstImportantDeSaisirTousLesMembres"></cogi-label>

        <cogi-button cssClass="gridspan15 right" cssClassButton="precedent" text="EtapePrecedente" @click="precedent"></cogi-button>
        <cogi-button cssClass="gridspan1 right" cssClassButton="suivant" text="EtapeSuivante" @click="suivant"></cogi-button>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, getCurrentInstance } from 'vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'

    import CogiLabel from './CogiLabel.vue'
    import CogiPersonne from './CogiPersonne.vue'
    import CogiPersonnes from './CogiPersonnes.vue'
    import CogiButton from './CogiButton.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import guid from './helpers/guidgen'
    import { del } from './../clients/delclient'
    import type DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IPropsCogiEtape2 {
        sourceItemsSexe: del.ListItem[]
        sourceItemsLienParente: del.ListItem[]
        sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible: Array<string>
        sourceItemsPaysNaissance: del.ListItem[]
        sourceItemsStatutResidence: del.ListItem[]
        modelValue: DemandeEnLigneVM
    }

    const props = withDefaults(defineProps<IPropsCogiEtape2>(), {})

    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const modelData = ref(props.modelValue)
    const hasError = ref(false)
    const valideNow = ref(false)
    const isInsuivantMode = ref(false)
    const validateCallback = ref(defaultValidateCallback)
    const chefMenage = ref(new del.Person())
    const instance = getCurrentInstance()

    chefMenage.value.id = guid.GenererGuid()
    chefMenage.value.prenom = modelData.value.prenom as string
    chefMenage.value.nom = modelData.value.nom as string
    chefMenage.value.dateNaissance = modelData.value.dateNaissance as Date
    if (!chefMenage.value.revenuTotal) chefMenage.value.revenuTotal = new del.Money({ value: modelData.value.revenuTotal!.value })
    chefMenage.value.etudiantTempsPlein = modelData.value.etudiantTempsPlein as boolean
    chefMenage.value.etudiantTempsPartiel = modelData.value.etudiantTempsPartiel as boolean
    chefMenage.value.handicapMoteur = modelData.value.handicapMoteur as boolean
    chefMenage.value.pensionAlimentaire = modelData.value.pensionAlimentaire as boolean
    chefMenage.value.pensionVeteran = modelData.value.pensionVeteran as boolean
    chefMenage.value.assuranceInvalidite = modelData.value.assuranceInvalidite as boolean

    const emit = defineEmits<{
        reculerUnEtape: [number]
        avancerUnEtape: [number]
    }>()

    const urlDocumentPiecesJustificativesTraduit = computed(() => {
        return modelData.value.dicUrlDocumentPiecesJustificatives!['fr']
    })

    function defaultValidateCallback(hasError: boolean) {}

    function suivant() {
        isInsuivantMode.value = true
        validate()
    }

    function precedent() {
        emit('reculerUnEtape', 1)
    }

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        hasError.value = validationStatut.hasError
        valideNow.value = false

        if (hasError.value) return

        doSuivant()
        validateCallback.value(hasError.value)
        validateCallback.value = defaultValidateCallback
    }

    function validate() {
        hasError.value = false
        valideNow.value = validatorAccumulator.value.startValidation(instance)
    }

    function doSuivant() {
        if (!isInsuivantMode.value) return

        isInsuivantMode.value = false
        if (hasError.value) {
            return
        } else {
            emit('avancerUnEtape', 1)
        }
    }

    watch(
        chefMenage.value,
        newValue => {
            modelData.value.prenom = newValue.prenom
            modelData.value.nom = newValue.nom
            modelData.value.dateNaissance = newValue.dateNaissance
            modelData.value.revenuTotal = new del.Money({ value: newValue.revenuTotal?.value })
            modelData.value.etudiantTempsPlein = newValue.etudiantTempsPlein
            modelData.value.etudiantTempsPartiel = newValue.etudiantTempsPartiel
            modelData.value.handicapMoteur = newValue.handicapMoteur
            modelData.value.pensionAlimentaire = newValue.pensionAlimentaire
            modelData.value.pensionVeteran = newValue.pensionVeteran
            modelData.value.assuranceInvalidite = newValue.assuranceInvalidite
        },
        { deep: true },
    )
</script>
