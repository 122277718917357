export class DateUtil {
    static estMajeur(birthdate: Date): boolean {
        const now = new Date()

        if (now.getFullYear() - birthdate.getFullYear() >= 19) return true

        if (now.getFullYear() - birthdate.getFullYear() === 18 && now.getMonth() > birthdate.getMonth())
            // Impossible en janvier puisque janvier ne peux pas être > qu'un autre mois de l'année le coverage ne peux donc pas couvrir cette ligne en janvier (now devrais être passé en paramètre ou au constructeur pour bien tester mais pour le moment je laisse le tout ainsi cela n'ayant aucune impacte négative sur le fonctionnement).
            return true

        if (now.getFullYear() - birthdate.getFullYear() === 18 && now.getMonth() === birthdate.getMonth() && now.getDate() >= birthdate.getDate()) return true

        return false
    }

    static addDays(date: Date, days: number): Date {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)

        return copy
    }
}
