<template>
    <div v-if="visible" :class="cssClass" class="dropdown dropdown-trigger" ref="dropdown" @click="ouvrirFermerLangues">
        <button
            class="button noPaddingnoMarginnoBorder dropdown-toggle titre-section"
            type="button"
            data-toggle="dropdown"
            aria-controls="dropdown-menu"
            aria-haspopup="true"
            aria-expanded="false"
            style="height: 34px">
            <span>{{ langueAffiche }}&nbsp;</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="dropdown-content">
                <cogi-label
                    v-for="langue in languesSort"
                    :key="langue"
                    cssClass="dropdown-item"
                    :label="langue.toUpperCase()"
                    :onClick="() => changeLangue(langue)"
                    :avecCurseur="true"
                    :dontTranslate="true">
                </cogi-label>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, onMounted, nextTick } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import loadingSpinner from './loading-spinner'

    interface IPropsLangue {
        modelValue?: string
        cssClass: string
        visible: boolean
        langues: Array<string>
    }

    const props = withDefaults(defineProps<IPropsLangue>(), {
        modelValue: undefined,
        cssClass: '',
        visible: false,
        langues: () => [],
    })

    const dropdown = ref(<HTMLElement | null>null)

    const culture = ref('')
    const languesSort = ref(props.langues)

    const langueAffiche = computed(() => {
        return culture.value ? (<string>culture.value).toUpperCase() : ''
    })

    const emit = defineEmits<{
        'update:modelValue': [string]
    }>()

    onMounted(() => {
        culture.value = props.modelValue!
        languesSort.value.sort()
    })

    function changeLangue(nouvelleLangue: string) {
        if (nouvelleLangue == culture.value) return

        loadingSpinner.start()
        emit('update:modelValue', nouvelleLangue)

        //la magie qui fait que la traduction s'applique partout
        //c'est le :key="vm.culture" sur le controle tabs du formulaire
        //vue sait ainsi qu'il doit recharger tout le contenu si la culture change

        nextTick(() => {
            loadingSpinner.stop()
        })
    }

    function ouvrirFermerLangues() {
        const dropdownElement = dropdown.value as HTMLElement
        if (dropdownElement) dropdownElement.classList.toggle('is-active')
    }

    watch(
        () => props.modelValue,
        newValue => {
            culture.value = newValue as string
        },
    )
    watch(
        () => props.langues,
        newValue => {
            if (languesSort.value == newValue) return
            languesSort.value = newValue
            languesSort.value.sort()
        },
    )
</script>
