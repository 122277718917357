<template>
    <demande-en-ligne :c="c" :no-succursale="noSuccursale" :langue="langue"></demande-en-ligne>
    <footer>
        <div class="container">
            <span
                ><span class=""
                    ><label class="sansCurseur"
                        >Version
                        <!---->
                        <!----></label
                    ></span
                ></span
            >
            <span title="2022-12-15 15:38:00"
                ><span class=""
                    ><label class="sansCurseur"
                        >1.11
                        <!---->
                        <!----></label
                    ></span
                >
                &nbsp;</span
            >
            <span
                ><span class=""
                    ><label class="sansCurseur"
                        >© 2024 COGIWEB - Tous droits réservés
                        <!---->
                        <!----></label
                    ></span
                ></span
            >
        </div>
    </footer>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue'
    import DemandeEnLigne from '@components/del/DemandeEnLigne.vue'

    interface IPropsFormulaireDemande {
        c: string
        noSuccursale: string
        langue: 'fr' | 'en'
    }

    const lang = ref('en')
    const props = defineProps<IPropsFormulaireDemande>()

    onMounted(() => {
        lang.value = props.langue
    })

    watch(
        () => props.langue,
        function (newValue) {
            lang.value = newValue
        },
    )
</script>
<style>
    @import '../components/del/css/global.css';
</style>
