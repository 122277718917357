<template>
    <span :class="cssClass">
        <span class="radio" :class="{ cogirequired: required, 'cogi-error': hasError }" @click="changeInput">
            <label class="radio_inner" :class="{ checked: isChecked, unchecked: !isChecked }">
                <input type="radio" class="rsform-radio" :name="groupename" :value="valeur" @change="updateInput" />
            </label>
        </span>
        <label class="radio inline" v-text="libelleTraduit" :class="{ 'cogi-error': hasError }" @click="changeInput"></label>
    </span>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'

    interface IPropsRadio {
        valeur: string
        groupename: string
        label: string
        required: boolean
        hasError: boolean
        cssClass?: string
    }

    const props = withDefaults(defineProps<IPropsRadio>(), {
        valeur: '',
        groupename: '',
        label: '',
        required: false,
        hasError: false,
        cssClass: '',
    })

    const model = defineModel<string>()

    const isChecked = computed(() => model.value == props.valeur)

    const libelleTraduit = computed(() => {
        return i18n.t(props.label)
    })

    function updateInput() {
        model.value = props.valeur
    }

    function changeInput() {
        model.value = props.valeur
    }
</script>
