<template>
    <div>
        <div v-if="complete">
            <formulaire-complete
                :noDeLaDemande="codeDemande"
                visible
                adresseOrganisme="3740 Jean-Marchand"
                courrielOrganisme="nicolas.prevereau@cogiweb.com"
                telephoneOrganisme="418 266-2100" />
        </div>
        <div v-else>
            <formulaire-demande :c="c" :no-succursale="noSuccursale" :langue="langue!" @complete="oncomplete"></formulaire-demande>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue'

    import FormulaireComplete from './FormulaireComplete.vue'
    import FormulaireDemande from './FormulaireDemande.vue'

    interface IPropsForm {
        c?: string
        noSuccursale?: string
        langue?: 'fr' | 'en'
    }

    defineProps<IPropsForm>()

    const complete = ref(false)
    const codeDemande = ref('')

    function oncomplete(params: { codeDemande: string }) {
        complete.value = true
        codeDemande.value = params.codeDemande
    }
</script>

<style>
    @import './css/global.css';
</style>
