<template>
    <div class="container body-content formdemande">
        <cogi-loading-spinner></cogi-loading-spinner>
        <div>
            <cogi-tabs ref="tabs" :key="vm.culture" @tab-clicked="tabClicked" valider-sur-onglet>
                <cogi-tab title="Etape1" ref="tab1" subtitle="ProfilDuDemandeur" :selected="true">
                    <cogi-titresection
                        v-model="vm.culture"
                        :utilise-multilangue="vm.utiliseMultilangue!"
                        :langues="vm.langues!"
                        :imageIcone="vm.imageIcone"
                        titre="IdentificationDuDemandeur"
                        tooltip="ToolTipSection1" />
                    <cogi-etape1
                        v-if="showEtape1"
                        ref="content1"
                        v-model="vm"
                        :sourceItemsSexe="vm.sexes as del.ListItem[]"
                        :sourceItemsTypeLogement="vm.listeTypeDeLogement as del.ListItem[]"
                        :sourceItemsSoldeHypotheque="vm.listeSoldeHypotheque as del.ListItem[]"
                        :sourceItemsStatutResidence="vm.statutsResidence as del.ListItem[]"
                        :sourceItemsLangueParlee="vm.listeLangueParlee as del.ListItem[]"
                        :sourceItemsPaysNaissance="vm.paysNaissance as del.ListItem[]"
                        @avancerUnEtape="avancerUnEtape">
                    </cogi-etape1>
                </cogi-tab>
                <cogi-tab title="Etape2" ref="tab2" subtitle="ProfilDuMenage" :selected="false">
                    <cogi-titresection
                        v-model="vm.culture"
                        :utilise-multilangue="vm.utiliseMultilangue!"
                        :langues="vm.langues!"
                        :imageIcone="vm.imageIcone"
                        titre="ProfileDuMenageCaps"
                        tooltip="ToolTipSection2" />
                    <cogi-etape2
                        v-if="showEtape1"
                        ref="content2"
                        v-model="vm"
                        :prenom="vm.prenom"
                        :nom="vm.nom"
                        :sourceItemsSexe="vm.sexes as del.ListItem[]"
                        :sourceItemsPaysNaissance="vm.paysNaissance as del.ListItem[]"
                        :sourceItemsLienParente="vm.lienParentes as del.ListItem[]"
                        :sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible="vm.lienParentesEstSansSousTutelleChefOuConjointPossible as string[]"
                        :sourceItemsStatutResidence="vm.statutsResidence as del.ListItem[]"
                        @avancerUnEtape="avancerUnEtape"
                        @reculerUnEtape="reculerUnEtape">
                    </cogi-etape2>
                </cogi-tab>
                <cogi-tab title="Etape3" ref="tab3" subtitle="AutonomieEtAutres" :selected="false">
                    <cogi-titresection
                        v-model="vm.culture"
                        :utilise-multilangue="vm.utiliseMultilangue!"
                        :langues="vm.langues!"
                        :imageIcone="vm.imageIcone"
                        titre="QuestionComplementaireEtAutonomieCaps"
                        tooltip="ToolTipSection3" />
                    <cogi-etape3
                        v-if="showEtape1"
                        ref="content3"
                        v-model="vm"
                        :sourceItemsMarquesVehicule="vm.marquesVehicule!"
                        @avancerUnEtape="avancerUnEtape"
                        @reculerUnEtape="reculerUnEtape">
                    </cogi-etape3>
                </cogi-tab>
                <cogi-tab title="Etape4" ref="tab4" subtitle="TerritoireEtModeles" :selected="false">
                    <cogi-titresection
                        v-model="vm.culture"
                        :utilise-multilangue="vm.utiliseMultilangue!"
                        :langues="vm.langues!"
                        :imageIcone="vm.imageIcone"
                        titre="VosPreferenceDeTerritoiresCaps"
                        tooltip="ToolTipSection4" />
                    <cogi-etape4
                        v-if="showEtape1"
                        ref="content4"
                        v-model="vm"
                        :sourceItemsTerritoire="vm.territoires as del.ListItem[]"
                        :sourceItemsImplicationOrganisme="vm.implicationOrganisme as del.ListItem[]"
                        :sourceItemsServiceDesires="vm.serviceDesires as del.ListItem[]"
                        :sourceItemsResponsabiliteCoop="vm.responsabiliteCoop as del.ListItem[]"
                        @avancerUnEtape="avancerUnEtape"
                        @reculerUnEtape="reculerUnEtape">
                    </cogi-etape4>
                </cogi-tab>
                <cogi-tab title="Etape5" ref="tab5" subtitle="FinaliserLaDemande" :selected="false">
                    <cogi-titresection
                        v-model="vm.culture"
                        :utilise-multilangue="vm.utiliseMultilangue!"
                        :langues="vm.langues!"
                        :imageIcone="vm.imageIcone"
                        titre="AttestationEtPiecesJustificativesCaps"
                        tooltip="ToolTipSection5" />
                    <cogi-etape5
                        v-if="showEtape1"
                        ref="content5"
                        v-model="vm"
                        v-model:files="files"
                        :sourceItemPointServices="vm.listePointServices!"
                        :sourceItemLienDemandeur="vm.lienParentesDemandeur!"
                        @reculerUnEtape="reculerUnEtape"
                        @soumettre="soumettre" />
                </cogi-tab>
            </cogi-tabs>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive } from 'vue'
    import type { Ref } from 'vue'
    import { del } from './clients/delclient'
    import CogiTitresection from './shared/CogiTitresection.vue'
    import CogiEtape1 from './shared/CogiEtape1.vue'
    import CogiEtape2 from './shared/CogiEtape2.vue'
    import CogiEtape3 from './shared/CogiEtape3.vue'
    import CogiEtape4 from './shared/CogiEtape4.vue'
    import CogiEtape5 from './shared/CogiEtape5.vue'
    import CogiTab from './shared/CogiTab.vue'
    import CogiTabs from './shared/CogiTabs.vue'
    import CogiLoadingSpinner from './shared/CogiLoadingSpinner.vue'
    import DemandeEnLigneVM from './DemandeEnLigneVM'

    const tabs: Ref<typeof CogiTabs | null> = ref(null)
    const content1: Ref<typeof CogiEtape1 | null> = ref(null)
    const content2: Ref<typeof CogiEtape2 | null> = ref(null)
    const content3: Ref<typeof CogiEtape3 | null> = ref(null)
    const content4: Ref<typeof CogiEtape4 | null> = ref(null)
    const content5: Ref<typeof CogiEtape5 | null> = ref(null)
    const showEtape1 = ref(<boolean>false)

    const tab1 = ref(null)
    const tab2 = ref(null)
    const tab3 = ref(null)
    const tab4 = ref(null)
    const tab5 = ref(null)

    interface IPropsForm {
        c?: string
        noSuccursale?: string
        langue: 'fr' | 'en'
    }

    const props = withDefaults(defineProps<IPropsForm>(), {
        langue: 'fr',
    })

    const files = ref(new Array<File>())

    const emit = defineEmits<{
        complete: [{ codeDemande: string }]
    }>()

    let vm = reactive(new DemandeEnLigneVM(props.langue))

    vm.initialiserVM(parseInt(props.noSuccursale!), props.c!).then(() => (showEtape1.value = true))

    async function tabClicked(currentTab: typeof CogiTab, futureTabIndex: number, estAvant: boolean) {
        if (!estAvant) {
            const content = getContent(currentTab.props.title)
            content.validate()
        }

        selectionnerTab(futureTabIndex)
    }

    function getContent(tabTitle: string): typeof CogiEtape1 | typeof CogiEtape2 | typeof CogiEtape3 | typeof CogiEtape4 | typeof CogiEtape5 {
        if (tabTitle == (tab1.value! as typeof CogiTab).props.title) return content1.value as typeof CogiEtape1
        if (tabTitle == (tab2.value! as typeof CogiTab).props.title) return content2.value as typeof CogiEtape2
        if (tabTitle == (tab3.value! as typeof CogiTab).props.title) return content3.value as typeof CogiEtape3
        if (tabTitle == (tab4.value! as typeof CogiTab).props.title) return content4.value as typeof CogiEtape4
        if (tabTitle == (tab5.value! as typeof CogiTab).props.title) return content5.value as typeof CogiEtape5

        throw 'tab invalide!'
    }

    function avancerUnEtape(indexTab: number) {
        selectionnerTab(indexTab + 1)
        window.scrollTo(0, 0)
    }

    function reculerUnEtape(indexTab: number) {
        selectionnerTab(indexTab - 1)
        window.scrollTo(0, 0)
    }

    function selectionnerTab(indexTab: number) {
        const cogiTabs = tabs.value as typeof CogiTabs
        switch (indexTab) {
            case 0:
                cogiTabs.selectTab(tab1.value)
                break
            case 1:
                cogiTabs.selectTab(tab2.value)
                break
            case 2:
                cogiTabs.selectTab(tab3.value)
                break
            case 3:
                cogiTabs.selectTab(tab4.value)
                break
            case 4:
                cogiTabs.selectTab(tab5.value)
                break
        }
    }

    function soumettre() {
        vm.soumettre(files.value, (value: string) => emit('complete', { codeDemande: value }))
    }
</script>
