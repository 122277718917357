<template>
    <span :class="cssClass" v-show="visible">
        <cogi-label :required="required" label="SelectionnerUnOuPlusieursModeles"></cogi-label>

        <cogi-modelehlm
            :visible="modelData.utiliseProgrammeHlm!"
            cssClass="cogi-subtitle grid"
            label="HlmPslPrive"
            v-model="modelData.estHlmPslPrive"
            :hasError="modeleHabitationHasError()"></cogi-modelehlm>
        <cogi-modelecoop
            :visible="modelData.utiliseProgrammeCoop!"
            :utiliseAtelierFederationCoop="modelData.utiliseAtelierFederationCoop!"
            :culture="modelData.culture!"
            cssClass="cogi-subtitle grid"
            label="Coop"
            v-model="modelData.coop!"
            :hasError="modeleHabitationHasError()"
            :sourceItemsResponsabiliteCoop="sourceItemsResponsabiliteCoop"
            :sourceItemsServiceDesires="sourceItemsServiceDesires"
            :sourceItemsImplicationOrganisme="sourceItemsImplicationOrganisme">
        </cogi-modelecoop>
        <cogi-modeleobnl
            :visible="modelData.utiliseProgrammeObnl!"
            :culture="modelData.culture!"
            cssClass="cogi-subtitle grid"
            label="Obnl"
            v-model="modelData.obnl!"
            :hasError="modeleHabitationHasError()"
            :sourceItemsServiceDesires="sourceItemsServiceDesires">
        </cogi-modeleobnl>
    </span>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'
    import CogiModelecoop from './CogiModelecoop.vue'
    import CogiModeleobnl from './CogiModeleobnl.vue'
    import CogiModelehlm from './CogiModelehlm.vue'
    import CogiLabel from './CogiLabel.vue'
    import type { del } from './../clients/delclient'
    import type DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IPropsModeleHabitation {
        required: boolean
        label?: string
        cssClass: string
        valideNow: boolean
        modelValue: DemandeEnLigneVM
        sourceItemsImplicationOrganisme: del.ListItem[]
        sourceItemsServiceDesires: del.ListItem[]
        sourceItemsResponsabiliteCoop: del.ListItem[]
        visible: boolean
    }

    const props = withDefaults(defineProps<IPropsModeleHabitation>(), {
        required: false,
        label: '',
        cssClass: '',
        valideNow: false,
        visible: true,
    })

    const modelData = ref(props.modelValue)
    const hasError = ref(false)

    const emit = defineEmits<{
        validated: [boolean]
    }>()

    const modeleHasData = computed(() => {
        return modelData.value.estHlmPslPrive || modelData.value.coop!.estSelectionne || modelData.value.obnl!.estSelectionne
    })

    function validate() {
        hasError.value = props.visible ? !modeleHasData.value : false
        emit('validated', hasError.value)
    }

    function modeleHabitationHasError() {
        let hasCurrentError = hasError.value && !modeleHasData.value
        if (!hasCurrentError) hasError.value = false
        return hasCurrentError
    }

    watch(
        () => props.valideNow,
        newValue => {
            if (newValue) validate()
        },
    )
</script>
