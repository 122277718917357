<template>
    <div class="grid" ref="template">
        <hr v-if="visible" class="grid-full" />
        <cogi-adresse
            cssClass="grid-full"
            title="AdressePrecedente"
            @validated="validateAdresses"
            :valideNow="valideNow"
            :model-value="adressePrec"
            @update:model-value="() => (model!.adressePrec = adressePrec)"
            :visible="visible"></cogi-adresse>
        <cogi-date
            cssClass="grid-half"
            :culture="culture"
            @validated="validateAdresses"
            label="De"
            :valideNow="valideNow"
            v-model="model!.adressePrecDe"
            :required="true"
            :visible="visible"></cogi-date>
        <cogi-date
            cssClass="grid-half"
            :culture="culture"
            label="A"
            @validated="validateAdresses"
            :valideNow="valideNow"
            :required="true"
            v-model="model!.adressePrecA"
            :dateReassignee="adressePrecAinitiale"
            :visible="visible"></cogi-date>
        <cogi-label cssClass="grid-full redText" label="DateDebutSuperieureDateFin" v-if="visible && estDateAdressePrecDeSuperieureA"></cogi-label>
        <cogi-label cssClass="grid-full redText" label="DateAdresseChevauchement" v-if="visible && estDateAdressePrecChevauchement"></cogi-label>

        <div class="grid grid-full" v-for="(adresse, index) in adressesPrecedentes" :key="index">
            <hr class="grid-full" />
            <cogi-button cssClass="grid-full right" cssClassButton="retirer" text="RetirerCetteAdresse" @click="retirerAdresse(index)"></cogi-button>
            <cogi-adresse
                cssClass="grid-full"
                style="margin-top: -10px"
                title="AdressePrecedente"
                @validated="validateAdresses"
                :valideNow="valideNow"
                v-model="adressesPrecedentes[index]">
            </cogi-adresse>
            <cogi-date
                cssClass="grid-half"
                :culture="culture"
                @validated="validateAdresses"
                label="De"
                :valideNow="valideNow"
                v-model="adressesPrecedentes[index].dateDe"
                :required="true"></cogi-date>
            <cogi-date
                cssClass="grid-half"
                :culture="culture"
                @validated="validateAdresses"
                label="A"
                :valideNow="valideNow"
                v-model="adressesPrecedentes[index].dateA"
                :dateReassignee="adressesPrecedentesAinitiales[index]"
                :required="true"></cogi-date>
            <cogi-label
                cssClass="grid-full redText"
                label="DateDebutSuperieureDateFin"
                v-if="estDateAdressesPrecedentesDeSuperieureA(adressesPrecedentes[index])"></cogi-label>
            <cogi-label cssClass="grid-full redText" label="DateAdresseChevauchement" v-if="estDateAdressesPrecedentesChevauchement(index)"></cogi-label>
        </div>

        <hr v-if="estAjouterAdresseVisible" class="grid-full" />
        <cogi-button
            v-if="estAjouterAdresseVisible"
            cssClass="grid-full right"
            cssClassButton="suivant"
            text="AjouterUneAdresse"
            v-on:click="ajouterAdresse"></cogi-button>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { DateUtil } from './helpers/dateutil'
    import guid from './helpers/guidgen'
    import { del } from '../clients/delclient'

    import CogiAdresse from './CogiAdresse.vue'
    import CogiButton from './CogiButton.vue'
    import CogiDate from './CogiDate.vue'
    import CogiLabel from './CogiLabel.vue'
    import DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IAdressesProps {
        visible: boolean
        valideNow: boolean
        adressePrecAinitiale: Date | undefined
    }

    const props = withDefaults(defineProps<IAdressesProps>(), {
        visible: true,
        valideNow: false,
    })

    const template = ref(null)

    const model = defineModel<DemandeEnLigneVM>()
    const culture = model.value!.culture
    const adressePrec = ref(model.value!.adressePrec ?? new del.Adresse())

    const adressesPrecedentes = ref(model.value!.adressesPrecedentes!)
    const adressesPrecedentesAinitiales = ref(<Array<Date>>[])
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))

    const estAjouterAdresseVisible = computed(() => {
        if (!props.visible || model.value!.adressePrecDe == null || adressesPrecedentes.value.length >= 2) return false
        const diff = monthDiff(model.value!.adressePrecDe, new Date()!)
        return !Number.isNaN(diff) && diff < 24
    })

    const estDateAdressePrecDeSuperieureA = computed(() => {
        return model.value!.adressePrecDe != null && model.value!.adressePrecA != null && model.value!.adressePrecDe > model.value!.adressePrecA
    })

    const estDateAdressePrecChevauchement = computed(() => {
        return (
            model.value!.adresseActuelleDepuis != null && model.value!.adressePrecA != null && model.value!.adressePrecA >= model.value!.adresseActuelleDepuis
        )
    })

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [DemandeEnLigneVM]
    }>()

    function ajouterAdresse() {
        let adresse = new del.AdressePrecedenteModel()
        adresse.id = guid.GenererGuid()
        adresse.noRue = ''
        adresse.app = ''
        adresse.codePostal = ''
        adresse.ville = ''
        adresse.dateDe = new Date()
        adresse.dateA = new Date()

        if (adressesPrecedentes.value.length < 1) {
            let datePrecedenteDe = model.value!.adressePrecDe
            if (datePrecedenteDe != null) {
                let dateMoinsUnJour = DateUtil.addDays(datePrecedenteDe, -1)
                adressesPrecedentesAinitiales.value.push(dateMoinsUnJour)
                adresse.dateA = dateMoinsUnJour
            }
        } else {
            let datePrecedenteDe = adressesPrecedentes.value[adressesPrecedentes.value.length - 1].dateDe
            if (datePrecedenteDe != null) {
                let dateMoinsUnJour = DateUtil.addDays(datePrecedenteDe, -1)
                adressesPrecedentesAinitiales.value.push(dateMoinsUnJour)
                adresse.dateA = dateMoinsUnJour
            }
        }

        adressesPrecedentes.value.push(adresse)
    }

    function retirerAdresse(index: number) {
        adressesPrecedentesAinitiales.value.splice(index, 1)
        adressesPrecedentes.value.splice(index, 1)
    }
    function validationDone(hasError: boolean) {
        if (adressesPrecedentes.value) {
            let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(hasError)

            if (!validationStatut.validationCompleted) return

            emitValidated(validationStatut.hasError || hasError)
        } else emitValidated(hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function validateAdresses(hasError: boolean) {
        if (!adressesPrecedentes.value) return true

        if (props.visible && estDateAdressePrecDeSuperieureA.value) return false
        if (
            adressesPrecedentes.value.some(function (adresse: del.AdressePrecedenteModel) {
                return estDateAdressesPrecedentesDeSuperieureA(adresse)
            })
        )
            return false

        if (props.visible && estDateAdressePrecChevauchement.value) return false
        if (
            adressesPrecedentes.value.some(function (adresse: del.AdressePrecedenteModel, index: number) {
                return estDateAdressesPrecedentesChevauchement(index)
            })
        )
            return false

        return validationDone(hasError)
    }

    function monthDiff(d1: Date, d2: Date) {
        let months
        months = (d2.getFullYear() - d1.getFullYear()) * 12
        months -= d1.getMonth()
        months += d2.getMonth()
        return months <= 0 ? 0 : months
    }

    function estDateAdressesPrecedentesDeSuperieureA(adresse: del.AdressePrecedenteModel) {
        return adresse.dateDe != null && adresse.dateA != null && adresse.dateDe > adresse.dateA
    }

    function estDateAdressesPrecedentesChevauchement(index: number) {
        let adresse = adressesPrecedentes.value[index]
        if (adresse.dateA == null) return false

        if (index == 0) {
            return model.value!.adressePrecDe != null && adresse.dateA >= model.value!.adressePrecDe
        } else {
            return adressesPrecedentes.value[0].dateDe != null && adresse.dateA >= adressesPrecedentes.value[0].dateDe
        }
    }

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            if (adressesPrecedentes.value) {
                let nbAdditionnalElements = (adressesPrecedentes.value.length - 1) * 3
                let startValidation = validatorAccumulator.value.startValidationWithAdditionalElements(this, nbAdditionnalElements)
                if (!startValidation) emitValidated(false)
            }
        },
        { deep: true },
    )

    watch(
        () => adressePrec,
        newValue => {
            model.value!.adressePrec = newValue.value
        },
        { deep: true },
    )
</script>
