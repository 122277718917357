<template>
    <div>
        <carte-immobilier
            :code-organisme="codeOrganisme"
            :no-succursale="noSuccursale"
            :coordonneesGeographiques="coordonneesGeographiques"
            @OuvrirDetail="ouvrirDetail"></carte-immobilier>
        <div class="lay-section">
            <input type="text" class="lay-col-3 recherche" @keydown.enter="rechercher" v-model="adresseSaisie" />
            <filtre-sous-batiment
                :codeOrganisme="codeOrganisme"
                :langue="langue"
                :adresseSaisie="adresseSaisie"
                :territoires="territoires"
                :categories="categories"
                :sousCategories="sousCategories"
                :typeHabitations="typeHabitations"
                :specifications="specifications"
                v-model:filtres-selectionnes="filtresSelectionnes"
                @Rechercher="rechercher">
            </filtre-sous-batiment>
            <v-btn class="lay-col-1" @click="rechercher" color="purple" icon="mdi-magnify"></v-btn>
        </div>
    </div>
    <br />
    <br />
    <v-data-iterator :items="resultats" :items-per-page="9">
        <template v-slot:default="{ items }">
            <v-row>
                <v-col v-for="(item, i) in items" :key="i" cols="12" xs="12" sm="6" md="4">
                    <v-card>
                        <v-card-title>{{ item.raw.territoire }}</v-card-title>

                        <v-img :aspect-ratio="1.4" width="200" :src="genererImage(item.raw.thumbnail)" cover> </v-img>

                        <v-card-subtitle>{{ item.raw.adresse }}</v-card-subtitle>
                        <template v-slot:actions>
                            <v-btn height="48" @click="ouvrirDetail(item.raw.cleNumerique!)"> Details </v-btn>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <template v-slot:footer="{ page, pageCount, prevPage, nextPage }" v-if="resultatsDisponibles">
            <div class="d-flex align-center justify-center pa-4">
                <v-btn :disabled="page === 1" density="comfortable" icon="mdi-arrow-left" variant="tonal" rounded @click="prevPage"></v-btn>

                <div class="mx-2 text-caption">Page {{ page }} {{ t('de') }} {{ pageCount }}</div>

                <v-btn :disabled="page >= pageCount" density="comfortable" icon="mdi-arrow-right" variant="tonal" rounded @click="nextPage"></v-btn>
            </div>
        </template>
    </v-data-iterator>
    <fiche-detaillee-sous-batiment
        :codeOrganisme="codeOrganisme"
        :noSuccursale="noSuccursale"
        :langue="langue"
        :cleNumerique="cleNumeriqueSelectionnee"
        v-model:dialog="dialog">
    </fiche-detaillee-sous-batiment>
</template>

<script setup lang="ts">
    import { ref, watch, onMounted } from 'vue'
    import { webcom } from '@clients/webcom'
    import { i18n } from '../../locales/i18n-wc'
    import { settings } from '@shared/settings'
    import CarteImmobilier from './CarteImmobilier.vue'
    import { VBtn } from 'vuetify/components'
    import FicheDetailleeSousBatiment from './FicheDetailleeSousBatiment.vue'
    import FiltreSousBatiment from './FiltreSousBatiment.vue'
    import type { IFiltres } from './FiltresSelectionnes'
    import { genererImage } from './Helper'

    const resultatsDisponibles = ref(false)
    const resultats = ref<webcom.GetAllSousBatimentsModel[]>([])
    const territoires = ref<webcom.CodeEtDescription[]>([])
    const categories = ref<webcom.CodeEtDescription[]>([])
    const sousCategories = ref<webcom.CodeEtDescription[]>([])
    const typeHabitations = ref<webcom.CodeEtDescription[]>([])
    const specifications = ref<webcom.Specification[]>([])
    const adresseSaisie = ref('')
    const dialog = ref(false)
    const cleNumeriqueSelectionnee = ref(0)
    const coordonneesGeographiques = ref<webcom.CoordonneesGeographiques[]>([])
    const filtresSelectionnes = ref<IFiltres>({
        territoiresSelectionnes: [],
        categoriesSelectionnees: [],
        sousCategoriesSelectionnees: [],
        typeHabitationsSelectionnes: [],
        optionsSpecificationsSelectionnees: [],
    })

    interface IPropsRechercheImmobilier {
        codeOrganisme: string
        noSuccursale: number
        langue?: string
    }

    const props = withDefaults(defineProps<IPropsRechercheImmobilier>(), {
        langue: 'fr',
    })

    watch(
        () => props.langue,
        function (newValue) {
            i18n.locale = newValue
            obtenirFiltre()
        },
    )

    onMounted(() => {
        i18n.locale = props.langue
        obtenirCoordonnesGeographiques()
    })

    function getClient() {
        return new webcom.Client(settings.bffBaseUrl)
    }

    function rechercher() {
        var client = getClient()
        client
            .sousBatiments(
                props.codeOrganisme,
                props.langue,
                adresseSaisie.value,
                filtresSelectionnes.value.territoiresSelectionnes,
                filtresSelectionnes.value.categoriesSelectionnees,
                filtresSelectionnes.value.sousCategoriesSelectionnees,
                filtresSelectionnes.value.typeHabitationsSelectionnes,
                filtresSelectionnes.value.optionsSpecificationsSelectionnees,
            )
            .then((result: webcom.GetAllSousBatimentsResponse) => {
                resultats.value = result.items!
                resultatsDisponibles.value = resultats.value.length > 0
            })
            .catch(() => {
                resultatsDisponibles.value = false
            })
    }
    //on traduit avec cette fonction parce que ce component est aussi utilisé dans un web component
    function t(key: string) {
        return i18n.t(key)
    }

    function obtenirFiltre() {
        console.log(props.langue)
        let client = getClient()
        client
            .filtres(props.codeOrganisme, props.langue, undefined)
            .then((result: webcom.FiltresResponse) => {
                territoires.value = result.territoires!
                categories.value = result.categories!
                sousCategories.value = result.sousCategories!
                typeHabitations.value = result.typeHabitations!
                specifications.value = result.specifications!
            })
            .catch(() => {})
    }

    function obtenirCoordonnesGeographiques() {
        let client = getClient()
        client
            .coordonneesGeographiques(props.codeOrganisme)
            .then((result: webcom.CoordonneesGeographiquesResponse) => {
                coordonneesGeographiques.value = result.coordonneesGeographiques!
                coordonneesGeographiques.value = coordonneesGeographiques.value
                    .filter(el => el.geoLocalisation?.latitude != null && el.geoLocalisation?.longitude != null)
                    .slice(0, 2) // A Retirer en prod
            })
            .catch(() => {})
    }

    function ouvrirDetail(cleNumerique: number) {
        cleNumeriqueSelectionnee.value = cleNumerique
        dialog.value = true
    }
</script>

<style scoped>
    @import '../../css/immobilier.less';
</style>
