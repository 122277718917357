<template>
    <div :class="cssClass">
        <div class="grid">
            <template v-for="(piecejustificative, index) in piecejustificatives" :key="index">
                <cogi-label cssClass="gridspan5" label="Prenom"></cogi-label>
                <cogi-label cssClass="gridspan11" label="Nom"></cogi-label>
                <cogi-regex cssClass="gridspan5" :disabled="true" v-model="piecejustificative.prenom"></cogi-regex>
                <cogi-regex cssClass="gridspan5" :disabled="true" v-model="piecejustificative.nom"></cogi-regex>
                <cogi-checkbox
                    cssClass="gridspan3 center verticalCenter"
                    label="PreuveAge"
                    v-model="piecejustificative.piecePreuveAge"
                    :onChange="changePiecePreuveAge(piecejustificative.Id)"></cogi-checkbox>
                <cogi-checkbox
                    cssClass="gridspan3 center verticalCenter"
                    label="Revenus"
                    v-model="piecejustificative.pieceRevenus"
                    :onChange="changePieceRevenus(piecejustificative.Id)"></cogi-checkbox>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import { PieceJustificativeEmitPoco, PieceJustificativePoco } from './pocos/PieceJustificativePoco'

    interface IPropsPieceJustificative {
        value?: PieceJustificativePoco[]
        cssClass?: string
    }

    const props = withDefaults(defineProps<IPropsPieceJustificative>(), {
        cssClass: '',
    })

    const piecejustificatives = ref(<Array<PieceJustificativePoco>>[])

    const emit = defineEmits<{
        input: [PieceJustificativeEmitPoco]
    }>()

    function changePiecePreuveAge(itemId: string) {
        return () => callEmitInput(itemId, PieceJustificativeEmitPoco.propertyPiecePreuveAge)
    }

    function changePieceRevenus(itemId: string) {
        return () => callEmitInput(itemId, PieceJustificativeEmitPoco.propertyPieceRevenus)
    }

    function callEmitInput(itemId: string, propertyChanged: string) {
        emitInput(new PieceJustificativeEmitPoco(itemId, propertyChanged))
    }

    function emitInput(emitMessage: PieceJustificativeEmitPoco) {
        emit('input', emitMessage)
    }

    watch(
        () => props.value,
        newValue => {
            piecejustificatives.value = newValue as PieceJustificativePoco[]
        },
        { deep: true },
    )
</script>
