<template>
    <div :class="cssClass" v-show="visible" ref="template">
        <div class="grid">
            <cogi-label class="grid-full" :required="required" :label="label"></cogi-label>
            <cogi-regex
                class="grid-full-with-end"
                @validated="validate"
                :required="required"
                :valideNow="valideNow"
                regex="^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$"
                v-model="valeur"
                :visible="visible"
                :funcFormatText="calculerValeurMonetaire"></cogi-regex>
            <cogi-label class="grid-end verticalCenter" label="$"></cogi-label>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, getCurrentInstance } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiRegex from './CogiRegex.vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'

    const instance = getCurrentInstance()

    interface IPropsMonetaire {
        required: boolean
        regex?: string
        placeholder?: string
        disabled?: boolean
        modelValue?: number
        valideNow: boolean
        cssClass: string
        label?: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsMonetaire>(), {
        required: false,
        regex: '',
        placeholder: '',
        disabled: false,
        valideNow: false,
        cssClass: '',
        label: '',
        visible: true,
    })

    const valeur = ref(<string | undefined>'')
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [number]
    }>()

    valeur.value = props.modelValue ? props.modelValue.toString() : ''

    function validate(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function calculerValeurMonetaire(chaine: string) {
        if (!chaine) return chaine

        let decimalSeparateur = chaine.split('.')
        let possedeDecimal = decimalSeparateur.length > 1
        let decimalSeparateur0 = decimalSeparateur[0].replace(/\D/g, '')
        if (decimalSeparateur0 != '') decimalSeparateur0 = parseInt(decimalSeparateur0).toString()
        let val = decimalSeparateur0 + (possedeDecimal ? '.' + decimalSeparateur[1].replace(/\D/g, '').substring(0, 2) : '')

        return val
    }

    watch(
        () => valeur.value,
        newValue => {
            emit('update:modelValue', parseInt(newValue as string)), { deep: true }
        },
    )

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
