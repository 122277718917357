<template>
    <div style="width: 100%" ref="map" class="carte"></div>
</template>
<script setup lang="ts">
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-nocheck
    import { ref, watch } from 'vue'
    import { i18n } from '../../locales/i18n-wc'
    import { webcom } from '../clients/webcom'
    const map = ref<HTMLDivElement>(null)

    interface IPropsCarteImmobilier {
        codeOrganisme: string
        noSuccursale: number
        langue?: string
        coordonneesGeographiques: webcom.coordonneesGeographiques[]
    }

    const props = withDefaults(defineProps<IPropsCarteImmobilier>(), {
        langue: 'fr',
    })

    const emit = defineEmits<{
        ouvrirDetail: number
    }>()

    watch(
        () => props.langue,
        function (newValue) {
            ;(i18n as any).locale = newValue
        },
    )

    watch(
        () => props.coordonneesGeographiques,
        function () {
            initMap()
        },
    )
    ;(g => {
        var h,
            a,
            k,
            p = 'The Google Maps JavaScript API',
            c = 'google',
            l = 'importLibrary',
            q = '__ib__',
            m = document,
            b = window
        b = b[c] || (b[c] = {})
        var d = b.maps || (b.maps = {}),
            r = new Set(),
            e = new URLSearchParams(),
            u = () =>
                h ||
                (h = new Promise(async (f, n) => {
                    await (a = m.createElement('script'))
                    e.set('libraries', [...r] + '')
                    for (k in g)
                        e.set(
                            k.replace(/[A-Z]/g, t => '_' + t[0].toLowerCase()),
                            g[k],
                        )
                    e.set('callback', c + '.maps.' + q)
                    a.src = `https://maps.${c}apis.com/maps/api/js?` + e
                    d[q] = f
                    a.onerror = () => (h = n(Error(p + ' could not load.')))
                    a.nonce = m.querySelector('script[nonce]')?.nonce || ''
                    m.head.append(a)
                }))
        d[l] ? console.warn(p + ' only loads once. Ignoring:', g) : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)))
    })({
        key: 'AIzaSyCPyQ0FN9LMRYWf8YxKJ8xFFdLZeR5AUY8',
        v: 'weekly',
        // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
        // Add other bootstrap parameters as needed, using camel case.
    })

    async function initMap() {
        const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary
        const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary

        const zeMap = new Map(map.value, {
            center: { lat: 46.82431, lng: -71.341515 }, //Valeur par defaut
            mapId: 'CARTE_BATIMENTS',
        })

        var bounds = new google.maps.LatLngBounds()
        for (var coordonnee of props.coordonneesGeographiques) {
            var latLng = new google.maps.LatLng(coordonnee?.geoLocalisation?.latitude ?? 46.82431, coordonnee?.geoLocalisation?.longitude ?? -71.341515)
            new AdvancedMarkerElement({
                map: zeMap,
                position: latLng,
                title: coordonnee?.adresse ?? '',
            }).addListener('click', () => ouvrirDetail(coordonnee.cleNumerique ?? 0))

            bounds.extend(latLng)
        }

        zeMap.setCenter(bounds.getCenter())
        zeMap.fitBounds(bounds)
    }

    function ouvrirDetail(cleNumerique: number) {
        emit('ouvrirDetail', cleNumerique)
    }

    //on traduit avec cette fonction parce que ce component est aussi utilisé dans un web component
    function t(key: string) {
        return i18n.t(key)
    }
</script>
