<template>
    <div class="grid titre-section" :class="cssClass" v-show="visible">
        <cogi-image :image="imageIcone" class="grid-begining" />
        <cogi-label :cssClass="labelCssClass" cssClassLabel="titre-section indentation" :label="titre" :tooltip="tooltip"> </cogi-label>
        <cogi-langue cssClass="grid-end" v-model="culture" :visible="utiliseMultilangue" :langues="langues"> </cogi-langue>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue'
    import CogiImage from './CogiImage.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiLangue from './CogiLangue.vue'
    import { ImageUtil } from './helpers/imageutil'
    import { i18n } from '@/locales/i18n-wc'

    interface IPropsTitreSection {
        modelValue?: string
        titre: string
        tooltip?: string
        visible?: boolean
        cssClass?: string
        utiliseMultilangue: boolean
        langues: Array<string>
        imageIcone?: object
    }

    const props = withDefaults(defineProps<IPropsTitreSection>(), {
        modelValue: undefined,
        titre: '',
        tooltip: '',
        visible: true,
        cssClass: '',
        utiliseMultilangue: false,
        langues: () => [],
    })

    const culture = ref('')

    const emit = defineEmits<{
        'update:modelValue': [string]
    }>()

    const labelCssClass = computed(() => {
        return !ImageUtil.EstImageNulle(props.imageIcone as { extension: string; contenuBase64: string }) ? 'grid-full-with-begining-end' : 'grid-full-with-end'
    })

    onMounted(() => {
        culture.value = props.modelValue!
    })

    watch(
        () => culture.value,
        newValue => {
            i18n.locale = newValue
            emit('update:modelValue', newValue)
        },
    )
</script>

<style scoped></style>
