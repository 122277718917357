<template>
    <v-menu v-model="menuFiltreOuvert" :close-on-content-click="false" location="bottom end">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" append-icon="mdi-filter-outline" class="lay-col-1"> {{ t('Filtres') }}</v-btn>
        </template>
        <v-card>
            <v-list v-model="sousMenusFiltreOuvert">
                <v-card-item>
                    <v-list-group value="territoires">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Territoires"></v-list-item>
                        </template>
                        <v-list-item v-bind="props">
                            <v-checkbox
                                v-for="territoire in territoires"
                                :key="territoire.code"
                                :label="territoire.description"
                                v-model="filtresSelectionnes.territoiresSelectionnes"
                                :inputId="territoire.code"
                                name="territoire"
                                :value="territoire.code" />
                        </v-list-item>
                    </v-list-group>
                </v-card-item>
                <v-card-item>
                    <v-list-group value="categories">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Catégories"></v-list-item>
                        </template>
                        <v-list-item v-bind="props">
                            <v-checkbox
                                v-for="categorie in categories"
                                :key="categorie.code"
                                :label="categorie.description"
                                v-model="filtresSelectionnes.categoriesSelectionnees"
                                :inputId="categorie.code"
                                name="categorie"
                                :value="categorie.code" />
                        </v-list-item>
                    </v-list-group>
                </v-card-item>
                <v-card-item>
                    <v-list-group value="typeHabitations">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Type d'habitations"></v-list-item>
                        </template>
                        <v-list-item v-bind="props">
                            <v-checkbox
                                v-for="typeHabitation in typeHabitations"
                                :key="typeHabitation.code"
                                :label="typeHabitation.code"
                                v-model="filtresSelectionnes.typeHabitationsSelectionnes"
                                :inputId="typeHabitation.code"
                                name="typeHabitation"
                                :value="typeHabitation.code" />
                        </v-list-item>
                    </v-list-group>
                </v-card-item>
                <v-card-item>
                    <v-list-group value="sousCategories">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Nombre de chambres"></v-list-item>
                        </template>
                        <v-list-item v-bind="props">
                            <v-checkbox
                                v-for="sousCategorie in sousCategories"
                                :key="sousCategorie.code"
                                :label="sousCategorie.description"
                                v-model="filtresSelectionnes.sousCategoriesSelectionnees"
                                :inputId="sousCategorie.code"
                                name="sousCategorie"
                                :value="sousCategorie.code" />
                        </v-list-item>
                    </v-list-group>
                </v-card-item>
                <v-card-item>
                    <v-list-group value="caracteristiques">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Caractérisques"></v-list-item>
                        </template>
                        <v-list-item v-bind="props">
                            <div v-for="(specification, index) in specifications" :key="index">
                                <v-list-item-title>{{ specification.specDescription }}</v-list-item-title>
                                <v-checkbox
                                    v-for="option in specification.options"
                                    :key="option.cleNumOption"
                                    :label="option.description"
                                    v-model="filtresSelectionnes.optionsSpecificationsSelectionnees"
                                    :inputId="option.cleNumOption"
                                    name="specificatiton"
                                    :value="option.cleNumOption" />
                            </div>
                        </v-list-item>
                    </v-list-group>
                </v-card-item>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="lay-col-2 bouton" @click="reinitialiser">{{ t('Reinitialiser') }}</v-btn>
                    <v-btn class="lay-col-2 bouton" @click="rechercher">{{ t('Rechercher') }}</v-btn>
                </v-card-actions>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script setup lang="ts">
    import { ref, onMounted } from 'vue'
    import { webcom } from '@clients/webcom'
    import { i18n } from '../../locales/i18n-wc'
    import type { IFiltres } from './FiltresSelectionnes'

    const sousMenusFiltreOuvert = ref([])
    const menuFiltreOuvert = ref(false)
    const filtresSelectionnes = defineModel<IFiltres>('filtresSelectionnes', { required: true })
    const emit = defineEmits<{
        rechercher: any
    }>()

    interface IPropsFiltreSousBatiment {
        codeOrganisme: string
        langue: string
        adresseSaisie: string
        territoires: webcom.CodeEtDescription[]
        categories: webcom.CodeEtDescription[]
        sousCategories: webcom.CodeEtDescription[]
        typeHabitations: webcom.CodeEtDescription[]
        specifications: webcom.Specification[]
    }

    const props = withDefaults(defineProps<IPropsFiltreSousBatiment>(), {
        langue: 'fr',
    })

    onMounted(() => {})

    //on traduit avec cette fonction parce que ce component est aussi utilisé dans un web component
    function t(key: string) {
        return i18n.t(key)
    }

    function reinitialiser() {
        filtresSelectionnes.value.territoiresSelectionnes =
            filtresSelectionnes.value.categoriesSelectionnees =
            filtresSelectionnes.value.sousCategoriesSelectionnees =
            filtresSelectionnes.value.typeHabitationsSelectionnes =
            filtresSelectionnes.value.optionsSpecificationsSelectionnees =
                []
    }

    function rechercher() {
        menuFiltreOuvert.value = false
        emit('rechercher')
    }
</script>
<style scoped>
    @import '../../css/immobilier.less';
</style>
