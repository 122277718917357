<template>
    <v-dialog v-model="dialog" width="1200px">
        <v-card width="auto">
            <v-card-item>
                <v-card-title> Détails </v-card-title>
            </v-card-item>
            <v-carousel model-value="0">
                <v-carousel-item
                    v-for="(photoSousBatiment, i) in sousBatiment?.photos"
                    :key="i"
                    :src="genererImage(photoSousBatiment.photo?.image, photoSousBatiment.photo?.extension)"
                    cover
                    show-arrows="hover"></v-carousel-item>
            </v-carousel>
            <div class="boxDetail">
                <div style="flex: 1">L'adresse</div>
                <div style="flex: 2">{{ sousBatiment?.adresse }}</div>
            </div>
            <div class="boxDetail">
                <div style="flex: 1">Types d'habitation</div>
                <div style="flex: 2">{{ typesHabitations }}</div>
            </div>
            <div class="boxDetail">
                <div style="flex: 1">Territoire</div>
                <div style="flex: 2">{{ sousBatiment?.territoire }}</div>
            </div>
            <div v-if="sousBatiment?.specifications?.length! > 0">
                <h3>Spécifications</h3>
                <template v-for="specification in specificationsEtOptions" :key="specification.nom">
                    <div class="boxDetail">
                        <div style="flex: 1">{{ specification.nom }}</div>
                        <div style="flex: 2">{{ specification.option }}</div>
                    </div>
                </template>
            </div>
            <template v-slot:actions>
                <v-btn class="ms-auto" text="Retour aux propriétés" @click="dialog = false"></v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
    import { ref, watch, onMounted } from 'vue'
    import { webcom } from '@clients/webcom'
    import { i18n } from '../../locales/i18n-wc'
    import { settings } from '@shared/settings'
    import { genererImage } from './Helper'

    interface IPropsFicheDetailleeSousBatiment {
        codeOrganisme: string
        noSuccursale: number
        langue: string
        cleNumerique: number
    }

    interface ISpecification {
        nom: string
        option: string
    }

    const props = withDefaults(defineProps<IPropsFicheDetailleeSousBatiment>(), {
        langue: 'fr',
    })

    const dialog = defineModel<boolean>('dialog')
    const sousBatiment = ref<webcom.SousBatimentModel>()
    const typesHabitations = ref('')
    const specificationsEtOptions = ref<ISpecification[]>([])

    watch(
        () => props.langue,
        function (newValue) {
            i18n.locale = newValue
        },
    )

    watch(
        () => props.cleNumerique,
        function (newValue) {
            if (newValue != 0) {
                obtenirDetail()
            }
        },
    )

    onMounted(() => {
        i18n.locale = props.langue
    })

    function getClient() {
        return new webcom.Client(settings.bffBaseUrl)
    }

    //on traduit avec cette fonction parce que ce component est aussi utilisé dans un web component
    function t(key: string) {
        return i18n.t(key)
    }

    function obtenirDetail() {
        let client = getClient()
        client
            .sousBatiment(props.cleNumerique, props.codeOrganisme, props.langue, undefined)
            .then((result: webcom.SousBatimentResponse) => {
                sousBatiment.value = result.sousBatiment!
                typesHabitations.value =
                    sousBatiment.value?.typeHabitations
                        ?.filter(t => t.code != null)
                        .map(t => t.code)
                        .join(', ') ?? ''
                gererLesOptionsMultiples(sousBatiment.value?.specifications ?? [])
                //photo.value = sousBatiment.value.photos![0]
            })
            .catch(() => {})
    }

    function gererLesOptionsMultiples(specifications: webcom.SpecificationModel[]) {
        specifications.forEach(spec => {
            if (typeof spec.nom === 'string' && !specificationsEtOptions.value.find(s => s.nom === spec.nom)) {
                const specification = <ISpecification>{}

                specification.nom = spec.nom
                specification.option =
                    specifications
                        .filter(s => s.nom == spec.nom)
                        .map(s => s?.option)
                        .join(', ') ?? ''

                specificationsEtOptions.value.push(specification)
            }
        })
    }
</script>

<style scoped>
    @import '../../css/immobilier.less';
</style>
