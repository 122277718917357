export class PieceJustificativePoco {
    public Id!: string
    public prenom!: string
    public nom!: string
    public piecePreuveAge!: boolean
    public pieceRevenus!: boolean
}

export class PieceJustificativeEmitPoco {
    constructor(public Id: string, public propertyChanged: string) {}

    public static propertyPiecePreuveAge = 'piecePreuveAge'
    public static propertyPieceRevenus = 'pieceRevenus'
}
