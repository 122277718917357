<template>
    <div :class="cssClass" v-show="visible">
        <div class="grid">
            <template v-for="item in props.items" :key="item.valeur">
                <cogi-radio
                    :cssClass="item.css"
                    :label="item.label"
                    v-model="model"
                    :groupename="groupename"
                    :valeur="item.valeur"
                    :required="required"
                    :hasError="hasError"></cogi-radio>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import CogiRadio from './CogiRadio.vue'
    import type { RadiolistPoco } from './pocos/RadiolistPoco'

    interface IPropsRadioList {
        items: Array<RadiolistPoco> | null
        groupename: string
        required: boolean
        valideNow: boolean
        cssClass: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsRadioList>(), {
        items: null,
        groupename: 'radioList',
        required: false,
        cssClass: '',
        visible: true,
    })

    const model = defineModel<string>()

    //const selected = ref('Locataire')

    const hasError = ref(false)

    const emit = defineEmits<{
        validated: [boolean]
    }>()

    watch(model, () => {
        baseValidate()
    })

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )

    // watch(
    //     () => selected.value,
    //     (newValue: string) => {
    //         model.value = newValue
    //     }
    // )

    function baseValidate(funcEmitValidated: () => void = () => {}) {
        hasError.value = false

        if (!props.visible) {
            funcEmitValidated()
            return
        }

        if (props.required) {
            if (!model.value || model.value == '') {
                hasError.value = true
            }
        }

        funcEmitValidated()
    }

    function validate() {
        baseValidate(() => emit('validated', hasError.value))
    }
</script>
