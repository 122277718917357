<template>
    <div :class="cssClass" v-show="visible" ref="template">
        <div class="grid">
            <cogi-label-only class="gridspan4 cogi-block-title" :label="title" />
            <autocomplete
                ref="champRecherche"
                :search="chercherAdresse"
                :get-result-value="obtenirDescriptionAdresse"
                @submit="assignerAdresse"
                submit-on-enter
                :placeholder="labelRechercheAdresse"
                :aria-label="labelRechercheAdresse"
                class="gridspan12 cogi-block-title" />
            <cogi-regex
                :visible="visible"
                cssClass="gridspan12"
                @validated="validated"
                label="NoRue"
                :valideNow="valideNow"
                :required="true"
                v-model="adresse.noRue"></cogi-regex>
            <cogi-regex
                :visible="visible"
                cssClass="gridspan4"
                @validated="validated"
                label="App"
                :valideNow="valideNow"
                :required="false"
                v-model="adresse.app"></cogi-regex>
            <cogi-regex
                :visible="visible"
                class="grid-half"
                @validated="validated"
                label="Ville"
                :valideNow="valideNow"
                :required="true"
                v-model="adresse.ville"></cogi-regex>
            <cogi-inputmask
                ref="codePostal"
                :visible="visible"
                class="grid-half"
                @validated="validated"
                label="CodePostal"
                :valideNow="valideNow"
                :required="true"
                :placeholderIsVisible="false"
                pattern="\w\d\w \d\w\d"
                placeholder="_X_ X_X"
                charset="_X_ X_X"
                validsample="A1A 1A1"
                v-model="adresse.codePostal"
                :valeurReassignee="adresse.codePostal"></cogi-inputmask>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch, getCurrentInstance } from 'vue'
    import type { ValidationStatutPoco } from './pocos/ValidationStatutPoco'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator } from './helpers/validatorAccumulator'
    import CogiInputmask from './CogiInputmask.vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import { i18n } from '../../../locales/i18n-wc'
    import { del } from '../clients/delclient'
    import { settings } from '@shared/settings'
    import Autocomplete from '@trevoreyre/autocomplete-vue'

    interface IAdresseResult {
        noRue?: string | undefined
        ville?: string | undefined
        codePostal?: string | undefined
        app?: string | undefined
        description?: string | undefined
    }

    interface IPropsAdresse {
        modelValue: del.Adresse
        valideNow: boolean
        cssClass?: string
        title: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsAdresse>(), {
        valideNow: false,
        cssClass: '',
        title: '',
        visible: true,
    })

    const template = ref(null)
    const champRecherche = ref(null)

    const adresse = ref(props.modelValue)
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const instance = getCurrentInstance()

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [object]
    }>()

    const labelRechercheAdresse = computed(() => {
        return i18n.t('RechercherVotreAdresse')
    })

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return
        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    async function chercherAdresse(mots: string) {
        if (mots === undefined || mots.length < 3) {
            return []
        }

        const client = new del.DemandeEnLigneClient(settings.bffBaseUrl)

        return await client
            .adresses(mots)
            .then(function (response) {
                return response
            })
            .catch(() => {
                return []
            })
    }

    function obtenirDescriptionAdresse(adresse: IAdresseResult) {
        return adresse.description
    }

    function assignerAdresse(result: del.AdresseResult) {
        if (!result) return

        adresse.value.noRue = result.noRue?.toString()
        adresse.value.ville = result.ville
        adresse.value.app = result.app
        adresse.value.codePostal = result.codePostal

        // Patch pour vider le contenu du champs de recherche au lieu de mettre la valeur choisie
        viderRecherche()
    }

    function viderRecherche() {
        const autocomplete = champRecherche.value as any
        autocomplete.value = ''

        //on mets le focus sur le prochain controle
        const champRechercheHtml = autocomplete.$el as HTMLElement
        const nextInput = champRechercheHtml.nextElementSibling!.querySelector('input') as HTMLInputElement
        nextInput.focus()
    }

    watch(
        () => adresse.value,
        newValue => {
            emit('update:modelValue', newValue), { deep: true }
        },
    )
</script>

<style scoped>
    @import '@trevoreyre/autocomplete-vue/dist/style.css';
</style>
