<template>
    <div :class="cssClass" v-show="visible" ref="template">
        <div class="grid">
            <hr class="grid-full" />
            <cogi-label cssClass="grid-half cogi-block-title" :dontTranslate="true" :label="vehiculeIndex" />
            <cogi-button cssClass="grid-half right" cssClassButton="retirer" text="RetirerCeVehicule" @click="retirerVehicule" />
            <cogi-dropdownlist
                cssClass="gridspan5"
                :culture="culture"
                label="Marque"
                @validated="validate"
                :valideNow="valideNow"
                :sourceItems="sourceItems"
                :required="true"
                :showEmptyItem="true"
                v-model="vehicule.marque"
                :visible="visible"></cogi-dropdownlist>
            <cogi-regex
                cssClass="gridspan6"
                label="Modele"
                @validated="validate"
                :valideNow="valideNow"
                :required="true"
                v-model="vehicule.modele"
                :visible="visible"></cogi-regex>
            <cogi-inputmask
                cssClass="gridspan5"
                label="Annee"
                @validated="validate"
                :valideNow="valideNow"
                id="annee_{0}"
                pattern="[1-2]{1}[0-9]{3}"
                placeholder="XXXX"
                :required="true"
                v-model="vehicule.annee"
                :visible="visible"></cogi-inputmask>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, getCurrentInstance } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'

    import CogiRegex from './CogiRegex.vue'
    import CogiDropdownlist from './CogiDropdownlist.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiButton from './CogiButton.vue'
    import CogiInputmask from './CogiInputmask.vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import type { del } from '../clients/delclient'

    interface IPropsVehicule {
        sourceItems: del.ListItem[]
        modelValue: del.Vehicule
        valideNow: boolean
        cssClass: string
        index: number
        visible: boolean
        culture: string
    }

    const props = withDefaults(defineProps<IPropsVehicule>(), {
        valideNow: false,
        cssClass: '',
        index: 0,
        visible: true,
        culture: 'fr',
    })

    const vehicule = ref(props.modelValue)
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))

    const vehiculeIndex = computed(() => {
        return i18n.t('Vehicule') + ' ' + (props.index + 1)
    })

    const emit = defineEmits<{
        retirerVehicule: []
        validated: [boolean]
        'update:modelValue': [del.Vehicule]
    }>()

    function retirerVehicule() {
        emit('retirerVehicule')
    }

    function validate(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    watch(
        () => vehicule.value,
        newValue => {
            emit('update:modelValue', newValue), { deep: true }
        },
    )

    const instance = getCurrentInstance()
    watch(
        () => props.valideNow,
        newValue => {
            if (!props.visible || !newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
