import { defineCustomElement } from 'vue'
import MonRangWebComponent from './MonRang.ce.vue'
import RechercheImmobilierWebComponent from './RechercheImmobilier.ce.vue'
import FormulaireDemandeComponent from './FormulaireDemande.ce.vue'
import { createVuetify } from 'vuetify'

//import 'vuetify/styles'
//import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/iconsets/mdi";


const vuetify = createVuetify(
    {  
        theme: {
            defaultTheme: "light",
          },
          icons: {
            defaultSet: "mdi",
            aliases,
            sets: {
              mdi,
              fa,
            },
          },
        //components,
        directives 
    })

    
const MonRang = defineCustomElement(MonRangWebComponent)
const RechercheImmobilier = defineCustomElement(RechercheImmobilierWebComponent, {configureApp: app => {
    app.use(vuetify)
}})
const FormulaireDemande = defineCustomElement(FormulaireDemandeComponent)

customElements.define('mon-rang', MonRang)
customElements.define('recherche-immobilier', RechercheImmobilier)
customElements.define('formulaire-demande',FormulaireDemande)