<template>
    <div :class="cssClass" ref="template">
        <div class="grid">
            <cogi-personne
                v-for="(personne, index) in personnes"
                cssClass="grid-full"
                v-model="personnes[index]"
                :key="personne.id!"
                @retirerPersonne="removePersonne(personne.id!)"
                :sourceItemsSexe="sourceItemsSexe"
                :sourceItemsLienParente="sourceItemsLienParente"
                :sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible="sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible"
                :sourceItemsPaysNaissance="sourceItemsPaysNaissance"
                :sourceItemsStatutResidence="sourceItemsStatutResidence"
                :lienParentePourGardePartagee="lienParentePourGardePartagee"
                :utiliseGardePartagee="utiliseGardePartagee"
                :urlPiece="urlPiece"
                :pourcentageGardePartage="pourcentageGardePartage"
                @validated="personneValidated"
                :valideNow="valideNow"
                :currentIndex="Number(index)"
                :culture="culture">
            </cogi-personne>
            <cogi-button cssClass="grid-full right" cssClassButton="suivant" text="AjouterUnePersonne" @click="addPersonne"></cogi-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import { ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import CogiPersonne from './CogiPersonne.vue'
    import CogiButton from './CogiButton.vue'
    import { del } from './../clients/delclient'
    import guid from './helpers/guidgen'

    interface IPropsPersonnes {
        sourceItemsSexe: del.ListItem[]
        sourceItemsLienParente: del.ListItem[]
        sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible: Array<string>
        sourceItemsPaysNaissance: del.ListItem[]
        sourceItemsStatutResidence: del.ListItem[]
        id?: string
        modelValue: Array<del.Person>
        valideNow: boolean
        cssClass: string
        lienParentePourGardePartagee: string
        utiliseGardePartagee: boolean
        urlPiece: string
        pourcentageGardePartage: unknown
        culture: string
    }

    const props = withDefaults(defineProps<IPropsPersonnes>(), {})

    const personnes = ref(new Array<del.Person>())
    const validator = ref(new ValidatorAccumulator())

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [Array<del.Person>]
    }>()

    personnes.value = props.modelValue

    function addPersonne() {
        const personne = new del.Person()
        personne.id = guid.GenererGuid()
        personne.prenom = ''
        personne.nom = ''
        personne.sexe = ''
        personne.nas = ''
        personne.lien = ''
        personne.statutDeResidence = ''
        personne.paysDeNaissance = ''
        personne.revenuTotal = new del.Money()
        personne.etudiantTempsPlein = false
        personne.etudiantTempsPartiel = false
        personne.handicapMoteur = false
        personne.pensionAlimentaire = false
        personne.pensionVeteran = false
        personne.assuranceInvalidite = false
        personne.possedePiecePreuveAge = false
        personne.possedePieceRevenus = false

        if (personnes.value == null) personnes.value = []

        personnes.value.push(personne)
    }

    function removePersonne(id: string) {
        personnes.value = personnes.value.filter(function (personne: del.Person) {
            return personne.id != id
        })
    }

    function personneValidated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    watch(
        () => personnes.value,
        () => {
            emit('update:modelValue', personnes.value), { deep: true }
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let startValidation: boolean = validator.value.startValidation(personnes.value.length)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
