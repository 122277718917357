<template>
    <div v-if="isActive" class="tab"><slot></slot></div>
</template>
<script setup lang="ts">
    import { getCurrentInstance, ref, onMounted, watch } from 'vue'
    import type { Ref } from 'vue'
    import type CogiTabsVue from './CogiTabs.vue'

    interface IPropsTab {
        title: string
        subtitle?: string
        selected?: boolean
    }

    const props = withDefaults(defineProps<IPropsTab>(), {
        subtitle: '',
        selected: false,
    })

    const isActive = ref(false)
    //const isModifiable = ref(false)

    const instance = getCurrentInstance()!
    const parent = instance.parent as unknown as typeof CogiTabsVue
    const tabs = (parent.exposed.tabs as Ref<unknown[]>).value
    tabs.push(instance)

    onMounted(() => {
        isActive.value = props.selected
    })

    watch(
        () => props.selected,
        (newValue: boolean) => {
            isActive.value = newValue
        },
    )

    defineExpose({ props, isActive })
</script>
