<template>
    <div>
        <div v-if="loadingSpinnerData.state.active" id="overlay">
            <div class="centered"></div>
            <div class="loading-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import loadingSpinner from './loading-spinner'

    const loadingSpinnerData = ref(loadingSpinner)
</script>

<style scoped>
    .loading-roller {
        position: relative;
        width: 64px;
        height: 64px;
        z-index: 1000;
        left: calc(100% * 0.5 - 25px);
        top: calc(100% * 0.5 - 25px);
    }

    .loading-roller div {
        animation: loading-roller-in 2s ease-in, loading-roller 1.2s cubic-bezier(0.7, 0, 0.3, 1) infinite;
        transform-origin: 32px 32px;
    }

    .loading-roller div:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        margin: -3px 0 0 -3px;
    }

    .loading-roller div:nth-child(1) {
        animation-delay: -0.036s;
    }

    .loading-roller div:nth-child(1):after {
        top: 50px;
        left: 50px;
    }

    .loading-roller div:nth-child(2) {
        animation-delay: -0.072s;
    }

    .loading-roller div:nth-child(2):after {
        top: 54px;
        left: 45px;
    }

    .loading-roller div:nth-child(3) {
        animation-delay: -0.108s;
    }

    .loading-roller div:nth-child(3):after {
        top: 57px;
        left: 39px;
    }

    .loading-roller div:nth-child(4) {
        animation-delay: -0.144s;
    }

    .loading-roller div:nth-child(4):after {
        top: 58px;
        left: 32px;
    }

    .loading-roller div:nth-child(5) {
        animation-delay: -0.18s;
    }

    .loading-roller div:nth-child(5):after {
        top: 57px;
        left: 25px;
    }

    .loading-roller div:nth-child(6) {
        animation-delay: -0.216s;
    }

    .loading-roller div:nth-child(6):after {
        top: 54px;
        left: 19px;
    }

    @keyframes loading-roller {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loading-roller-in {
        0% {
            opacity: 0;
        }

        95% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes loading-roller {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loading-roller-overlay {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    #overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        z-index: 999;
        animation: loading-roller-overlay 2s ease-in;
    }
</style>
