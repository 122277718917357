<template>
    <div :class="cssClass" v-show="visible">
        <div>
            <span class="checkbox" :class="{ 'cogi-error': hasErrorInterne }">
                <label class="checkbox_inner" :class="{ checked: model, unchecked: !model }">
                    <input type="checkbox" v-model="model" />
                </label>
            </span>
            <cogi-label :label="label" :dontTranslate="dontTranslate" :onClick="changeInput" :avecCurseur="true"></cogi-label>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch } from 'vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsCheckBox {
        id?: string
        label?: string
        dontTranslate?: boolean
        disabled?: boolean
        description?: string
        hasError?: boolean
        cssClass?: string
        required?: boolean
        valideNow?: boolean
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsCheckBox>(), {
        id: undefined,
        label: '',
        dontTranslate: false,
        hasError: false,
        cssClass: '',
        required: false,
        valideNow: false,
        visible: true,
    })

    const model = defineModel<boolean>()
    const hasErrorInterne = ref(false)

    const emit = defineEmits<{
        validated: [boolean]
        change: []
    }>()

    function changeInput() {
        if (model.value) model.value = false
        else model.value = true
    }

    function validate() {
        hasErrorInterne.value = false

        if (!props.visible) {
            emit('validated', false)
            return
        }

        if (props.required && !model.value) hasErrorInterne.value = true

        emit('validated', hasErrorInterne.value)
    }

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )

    watch(
        () => props.hasError,
        (newValue: boolean) => {
            hasErrorInterne.value = newValue
        },
    )

    watch(
        () => model,
        () => {
            hasErrorInterne.value = false
            emit('change')
        },
    )
</script>
