<template>
    <div v-show="visible">
        <span :class="cssClass">
            <cogi-checkbox cssClass="grid-full-with-end" :label="label" :required="required" v-model="obnl.estSelectionne" :hasError="hasError"></cogi-checkbox>
            <cogi-info cssClass="grid-end" tooltip="ToolTipModeleObnl"></cogi-info>
        </span>
        <div v-show="isPanelShown">
            <cogi-yesno
                cssClass="grid-full"
                label="RecherchezVousUneResidencePourPersonnesAgeesAvecServices"
                :required="false"
                v-model="obnl.rechercheResidencePersonnesAgeesAvecServices"></cogi-yesno>
            <cogi-label cssClass="grid-full" label="SiUnProjetEnParticulierVousInteresseSvpIndiquerLeNomOuLadresseFacultatif"></cogi-label>
            <cogi-regex cssClass="grid-full" v-model="obnl.projetParticulier"></cogi-regex>

            <div v-show="isPanelServicesShown">
                <cogi-checkboxgroup
                    :culture="culture"
                    cssClass="grid-full"
                    :required="false"
                    v-model="obnl.serviceDesires"
                    label="ServiceDesires"
                    :sourceItems="sourceItemsServiceDesires"></cogi-checkboxgroup>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'

    import CogiCheckboxgroup from './CogiCheckboxgroup.vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiYesno from './CogiYesno.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiInfo from './CogiInfo.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import type { del } from './../clients/delclient'

    interface IPropsModeleObnl {
        required?: boolean
        label: string
        cssClass: string
        hasError: boolean
        modelValue: del.Obnl
        sourceItemsServiceDesires: del.ListItem[]
        visible: boolean
        culture: string
    }

    const props = withDefaults(defineProps<IPropsModeleObnl>(), {
        required: false,
        label: '',
        cssClass: '',
        hasError: false,
        visible: true,
        culture: 'fr',
    })

    const obnl = ref(props.modelValue)

    const emit = defineEmits<{
        input: [del.Obnl]
    }>()

    const isPanelShown = computed(() => {
        return obnl.value.estSelectionne
    })

    const isPanelServicesShown = computed(() => {
        return obnl.value.rechercheResidencePersonnesAgeesAvecServices
    })

    watch(
        () => obnl.value,
        newValue => {
            emit('input', newValue), { deep: true }
        },
    )
</script>
