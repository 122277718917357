<template>
    <span :class="cssClass" v-show="visible">
        <cogi-checkbox cssClass="grid-full-with-end" :label="label" :required="required" v-model="estSelectionne" :hasError="hasError"></cogi-checkbox>
        <cogi-info cssClass="grid-end" tooltip="ToolTipModeleHlm"></cogi-info>
    </span>
</template>
<script setup lang="ts">
    import { watch } from 'vue'

    import CogiInfo from './CogiInfo.vue'
    import CogiCheckbox from './CogiCheckbox.vue'

    interface IPropsModeleHlm {
        required?: boolean
        label: string
        cssClass: string
        tooltip?: string
        value?: boolean
        hasError: boolean
        visible: boolean
    }

    const props = withDefaults(defineProps<IPropsModeleHlm>(), {
        required: false,
        label: '',
        cssClass: '',
        tooltip: '',
        hasError: false,
        visible: true,
    })

    const estSelectionne = defineModel<boolean>()

    const emit = defineEmits<{
        input: [boolean]
    }>()

    watch(
        () => estSelectionne.value!,
        newValue => {
            emit('input', newValue)
        },
    )
</script>
