<template>
    <div :class="cssClass" v-show="visible">
        <cogi-label :required="required" :label="label"></cogi-label>
        <select v-model="selectedValue" :class="{ cogirequired: required, 'cogi-error': hasError }" v-on:change="change">
            <option v-for="item in items" :key="item.listItemId" :value="item.value">{{ getDescription(item.value as string) }}</option>
        </select>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, onMounted } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import { del } from './../clients/delclient'

    interface IPropsDropDownList {
        sourceItems: del.ListItem[]
        required?: boolean
        showEmptyItem?: boolean
        valideNow?: boolean
        modelValue?: string
        cssClass?: string
        label?: string
        visible?: boolean
        culture: string
    }

    const props = withDefaults(defineProps<IPropsDropDownList>(), {
        required: false,
        showEmptyItem: false,
        valideNow: false,
        modelValue: '',
        cssClass: '',
        label: '',
        visible: true,
        culture: 'fr',
    })

    const items = ref(<del.ListItem[]>[])
    const hasError = ref(false)
    const selectedValue = ref(props.modelValue)

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [string]
    }>()

    function change() {
        hasError.value = false
    }

    function validate() {
        hasError.value = false

        if (!props.visible) {
            emitValidated(false)
            return
        }
        if (props.required && !selectedValue.value) hasError.value = true
        emitValidated(hasError.value)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function getDescription(value: string): string {
        for (var i = 0; i < items.value.length; i++) {
            if (items.value[i].value == value) {
                return (props.culture == 'fr' ? items.value[i].description : items.value[i].descriptionAnglais) as string
            }
        }

        return ''
    }

    watch(
        () => selectedValue.value,
        newValue => {
            emit('update:modelValue', newValue as string)
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (newValue) validate()
        },
    )

    onMounted(() => {
        if (props.showEmptyItem) items.value.push(new del.ListItem({ value: '', description: '', descriptionAnglais: '', listItemId: -1 }))

        for (var i = 0; i < props.sourceItems.length; i++) {
            items.value.push(props.sourceItems[i])
        }
    })
</script>
