import type { IValidatorAccumulator, IValidatorAccumulatorVueComponent } from './IValidatorAccumulator'
import { ValidationStatutPoco } from './IValidatorAccumulator'
export { ValidationStatutPoco }

export class ValidatorAccumulator implements IValidatorAccumulator {
    private validationStatut: ValidationStatutPoco = new ValidationStatutPoco()

    public startValidation(nbElementsToValidate: number): boolean {
        this.validationStatut = new ValidationStatutPoco()        
        this.validationStatut.nbElementsToValidate = nbElementsToValidate
        this.validationStatut.currentElementValidated = 0
        return !this.validationIsComplete()
    }

    public validated(hasError: boolean): ValidationStatutPoco {
        this.validationStatut.currentElementValidated++
        this.validationStatut.hasError = this.validationStatut.hasError || hasError
        this.validationStatut.validationCompleted = this.validationIsComplete()

        return this.validationStatut
    }

    private validationIsComplete(): boolean {              
        return this.validationStatut.currentElementValidated >= this.validationStatut.nbElementsToValidate
    }
}

export class ValidatorAccumulatorVueComponent implements IValidatorAccumulatorVueComponent {
    constructor(private validatorAccumulator: ValidatorAccumulator) {}

    public startValidation(component: any): boolean {        
        const nbElementsToValidate = this.getNbElementsToValidate(component)
        return this.validatorAccumulator.startValidation(nbElementsToValidate)
    }

    public startValidationWithAdditionalElements(component: any, nbAdditionalElementsToValidate: number): boolean {
        const nbElementsToValidate = this.getNbElementsToValidate(component)
        return this.validatorAccumulator.startValidation(nbElementsToValidate + nbAdditionalElementsToValidate)
    }

    public validated(hasError: boolean): ValidationStatutPoco {
        return this.validatorAccumulator.validated(hasError)
    }

    private getNbElementsToValidate(component: any): number { 
        if(!component)  
            return 0
        
        const children = (component.vnode.component!.subTree as any).dynamicChildren as []                
        const validatedChildren = children.filter((c: any) => (c.component?.vnode?.props?.onValidated)?? false)

        const nbElementsToValidate = validatedChildren.length         
        return nbElementsToValidate        
    }
}
