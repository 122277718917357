<template>
    <div :class="cssClass" v-show="visible">
        <cogi-label :required="required" :label="label"></cogi-label>
        <div class="grid">
            <select class="gridspan5" v-model="year" :class="{ cogirequired: required, 'cogi-error': hasError }" @blur="blur" @change="resetDayValue">
                <option v-for="y in years" :value="y" :key="y">{{ y }}</option>
            </select>
            <select class="gridspan6" v-model="month" :class="{ cogirequired: required, 'cogi-error': hasError }" @blur="blur" @change="resetDayValue">
                <option v-for="m in months" :value="m.id" :key="m.id">{{ m.text }}</option>
            </select>
            <select class="gridspan5" v-model="day" :class="{ cogirequired: required, 'cogi-error': hasError }" @blur="blur">
                <option v-for="d in days" :value="d" :key="d">{{ d }}</option>
            </select>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { onMounted, ref, watch, computed } from 'vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsDate {
        dateReassignee?: Date
        label?: string
        required?: boolean
        valideNow?: boolean
        cssClass?: string
        visible?: boolean
        culture?: string // 'fr' | 'en'
    }

    const props = withDefaults(defineProps<IPropsDate>(), {
        label: '',
        required: false,
        valideNow: false,
        cssClass: '',
        visible: true,
        culture: 'fr',
    })

    const model = defineModel<Date>()
    const year = ref(<number | string>(props.dateReassignee?.getFullYear() ?? ''))
    const month = ref(<number | string>(props.dateReassignee?.getMonth() ?? ''))
    const day = ref(<number | string>(props.dateReassignee?.getDate() ?? ''))
    const hasError = ref(false)

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [Date]
    }>()

    function validate(): void {
        hasError.value = false

        if (!props.visible) {
            emit('validated', false)
            return
        }

        if (props.required && year.value === '') hasError.value = true

        if (props.required && month.value === '') hasError.value = true

        if (props.required && day.value === '') hasError.value = true

        emit('validated', hasError.value)
    }

    function blur() {
        hasError.value = false
    }
    function resetDayValue() {
        day.value = ''
    }

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )

    watch(year, updateModel)
    watch(month, updateModel)
    watch(day, updateModel)

    onMounted(() => {
        if (new Date(model.value!).getFullYear() > 1900) {
            const nouvelleDate = new Date(model.value!)
            year.value = nouvelleDate?.getFullYear() ?? ''
            month.value = nouvelleDate?.getMonth() ?? ''
            day.value = nouvelleDate?.getDate() ?? ''
        }
    })

    const years = computed(() => {
        const yearArray = []
        yearArray.push('')
        const currentYear = new Date().getFullYear()
        for (let i = currentYear + 1; i > 1900; i--) {
            yearArray.push(i.toString())
        }

        return yearArray
    })

    const months = computed(() => {
        const monthArray = []
        monthArray.push({ id: '', text: '' })

        let locale = 'fr-ca'

        if (props.culture == 'fr') locale = 'fr-ca'
        else if (props.culture == 'en') locale = 'en'

        for (let i = 0; i < 12; i++) {
            const obj = { id: '', text: '' }
            const mois = new Date(2000, i, 2).toLocaleString(locale, { month: 'long' })
            obj.id = i.toString()
            obj.text = mois
            monthArray.push(obj)
        }

        return monthArray
    })

    const days = computed(() => {
        const textYear = year.value as string
        const textMonth = month.value as string
        const numberOfDays = new Date(parseInt(textYear), parseInt(textMonth) + 1, parseInt('0')).getDate()

        const daysArray = []
        daysArray.push('')
        let i = 1
        for (i = 1; i <= numberOfDays; i++) {
            daysArray.push(i.toString())
        }

        return daysArray
    })

    function updateModel() {
        if ((year.value ?? '') === '' || (month.value ?? '') === '' || (day.value ?? '') === '') {
            model.value = undefined
        } else {
            model.value = new Date(year.value as number, month.value as number, day.value as number)
        }
    }
</script>
