<template>
    <div :class="cssClass" v-show="visible">
        <cogi-label :required="required" :label="label" :tooltip="tooltip" :dontTranslateToolTip="dontTranslateToolTip"></cogi-label>
        <input
            ref="maskedinput"
            type="text"
            :placeholder="placeholderToShow"
            v-model="model"
            :class="{ cogirequired: required, 'cogi-error': inputHasError }"
            v-on:blur="blur" />
    </div>
</template>
<script setup lang="ts">
    import { ref, reactive, computed, watch } from 'vue'
    import CogiLabel from './CogiLabel.vue'

    interface IPropsInuputMask {
        disabled?: boolean
        pattern?: string
        placeholder?: string
        charset?: string
        required?: boolean
        additionnalValidation?: boolean
        valideNow: boolean
        hasExternalError?: boolean
        cssClass?: string
        label: string
        tooltip?: string
        visible?: boolean
        placeholderIsVisible?: boolean
        dontTranslateToolTip?: boolean
    }

    const model = defineModel<string>()

    const props = withDefaults(defineProps<IPropsInuputMask>(), {
        valideNow: false,
        visible: true,
        placeholderIsVisible: true,
        dontTranslateToolTip: false,
    })
    const hasError = ref(false)
    const options = reactive({
        masked: '.masked',
        mNum: 'XdDmMyY9',
        mChar: '_',
    })

    const emit = defineEmits<{
        validated: [boolean]
    }>()

    const inputHasError = computed(() => {
        return hasError.value || props.additionnalValidation || props.hasExternalError
    })

    const placeholderToShow = computed(() => {
        return props.placeholderIsVisible ? props.placeholder : undefined
    })

    function blur() {
        hasError.value = false
    }
    function validate() {
        hasError.value = false

        if (!props.visible) {
            emitValidated(false)
            return
        }

        if (props.required && model.value && model.value!.length == 0) {
            hasError.value = true
            emitValidated(true)
            return
        }

        if (!props.required && !model.value) {
            hasError.value = false
            emitValidated(false)
            return
        }

        var regExPattern = new RegExp(props.pattern!)
        if (!regExPattern.test(model.value!)) {
            hasError.value = true
        }

        emitValidated(hasError.value)
    }
    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }
    function tryChangeValue(newValue: string) {
        var isCharsetPresent = props.charset

        var placeholder = isCharsetPresent || props.placeholder
        if (!placeholder) return newValue
        var l = placeholder.length
        var futureValue = '',
            i,
            j,
            isInt,
            isLetter,
            strippedValue

        if (newValue.length === 0) {
            if (props.required) hasError.value = true
            return ''
        } else hasError.value = false

        // strip special characters
        strippedValue = isCharsetPresent ? newValue.replace(/\W/g, '') : newValue.replace(/\D/g, '')
        var matchesNumber
        var matchesLetter
        for (i = 0, j = 0; i < l; i++) {
            isInt = !isNaN(parseInt(strippedValue[j]))
            isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false
            matchesNumber = options.mNum.indexOf(placeholder[i]) >= 0
            matchesLetter = options.mChar.indexOf(placeholder[i]) >= 0
            if ((matchesNumber && isInt) || (isCharsetPresent && matchesLetter && isLetter)) {
                futureValue += strippedValue[j++]
            } else if ((!isCharsetPresent && !isInt && matchesNumber) || (isCharsetPresent && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)))) {
                return futureValue
            } else {
                futureValue += placeholder[i]
            }
            // break if no characters left and the pattern is non-special character
            if (strippedValue[j] == undefined) {
                break
            }
        }

        return futureValue
    }

    watch(
        () => model.value,
        newValue => {
            model.value = tryChangeValue(newValue!)
            //emit('update:modelValue', value.value)
        },
    )

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (newValue) validate()
        },
    )
</script>
