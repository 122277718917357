<template>
    <div ref="template">
        <div class="cogi-subtitle">
            <cogi-label-only label="AntecedantDuMenage" />
        </div>
        <div class="grid">
            <cogi-yesno
                cssClass="grid-full"
                :label="labelExpulsion"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.expulsion"></cogi-yesno>
            <cogi-yesno
                cssClass="grid-full"
                :label="labelDeguerpi"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.deguerpi"></cogi-yesno>
            <cogi-yesno
                cssClass="grid-full"
                :label="labelDetteHLM"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.detteHLM"></cogi-yesno>
            <cogi-yesno
                cssClass="grid-full"
                label="AvezVousFaitObjetDuneDeclarationDeFaillite"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.faillite"></cogi-yesno>
            <cogi-yesno
                cssClass="grid-full"
                label="EstCeQueVotreLogementFaitObjetTraitementAntiParasite"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.punaise"></cogi-yesno>
            <cogi-yesno
                cssClass="grid-full"
                :visible="modelData.utiliseValeurBiens"
                :label="ObtenirLibelleMontantLiquiditeExcedant()"
                :dontTranslate="true"
                :required="true"
                @validated="validated"
                :valideNow="valideNow"
                v-model="modelData.valeurtotal"
                :possedeTooltip="true"
                tooltip="IlSAgitDeLaValeurTotaleDesAvoirsLiquides"></cogi-yesno>
        </div>
        <div class="cogi-subtitle">
            <cogi-label-only label="Vehicules" />
        </div>
        <div class="grid">
            <div class="gridspan12">
                <cogi-label :required="true" label="EstCeQueVousPossederUnOuPlusieursVehicules"></cogi-label>
            </div>
            <cogi-yesno
                cssClass="gridspan4radiobtn"
                :required="true"
                v-model="modelData.possedeVoiture"
                @validated="validated"
                :valideNow="valideNow"></cogi-yesno>
        </div>
        <cogi-vehicules
            v-model="modelData"
            :sourceItems="sourceItemsMarquesVehicule"
            @validated="validated"
            :valideNow="valideNow"
            :culture="modelData.culture!">
        </cogi-vehicules>

        <div class="cogi-subtitle">
            <cogi-label-only label="Autonomie" />
            <cogi-info cssClass="grid-end" tooltip="EtreAutonomeSignifieEtreCapableDassurerVosBesoinsEssentiels"></cogi-info>
        </div>

        <cogi-autonomie
            :visibleSurValeur="false"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData.autonomieTachesMenages"
            label="EtesVousAutonomeDansLexecutionDesTacheMenageres"></cogi-autonomie>
        <cogi-autonomie
            :visibleSurValeur="false"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData.autonomieHygiene"
            label="EtesVousAutonomeParRapportAVotreHygienePersonnelle"></cogi-autonomie>
        <cogi-autonomie
            :visibleSurValeur="true"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData.autonomieSante"
            label="AvezVousBesoinDeSoinsDeSante"></cogi-autonomie>
        <cogi-autonomie
            :visibleSurValeur="true"
            @validated="validated"
            :valideNow="valideNow"
            v-model="modelData.autonomieRepas"
            label="AvezVousBesoinDassistanceDansLaPreparationDesRepas"></cogi-autonomie>

        <div class="grid">
            <cogi-button cssClass="gridspan15 right" cssClassButton="precedent" text="EtapePrecedente" @click="precedent"></cogi-button>
            <cogi-button cssClass="gridspan1 right" cssClassButton="suivant" text="EtapeSuivante" @click="suivant"></cogi-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, getCurrentInstance } from 'vue'
    import { i18n } from '../../../locales/i18n-wc'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'

    import CogiButton from './CogiButton.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import CogiAutonomie from './CogiAutonomie.vue'
    import CogiInfo from './CogiInfo.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiVehicules from './CogiVehicules.vue'
    import CogiYesno from './CogiYesno.vue'
    import type { del } from '../clients/delclient'
    import type DemandeEnLigneVM from '../DemandeEnLigneVM'

    interface IPropsCogiEtape3 {
        sourceItemsMarquesVehicule: del.ListItem[]
        modelValue: DemandeEnLigneVM
    }

    const props = withDefaults(defineProps<IPropsCogiEtape3>(), {})

    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const modelData = props.modelValue
    const hasError = ref(false)
    const valideNow = ref(false)
    const isInsuivantMode = ref(false)
    let validateCallback = ref(defaultValidateCallback)

    const emit = defineEmits<{
        avancerUnEtape: [number]
        reculerUnEtape: [number]
    }>()

    const labelExpulsion = computed(() => {
        if (utiliseAuMoinsUnModeleHabitation.value) return 'AvezVousDejaEteExpulseLogementSubventionne'

        return 'AvezVousDejaEteExpulseLogement'
    })

    const labelDeguerpi = computed(() => {
        if (utiliseAuMoinsUnModeleHabitation.value) return 'AvezVousDejaDeguerpiLogementSubventionne'

        return 'AvezVousDejaDeguerpiLogement'
    })

    const labelDetteHLM = computed(() => {
        if (utiliseAuMoinsUnModeleHabitation.value) return 'AvezVousUneDetteEnversUnLocateurSubventionne'

        return 'AvezVousUneDetteEnversUnLocateur'
    })

    const utiliseAuMoinsUnModeleHabitation = computed(() => {
        return modelData.utiliseProgrammeHlm || modelData.utiliseProgrammeCoop || modelData.utiliseProgrammeObnl
    })

    function defaultValidateCallback(value: boolean) {}

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        hasError.value = validationStatut.hasError
        valideNow.value = false
        if (hasError.value) return

        doSuivant()
        validateCallback.value(hasError.value)
        validateCallback.value = defaultValidateCallback
    }

    function suivant() {
        isInsuivantMode.value = true
        validate()
    }

    function doSuivant() {
        if (!isInsuivantMode.value) return

        isInsuivantMode.value = false
        if (hasError.value) return
        else {
            emit('avancerUnEtape', 2)
        }
    }

    function precedent() {
        emit('reculerUnEtape', 2)
    }

    const instance = getCurrentInstance()

    function validate() {
        hasError.value = false
        valideNow.value = validatorAccumulator.value.startValidation(instance)
    }

    function ObtenirLibelleMontantLiquiditeExcedant() {
        return i18n.t('EstCeQueLaValeurTotalDesBiensExcede') + ' ' + modelData.montantLiquiditeExcedant + ' ?'
    }
</script>
