<template>
    <div v-show="visible">
        <span :class="cssClass">
            <cogi-checkbox cssClass="grid-full-with-end" :label="label" :required="required" v-model="coop.estSelectionne" :hasError="hasError"></cogi-checkbox>
            <cogi-info cssClass="grid-end" tooltip="ToolTipModeleCoop"></cogi-info>
        </span>

        <div v-show="isPanelShown">
            <cogi-label cssClass="grid-full" label="AfinQueVotreDemandeDeLogementSoitConsideree"> </cogi-label>
            <br /><br />
            <cogi-label cssClass="grid-full" label="EtesVousImpliqueOrganismeCommunautaire"> </cogi-label>
            <cogi-dropdownlist
                cssClass="grid-full"
                :sourceItems="sourceItemsImplicationOrganisme"
                :culture="culture"
                v-model="coop.impliqueDansOrganismeCommu"></cogi-dropdownlist>
            <cogi-label cssClass="grid-full" label="ParmiLesResponsabilitésSuivantesLesquelles"> </cogi-label>

            <cogi-checkboxgroup
                :culture="culture"
                cssClass="grid-full"
                :required="false"
                v-model="coop.responsabiliteCoop"
                :sourceItems="sourceItemsResponsabiliteCoop"></cogi-checkboxgroup>

            <br />
            <cogi-yesno
                cssClass="grid-full"
                label="RecherchezVousUneResidencePourPersonnesAgees"
                v-model="coop.rechercheResidencePourPersonneAgees"></cogi-yesno>
            <div v-show="isPanelServicesShown">
                <cogi-checkboxgroup
                    :culture="culture"
                    cssClass="grid-full"
                    :required="false"
                    v-model="coop.serviceDesires"
                    label="ServiceDesires"
                    :sourceItems="sourceItemsServiceDesires"></cogi-checkboxgroup>
            </div>
            <br />
            <cogi-yesno cssClass="grid-full" label="SeriezVousDisponibleConsacrerHeures" :required="false" v-model="coop.disponibleCoopHabitation"></cogi-yesno>

            <span v-show="utiliseAtelierFederationCoop">
                <cogi-yesno cssClass="grid-full" label="AvezVousParticipeAteliersDinformation" v-model="coop.participationAtelierInformation"></cogi-yesno>

                <br />
                <span class="grid">
                    <cogi-checkbox cssClass="grid-half" label="NonMaisJeSuisInscritAtelierLieuLeDeuxPoint" v-model="coop.inscritAtelier"> </cogi-checkbox>
                    <cogi-date cssClass="grid-half" :culture="culture" v-model="coop.dateAtelier"></cogi-date>
                </span>
                <cogi-checkbox cssClass="grid-half" label="NonMaisJaimeraisObtenirLinformationAteliersAvenir" v-model="coop.informationAtelier">
                </cogi-checkbox>
            </span>

            <br />
            <cogi-label cssClass="grid-full" label="ObtenirUnLogementExigeEngageIimpliquerContribuerIndiquerRaisons"> </cogi-label>
            <textarea class="grid-full" cols="50" rows="10" v-model="coop.explicationCoop"></textarea>

            <cogi-label cssClass="grid-full" label="SiUnProjetParticulierVousIntéresseIndiquerNomOuAdresse"></cogi-label>
            <cogi-regex cssClass="grid-full" v-model="coop.projetParticulier"></cogi-regex>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'

    import CogiRegex from './CogiRegex.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import CogiDate from './CogiDate.vue'
    import CogiYesno from './CogiYesno.vue'
    import CogiDropdownlist from './CogiDropdownlist.vue'
    import CogiCheckboxgroup from './CogiCheckboxgroup.vue'
    import CogiInfo from './CogiInfo.vue'
    import type { del } from './../clients/delclient'

    interface IPropsModeleCoop {
        required?: boolean
        label: string
        cssClass: string
        modelValue: del.Coop
        hasError: boolean
        sourceItemsImplicationOrganisme: del.ListItem[]
        sourceItemsServiceDesires: del.ListItem[]
        sourceItemsResponsabiliteCoop: del.ListItem[]
        visible: boolean
        culture: string
        utiliseAtelierFederationCoop: boolean
    }

    const props = withDefaults(defineProps<IPropsModeleCoop>(), {
        required: false,
        label: '',
        cssClass: '',
        hasError: false,
        visible: true,
        culture: 'fr',
        utiliseAtelierFederationCoop: false,
    })

    const coop = ref(props.modelValue)

    const emit = defineEmits<{
        input: [del.Coop]
    }>()

    const isPanelShown = computed(() => {
        return coop.value.estSelectionne
    })

    const isPanelServicesShown = computed(() => {
        return coop.value.rechercheResidencePourPersonneAgees
    })

    watch(
        () => coop.value,
        newValue => {
            emit('input', newValue), { deep: true }
        },
    )
</script>
