<template>
    <div :class="cssClass" v-show="visible" ref="template">
        <div class="grid">
            <div class="gridspan12">
                <cogi-inputmask
                    :visible="visible"
                    @validated="validated"
                    :label="label"
                    :valideNow="valideNow"
                    pattern="\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4}"
                    placeholder="(XXX) XXX-XXXX"
                    :required="required"
                    v-model="no_telephone"></cogi-inputmask>
            </div>
            <div class="gridspan4">
                <cogi-inputmask
                    :visible="visible"
                    @validated="validated"
                    :valideNow="valideNow"
                    :required="false"
                    placeholder="XXXX"
                    v-model="ext"
                    label="Ext"></cogi-inputmask>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, getCurrentInstance, watch } from 'vue'
    import CogiInputmask from './CogiInputmask.vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { TelephonePoco } from './pocos/TelephonePoco'
    import type { del } from './../clients/delclient'

    interface IPropsTelephone {
        required?: boolean
        modelValue?: del.Telephone
        valideNow?: boolean
        label: string
        cssClass: string
        visible?: boolean
    }

    const props = withDefaults(defineProps<IPropsTelephone>(), {
        required: false,
        valideNow: false,
        label: '',
        cssClass: '',
        visible: true,
    })

    const template = ref(null)

    const no_telephone = ref(props.modelValue?.telephone ?? '')
    const ext = ref(props.modelValue?.ext ?? '')
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [object]
    }>()

    const telephone = computed(() => {
        const poco = new TelephonePoco()
        poco.telephone = no_telephone.value
        poco.ext = ext.value
        return poco
    })

    const instance = getCurrentInstance()

    watch(
        () => no_telephone.value,
        () => {
            emitUpdateModel()
        },
    )

    watch(
        () => ext.value,
        () => {
            emitUpdateModel()
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )

    function validated(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function emitUpdateModel() {
        emit('update:modelValue', telephone.value)
    }
</script>
