<template>
    <div>
        <mon-rang :code-organisme="codeOrganisme" :langue="lang"></mon-rang>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue'
    import MonRang from '@components/rang/MonRang.vue'

    const lang = ref('en')

    interface IPropsRang {
        codeOrganisme: string
        langue: string
    }

    const props = defineProps<IPropsRang>()

    onMounted(() => {
        lang.value = props.langue
    })
    watch(
        () => props.langue,
        function (newValue) {
            lang.value = newValue
        },
    )
</script>
<style>
    @import '../css/rang.less';
</style>
