import { reactive } from 'vue'

const state = reactive({
    active: false,
})

let close: (value: unknown) => void
const loadingPromise = () => new Promise(resolve => (close = resolve))

const start = () => {
    state.active = true
    return loadingPromise()
}
const reset = () => {
    state.active = false
}

const loadingSpinner = {
    get state() {
        return state
    },

    start() {
        return start()
    },

    stop() {
        close(null)
        reset()
    },
}

export default loadingSpinner
