<template>
    <div class="noPaddingnoMarginnoBorder" :class="cssClass" ref="template">
        <div class="grid">
            <cogi-yesno
                cssClass="grid-full"
                :label="label"
                :required="true"
                @validated="validate"
                :valideNow="valideNow"
                v-model="model!.estAutonome"></cogi-yesno>
            <cogi-yesno
                :isLabelOnTop="true"
                class="grid-half"
                :visible="visible"
                label="AideRecue?"
                @validated="validate"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.aideRecue"></cogi-yesno>
            <cogi-regex
                class="grid-half"
                :visible="visibleDetail as boolean"
                label="NbrHeureSemaine"
                @validated="validate"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.nbHeures"></cogi-regex>
            <cogi-regex
                class="grid-half"
                :visible="visibleDetail as boolean"
                label="Organisme"
                @validated="validate"
                :valideNow="valideNow"
                :required="true"
                v-model="model!.organisme"></cogi-regex>
            <cogi-regex
                class="grid-half"
                :visible="visibleDetail as boolean"
                label="NomIntervenant"
                @validated="validate"
                :valideNow="valideNow"
                v-model="model!.nomIntervenant"></cogi-regex>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, getCurrentInstance } from 'vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiYesno from './CogiYesno.vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import type { del } from '../clients/delclient'

    interface IPropsAutonomie {
        valideNow: boolean
        visibleSurValeur: boolean
        label: string
        cssClass?: string
    }

    const props = withDefaults(defineProps<IPropsAutonomie>(), {
        valideNow: false,
        visibleSurValeur: false,
        label: '',
        cssClass: '',
    })

    const model = defineModel<del.Autonomie>()
    const instance = getCurrentInstance()
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))

    const visible = computed(() => {
        return model.value!.estAutonome === (props.visibleSurValeur === true)
    })

    const visibleDetail = computed(() => {
        return visible.value && model.value!.aideRecue != undefined && model.value!.aideRecue
    })

    const emit = defineEmits<{
        input: [del.Autonomie?]
        validated: [boolean]
    }>()

    function validate(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    watch(
        () => model.value,
        newValue => {
            emit('input', newValue)
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
