export class ImageUtil {
    static GetImageBase64Source(image: { extension: string; contenuBase64: string }) {
        return this.GetBase64Source(image.extension, image.contenuBase64)
    }

    static GetBase64Source(extensionImage: string, contenuBase64: string) {        
        return 'data:' + this.GetImageMimeTypeSelonExtension(extensionImage) + ';base64,' + contenuBase64
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
    static GetImageMimeTypeSelonExtension(extensionImage: string) {
        let type
        const extImage = extensionImage === null || extensionImage === void 0 ? void 0 : extensionImage.replace('.', '')
        switch (extImage) {
            case 'ico':
            case 'cur':
                type = 'x-icon'
                break
            case 'jpg':
            case 'jfif':
            case 'pjpeg':
            case 'pjp':
                type = 'jpeg'
                break
            case 'svg':
                type = 'svg+xml'
                break
            case 'tif':
                type = 'tiff'
                break
            default:
                type = extensionImage
                break
        }
        return 'image/' + type
    }

    static EstImageNulle(image: { extension: string; contenuBase64: string }) {
        return image === undefined || image == null || image.extension == null || image.contenuBase64 === undefined || image.contenuBase64 == null
    }
}
