<template>
    <div>
        <div class="grid">
            <cogi-image :image="imageLogo" class="grid-full" imgCssClass="entete" style="justify-self: center" />
            <cogi-titresection
                cssClass="grid-full"
                titre="DemandeCompletee"
                v-model="culture"
                :utiliseMultilangue="utiliseMultilangue"
                :langues="langues"
                :imageIcone="imageIcone" />

            <hr class="grid-full" />

            <h3 class="grid-full formulaireComplete" style="text-align: center; font-size: 26px; line-height: 28px">
                {{ traduire('NousAvonsBienRecuVotreDemandeDeLogementSubventionne') }}
            </h3>
            <p class="grid-full">
                {{
                    traduire(
                        'AfinDassurerUnTraitementAdequatDeVotreDemandeIlEstImportantDeTransmettreLesPiecesJustificativesCorrespondantAvotreSituationDansLesPlusBrefsDelaisParCourrielA',
                    )
                }}
                <a :href="hrefcourrielOrganisme">{{ courrielOrganisme }}</a
                >, {{ traduire('ParCourrierOuEnPersonneAladresseSuivante') + ':' }} <b>{{ adresseOrganisme }}</b>
            </p>
            <p class="grid-full">
                {{ traduire('PourTouteQuestionConcernantVotreDemandeDeLogementSubventionneNhesitezPasAcommuniquerAvecNousParCourrielA') }}
                <a :href="hrefcourrielOrganisme">{{ courrielOrganisme }}</a> {{ traduire('OuParTelephone') }} {{ telephoneOrganisme }}.
            </p>

            <hr class="grid-full" />

            <h3 class="grid-full formulaireComplete" style="text-align: center; font-size: 26px; line-height: 28px">
                {{ traduire('BeforeNo') }}N<sup>O</sup>{{ traduire('AfterNo') }}: {{ noDeLaDemande }}
            </h3>
            <p class="grid-full">{{ traduire('IlEstFortementRecommandeDeConserverCeNumeroPourVosDossiers') }}</p>

            <input class="grid-full center btn suivant" type="button" :value="traduire('RetourAlaccueil')" @click="retour" />
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'
    import { i18n } from '../../locales/i18n-wc'
    import CogiImage from './shared/CogiImage.vue'
    import CogiTitresection from './shared/CogiTitresection.vue'

    interface IPropsFormulaireComplete {
        noDeLaDemande: string
        adresseOrganisme: string
        courrielOrganisme: string
        telephoneOrganisme: string
        utiliseMultilangue?: boolean
        culture?: string
        langues?: Array<string>
        imageIcone?: object
        imageLogo?: object
    }

    const props = withDefaults(defineProps<IPropsFormulaireComplete>(), {
        culture: 'fr',
        noDeLaDemande: '',
        visible: false,
        adresseOrganisme: '',
        courrielOrganisme: '',
        telephoneOrganisme: '',
        utiliseMultilangue: false,
        langues: () => [],
    })

    const culture = ref(props.culture)

    const emit = defineEmits<{
        redirigerVersSiteSource: [boolean]
        input: [string]
    }>()

    const hrefcourrielOrganisme = computed(() => {
        return 'mailto: ' + props.courrielOrganisme + ';'
    })

    function retour() {
        emit('redirigerVersSiteSource', true)
    }

    function traduire(value: string) {
        return i18n.t(value)
    }

    watch(
        () => props.culture,
        newValue => {
            culture.value = newValue
        },
    )

    watch(
        () => culture.value,
        newValue => {
            emit('input', newValue)
        },
    )
</script>
