export class ValidationStatutPoco {
    constructor() {
        this.validationCompleted = this.hasError = false
        this.currentElementValidated = 0
    }

    public nbElementsToValidate!: number
    public currentElementValidated!: number
    public validationCompleted: boolean
    public hasError: boolean
}
