<template>
    <span :class="props.cssClass" v-show="visible">
        <label :class="[{ sansCurseur: !avecCurseur }, cssClassLabel]">
            {{ libelleTraduit }}
            <span class="redText" v-if="required">*</span>
            <cogi-info :tooltip="tooltip" :dontTranslate="props.dontTranslateToolTip" v-if="possedeTooltip"></cogi-info>
        </label>
    </span>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue'
    import { i18n } from '@/locales/i18n-wc'
    import CogiInfo from './CogiInfo.vue'

    interface IPropsLabel {
        required?: boolean
        label?: string
        tooltip?: string
        cssClass?: string
        cssClassLabel?: string
        avecCurseur?: boolean
        dontTranslate?: boolean
        dontTranslateToolTip?: boolean
        estToujoursVisible?: boolean
    }

    const props = withDefaults(defineProps<IPropsLabel>(), {
        required: false,
        label: '',
        tooltip: '',
        cssClass: '',
        cssClassLabel: '',
        avecCurseur: false,
        dontTranslate: false,
        dontTranslateToolTip: false,
        estToujoursVisible: false,
    })

    const visible = ref(<string | true>props.estToujoursVisible || props.label)
    const possedeTooltip = ref(<string>props.tooltip)

    const libelleTraduit = computed(() => {
        return props.dontTranslate ? props.label : i18n.t(props.label)
    })
</script>
