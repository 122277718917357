<template>
    <div>
        <recherche-immobilier :code-organisme="codeOrganisme" :no-succursale="noSuccursale" :langue="lang"></recherche-immobilier>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from 'vue'
    import RechercheImmobilier from '@components/immobilier/RechercheImmobilier.vue'

    const lang = ref('en')

    interface IPropsRechercheImmobilier {
        codeOrganisme: string
        noSuccursale: number
        langue: string
    }

    const props = defineProps<IPropsRechercheImmobilier>()

    onMounted(() => {
        lang.value = props.langue
    })

    watch(
        () => props.langue,
        function (newValue) {
            lang.value = newValue
        },
    )
</script>
<style>
    @import '../css/immobilier.less';
    @import 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
    @import 'https://cdn.jsdelivr.net/npm/@mdi/font@4.9.95/css/materialdesignicons.min.css';
</style>
