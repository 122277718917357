<template>
    <div v-if="aImage">
        <img :src="srcImage" alt="" :class="imgCssClass" />
    </div>
</template>
<script setup lang="ts">
    import { computed } from 'vue'
    import { ImageUtil } from './helpers/imageutil'

    interface IPropsImage {
        image?: object
        imgCssClass?: string
    }

    const props = withDefaults(defineProps<IPropsImage>(), {
        imgCssClass: '',
    })

    const aImage = computed(() => {
        return !ImageUtil.EstImageNulle(props.image as { extension: string; contenuBase64: string })
    })

    const srcImage = computed(() => {
        return ImageUtil.GetImageBase64Source(props.image as { extension: string; contenuBase64: string })
    })
</script>
