//sert a faire la traduction dans les web components
//ATTENTION, NE PAS UTILISER EN MODE SPA, JUSTE DANS LES WEB COMPONENTS
import { I18n } from 'i18n-js'
import en from './en.json'
import fr from './fr.json'

export const i18n = new I18n({
    en: en,
    fr: fr,
})

i18n.defaultLocale = 'fr'
i18n.locale = 'fr'
