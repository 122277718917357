import { del } from './clients/delclient'
import { settings } from '@shared/settings'
import axios from 'axios'
import loadingSpinner from './shared/loading-spinner'
import { i18n } from '@/locales/i18n-wc'

export default class DemandeEnLigneVM {
    codeOrganisme?: string | undefined
    noSuccursale?: number
    codeDemandeWeb?: string | undefined
    culture?: string | undefined
    prenom?: string | undefined
    nom?: string | undefined
    telephoneDemandeur?: del.Telephone
    telephoneAutre?: del.Telephone
    courrielEmail?: string | undefined
    dateNaissance?: Date
    sexe?: string | undefined
    nas?: string | undefined
    revenuTotal?: del.Money
    personneAJoindre?: string | undefined
    personneAJoindreTelephone?: del.Telephone
    statutDeResidence?: string | undefined
    paysDeNaissance?: string | undefined
    langue?: string | undefined
    adresseActuelle?: del.Adresse
    adresseActuelleDepuis?: Date
    adressePrec?: del.Adresse
    adressePrecDe?: Date | undefined
    adressePrecA?: Date | undefined
    adressesPrecedentes?: del.AdressePrecedenteModel[] | undefined
    typeDemandeur?: del.TypeDemandeur
    nombrePieces?: string | undefined
    loyerMensuel?: del.Money
    serviceChauffage?: boolean
    serviceElectricite?: boolean
    serviceEauChaude?: boolean
    serviceAutre?: boolean
    nomProprietaire?: string | undefined
    noProprietaire?: del.Telephone
    soldeHypotheque?: string | undefined
    typeChambreur?: del.TypeChambreur
    etudiantTempsPlein?: boolean
    etudiantTempsPartiel?: boolean
    handicapMoteur?: boolean
    pensionAlimentaire?: boolean | undefined
    pensionVeteran?: boolean | undefined
    assuranceInvalidite?: boolean | undefined
    utiliseGardePartagee?: boolean
    persons?: del.Person[] | undefined
    expulsion?: boolean | undefined
    deguerpi?: boolean | undefined
    detteHLM?: boolean | undefined
    faillite?: boolean | undefined
    punaise?: boolean | undefined
    valeurtotal?: boolean | undefined
    possedeVoiture?: boolean | undefined
    vehicules?: del.Vehicule[] | undefined
    autonomieTachesMenages?: del.Autonomie
    autonomieHygiene?: del.Autonomie
    autonomieSante?: del.Autonomie
    autonomieRepas?: del.Autonomie
    utiliseValeurBiens?: boolean
    territoiresSelectionnes?: string[] | undefined
    estHlmPslPrive?: boolean
    obnl?: del.Obnl
    coop?: del.Coop
    utiliseProgrammeHlm?: boolean
    utiliseProgrammeCoop?: boolean
    utiliseAtelierFederationCoop?: boolean
    utiliseProgrammeObnl?: boolean
    possedePiecePreuveAge?: boolean | undefined
    possedePieceRevenus?: boolean | undefined
    readonly preuveAge?: boolean
    readonly preuveRevenus?: boolean
    fichierJoints?: del.FichierJoint[] | undefined
    encadrementNecessaire?: string | undefined
    autreCommentaire?: string | undefined
    etesVousDemandeur?: boolean
    prenomDemandeurPour?: string | undefined
    nomDemandeurPour?: string | undefined
    lienDemandeurPour?: string | undefined
    telephoneDemandeurPour?: del.Telephone
    courrielDemandeurPourEmail?: string | undefined
    confirmAutorisation?: boolean
    accepteRenseignements?: boolean
    accepteVerificationsOmh?: boolean
    lienParentePourGardePartagee: string
    adresseOrganisme?: string | undefined
    courrielOrganisme?: string | undefined
    telephoneOrganisme?: string | undefined
    montantLiquiditeExcedant?: string | undefined
    pourcentageGardePartage?: number
    version?: del.VersionViewModel
    validerSurOnglet?: boolean
    fichiersJointsMaxBytes?: number
    fichierJointMaxLongueurNom?: number
    formulaireCompleteVisible?: boolean
    utiliseMultilangue?: boolean
    langues?: string[] | undefined
    territoires?: del.ListItem[] | undefined
    marquesVehicule?: del.ListItem[] | undefined
    sexes?: del.ListItem[] | undefined
    lienParentes?: del.ListItem[] | undefined
    lienParentesEstSansSousTutelleChefOuConjointPossible?: string[] | undefined
    paysNaissance?: del.ListItem[] | undefined
    statutsResidence?: del.ListItem[] | undefined
    listeTypeDeLogement?: del.ListItem[] | undefined
    listeSoldeHypotheque?: del.ListItem[] | undefined
    listePointServices?: del.ListItem[] | undefined
    listeLangueParlee?: del.ListItem[] | undefined
    lienParentesDemandeur?: del.ListItem[] | undefined
    implicationOrganisme?: del.ListItem[] | undefined
    serviceDesires?: del.ListItem[] | undefined
    responsabiliteCoop?: del.ListItem[] | undefined
    imageLogo?: del.ImageModel
    imageIcone?: del.ImageModel
    dicImageTerritoire?: { [key: string]: del.ImageModel } | undefined
    dicNomOrganisme?: { [key: string]: string } | undefined
    dicUrlSiteAccueil?: { [key: string]: string } | undefined
    dicUrlDocumentTerritoires?: { [key: string]: string } | undefined
    dicUrlDocumentPiecesJustificatives?: { [key: string]: string } | undefined

    constructor(langue: 'fr'|'en'){
        loadingSpinner.start()        
        this.loyerMensuel = new del.Money()
        this.telephoneAutre = new del.Telephone()
        this.telephoneDemandeur= new del.Telephone()
        this.personneAJoindreTelephone= new del.Telephone()
        this.adresseActuelle= new del.Adresse()
        this.adressePrec= new del.Adresse()
        this.adressesPrecedentes= []
        this.noProprietaire= new del.Telephone()
        this.typeChambreur= new del.TypeChambreur()
        this.typeDemandeur= new del.TypeDemandeur()
        this.typeDemandeur.value = del.TypeDemandeurEnum.Locataire
        this.persons= []
        this.revenuTotal = new del.Money()
        this.vehicules= []
        this.autonomieTachesMenages= new del.Autonomie()
        this.autonomieHygiene= new del.Autonomie()
        this.autonomieSante= new del.Autonomie()
        this.autonomieRepas= new del.Autonomie()
        this.fichierJoints= []
        this.telephoneDemandeurPour= new del.Telephone()
        this.utiliseGardePartagee= false
        this.lienParentePourGardePartagee = "Invalide"
        this.culture = langue;
        i18n.locale = langue                
    }

    remplirPourDebug() {
        this.prenom = 'aaa'
        this.nom = 'bbb'
        this.telephoneDemandeur = new del.Telephone()
        this.telephoneDemandeur!.ext = '555'
        this.telephoneDemandeur!.telephone = '(555) 555-5555'
        this.telephoneAutre = new del.Telephone()
        this.telephoneAutre!.telephone = '(666) 666-6666'
        this.telephoneAutre!.ext = '6666'
        this.courrielEmail = 'nicolas.prevereau@cogiweb.com'
        this.dateNaissance = new Date(1965, 0, 1)
        this.sexe = 'M'
        this.nas = '999 999 998'
        this.personneAJoindre = 'Personne Ajoindre'
        this.personneAJoindreTelephone = new del.Telephone()
        this.personneAJoindreTelephone!.telephone = '(777) 777-7777'
        this.personneAJoindreTelephone!.ext = '7777'
        this.statutDeResidence = 'CAN'
        this.paysDeNaissance = 'CA'
        this.langue = '1'

        this.adresseActuelle!.noRue = '3740, rue Jean-Marchand'
        this.adresseActuelle!.codePostal = 'G2C 1Y6'
        this.adresseActuelle!.ville = 'Québec'
        this.adresseActuelleDepuis = new Date(2020, 0, 1)
        this.typeDemandeur!.value = del.TypeDemandeurEnum.Proprietaire

        this.soldeHypotheque = 'ZeroCinquante'

        this.revenuTotal = new del.Money({ value: 15000 })
        this.pensionAlimentaire = false
        this.pensionVeteran = false
        this.assuranceInvalidite = false

        this.expulsion = false
        this.deguerpi = false
        this.detteHLM = false
        this.faillite = false
        this.punaise = false
        this.valeurtotal = false
        this.possedeVoiture = false
        this.autonomieTachesMenages!.estAutonome = true
        this.autonomieHygiene!.estAutonome = true
        this.autonomieSante!.estAutonome = false
        this.autonomieRepas!.estAutonome = false
    }

    async initialiserVM(noSuccursale: number, c: string) {
        this.adresseActuelle!.noRue = ''
        this.adresseActuelle!.ville = ''
        this.adresseActuelle!.app = ''
        this.adresseActuelle!.codePostal = ''

        const client = new del.DemandeEnLigneClient(settings.bffBaseUrl)
        const vm2 = await client.choix(c, noSuccursale, this.culture)

        this.codeOrganisme = vm2.codeOrganisme
        this.noSuccursale = vm2.noSuccursale
        this.version = vm2.version
        this.langues = vm2.langues
        this.territoires = vm2.territoires
        this.marquesVehicule = vm2.marquesVehicule
        this.sexes = vm2.sexes
        this.lienParentes = vm2.lienParentes
        this.lienParentesEstSansSousTutelleChefOuConjointPossible = vm2.lienParentesEstSansSousTutelleChefOuConjointPossible
        this.paysNaissance = vm2.paysNaissance
        this.statutsResidence = vm2.statutsResidence
        this.listeTypeDeLogement = vm2.listeTypeDeLogement
        this.listeSoldeHypotheque = vm2.listeSoldeHypotheque
        this.listePointServices = vm2.listePointServices
        this.listeLangueParlee = vm2.listeLangueParlee
        this.lienParentesDemandeur = vm2.lienParentesDemandeur
        this.implicationOrganisme = vm2.implicationOrganisme
        this.serviceDesires = vm2.serviceDesires
        this.responsabiliteCoop = vm2.responsabiliteCoop
        this.imageLogo = vm2.imageLogo
        this.imageIcone = vm2.imageIcone
        this.utiliseMultilangue = vm2.utiliseMultilangue

        //this.lienParentePourGardePartagee = vm2.lienParentePourGardePartagee

        this.dicUrlDocumentPiecesJustificatives = vm2.dicUrlDocumentPiecesJustificatives
        this.dicImageTerritoire = vm2.dicImageTerritoire
        this.dicNomOrganisme = vm2.dicNomOrganisme
        this.dicUrlDocumentTerritoires = vm2.dicUrlDocumentTerritoires
        this.dicUrlSiteAccueil = vm2.dicUrlSiteAccueil        

        this.obnl = vm2.obnl
        this.coop = vm2.coop

        //Pourrait changer si on demande une culture non supportée par le pilotage
        if(this.culture !== vm2.culture)
        {
            this.culture = vm2.culture!
            i18n.locale = this.culture
        }

        if(import.meta.env.DEV)  this.remplirPourDebug()

        loadingSpinner.stop()

        return this
    }

    obtenirDemande(): del.DemandeViewModel {
        const modelStringify = JSON.stringify(this)
        const modelCopy = JSON.parse(modelStringify)

        // on vide les listes et toutes les propriétés de "DemandeViewModel" qui ne sont pas requises lors du post.
        modelCopy.version =
            modelCopy.langues =
            modelCopy.territoires =
            modelCopy.marquesVehicule =
            modelCopy.sexes =
            modelCopy.lienParentes =
            modelCopy.lienParentesEstSansSousTutelleChefOuConjointPossible =
            modelCopy.paysNaissance =
            modelCopy.statutsResidence =
            modelCopy.listeTypeDeLogement =
            modelCopy.listeSoldeHypotheque =
            modelCopy.listePointServices =
            modelCopy.listeLangueParlee =
            modelCopy.lienParentesDemandeur =
            modelCopy.implicationOrganisme =
            modelCopy.serviceDesires =
            modelCopy.responsabiliteCoop =
            modelCopy.imageIcone =
            modelCopy.imageLogo =
            modelCopy.dicImageTerritoire =
            modelCopy.dicNomOrganisme =
            modelCopy.dicUrlSiteAccueil =
            modelCopy.dicUrlDocumentTerritoires =
            modelCopy.dicUrlDocumentPiecesJustificatives =
                undefined

        // Traitements spéciaux pour les fichierJoints (on les ajoute au formdata directement plus tard)
        modelCopy.fichierJoints = undefined

        if (!modelCopy.adressePrec) {
            modelCopy.adressePrec = new del.Adresse({})
            modelCopy.adressePrecDe = undefined
            modelCopy.adressePrecA = undefined
        }

        //HACK pour probleme serializer de value object... 
        modelCopy.TypeDemandeur = this.typeDemandeur?.value
        modelCopy.typeDemandeur = undefined

        return modelCopy
    }

    soumettre(files: Array<File>, callback: (value: string) => void): void {
        const formData: FormData = new FormData()

        loadingSpinner.start()
        const model = this.obtenirDemande()
        const modelString = JSON.stringify(model)
        if (files) {
            files.forEach(f => formData.append('file', f, f.name))
        }

        //on envoie directement par axios parce que le client nswag supporte mal les formData
        formData.append('model', modelString)
        axios.post(settings.bffBaseUrl + '/del/demande_en_ligne', formData)
            .then(response => callback(response.data))
            .then(() => loadingSpinner.stop())
    }
}
