<template>
    <div :class="cssClass">
        <cogi-label cssClass="grid-full" :required="required" :label="label"></cogi-label>
        <cogi-checkbox cssClass="grid-full" :onChange="manageError" v-if="tousVisible" v-model="selectAll" label="Tous" :hasError="hasError" />
        <div class="grid-6">
            <div :class="'gridspan' + 6 / nbColonnesAffichage">
                <cogi-checkbox
                    v-for="item in getItemsColonne(1)"
                    :key="item.listItemId"
                    :onChange="emitInput"
                    :label="loadDescription(item)"
                    :dontTranslate="true"
                    v-model="(item as any).selectionne"
                    :hasError="hasError"></cogi-checkbox>
            </div>
            <div v-if="nbColonnesAffichage > 1" :class="'gridspan' + 6 / nbColonnesAffichage">
                <cogi-checkbox
                    v-for="item in getItemsColonne(2)"
                    :key="item.listItemId"
                    :onChange="emitInput"
                    :label="loadDescription(item)"
                    :dontTranslate="true"
                    v-model="(item as any).selectionne"
                    :hasError="hasError"></cogi-checkbox>
            </div>
            <div v-if="nbColonnesAffichage > 2" :class="'gridspan' + 6 / nbColonnesAffichage">
                <cogi-checkbox
                    v-for="item in getItemsColonne(3)"
                    :key="item.listItemId"
                    :onChange="emitInput"
                    :label="loadDescription(item)"
                    :dontTranslate="true"
                    v-model="(item as any).selectionne"
                    :hasError="hasError"></cogi-checkbox>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import type { del } from './../clients/delclient'

    interface IPropsCheckboxgroup {
        modelValue?: string[]
        label?: string
        sourceItems: del.ListItem[]
        tousVisible?: boolean
        codeVisible?: boolean
        required: boolean
        cssClass: string
        valideNow?: boolean
        culture: string
        nbColonnesAffichage?: number
    }

    const props = withDefaults(defineProps<IPropsCheckboxgroup>(), {
        label: '',
        required: false,
        cssClass: '',
        valideNow: false,
        culture: 'fr',
        nbColonnesAffichage: 1,
    })

    const items = ref(<Array<del.ListItem>>[])
    const hasError = ref(false)

    const selectAll = computed({
        get() {
            return items.value.every(function (item: del.ListItem) {
                return (item as any).selectionne
            })
        },
        set() {
            for (var i = 0; i < items.value.length; i++) ChangeSelectionneItems(i)
            console.log(items.value)
        },
    })

    const emit = defineEmits<{
        'update:modelValue': [Array<string | undefined>]
        validated: [boolean]
    }>()

    items.value = props.sourceItems

    for (var i = 0; i < items.value.length; i++) ChangeSelectionneItems(i)

    function loadDescription(item: del.ListItem) {
        if (props.codeVisible) {
            if (props.culture == 'fr') return item.value + '-' + item.description
            else return item.value + '-' + item.descriptionAnglais
        } else {
            if (props.culture == 'fr') return item.description
            else return item.descriptionAnglais
        }
    }

    function ChangeSelectionneItems(index: number) {
        let copie = items.value[index]
        // à décommenter, bug avec le cast as any
        //copie.selectionne = selectionne
        items.value[index] = copie
    }

    function validate() {
        hasError.value = items.value.every(function (item: del.ListItem) {
            return !(item as any).selectionne
        })
        emit('validated', hasError.value)
    }

    function manageError() {
        hasError.value = false
    }

    function emitInput() {
        manageError()

        let selectedItems = items.value.filter(function (item: del.ListItem) {
            return (item as any).selectionne
        })
        let selectedValues = selectedItems.map(function (item: del.ListItem) {
            return item.value
        })
        emit('update:modelValue', selectedValues)
    }

    function getNbElementsColonne(rangColonne: number) {
        if (rangColonne > props.nbColonnesAffichage) return 0

        let nbElements = Math.floor(items.value.length / props.nbColonnesAffichage)
        if (items.value.length % props.nbColonnesAffichage >= rangColonne) nbElements++

        return nbElements
    }

    function getItemsColonne(rangColonne: number) {
        let nbElementsColPrecedentes = 0
        for (let i = rangColonne - 1; i > 0; i--) nbElementsColPrecedentes += getNbElementsColonne(i)

        return items.value.slice(nbElementsColPrecedentes, nbElementsColPrecedentes + getNbElementsColonne(rangColonne))
    }

    watch(
        () => props.valideNow,
        newValue => {
            if (newValue) validate()
        },
    )

    watch(
        () => props.sourceItems,
        () => {
            emitInput()
        },
        {
            deep: true,
        },
    )
</script>
