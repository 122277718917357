<template>
    <div :class="cssClass" v-show="visible" ref="template">
        <cogi-inputmask
            :cssClass="cssClass"
            :hasExternalError="nasHasError"
            :label="label"
            :dontTranslateToolTip="true"
            :tooltip="tooltip"
            @validated="validate"
            :valideNow="valideNow"
            v-model="nas"
            pattern="[0-9]{3}\s[0-9]{3}\s[0-9]{3}"
            placeholder="XXX XXX XXX"
            :required="required"
            v-show="visible"></cogi-inputmask>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, getCurrentInstance } from 'vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import CogiInputmask from './CogiInputmask.vue'

    interface IPropsNas {
        required: boolean
        regex?: string
        placeholder?: string
        disabled?: boolean
        modelValue?: string
        valideNow: boolean
        cssClass: string
        label: string
        tooltip: string
        visible?: boolean
    }

    const instance = getCurrentInstance()

    const props = withDefaults(defineProps<IPropsNas>(), {
        regex: '',
        placeholder: '',
        disabled: false,
        modelValue: '',
        visible: true,
    })

    const template = ref(null)

    const nas = ref(<string>props.modelValue)
    const validatorAccumulator = ref(<ValidatorAccumulatorVueComponent>new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const nasHasError = ref(<boolean>false)

    const emit = defineEmits<{
        validated: [boolean]
        'update:modelValue': [string]
    }>()

    function validate(newValue: boolean) {
        validateNas()

        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError || nasHasError.value)
    }
    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }
    function validateNas() {
        var nasVal = nas.value
        nasHasError.value = false
        if (!nasVal) return
        while (nasVal.indexOf(' ') > -1) nasVal = nasVal.replace(' ', '')
        if (nasVal.length === 9) {
            var mask = '121212121'
            var sum = 0
            for (var i = 0; i < nasVal.length; ++i) {
                var res = (Number(nasVal[i]) * parseInt(mask[i])).toString()
                for (var j = 0; j < res.length; ++j) sum += parseInt(res[j])
            }
            if (sum % 10 !== 0) {
                nasHasError.value = true
            }
        } else {
            nasHasError.value = true
        }
    }

    watch(
        () => nas.value,
        val => {
            emit('update:modelValue', val)
        },
        { deep: true },
    )

    watch(
        () => nas.value,
        (newValue: string) => {
            nas.value = newValue
        },
    )

    watch(
        () => props.valideNow,
        (newValue: boolean) => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
