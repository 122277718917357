<template>
    <div :class="cssClass" ref="template">
        <div class="grid">
            <hr v-if="hrVisible" class="grid-full" />
            <div v-if="visible" class="cogi-block-title grid-half">
                <cogi-label-only label="MembreDuMenage" />
            </div>
            <cogi-button v-if="visible" cssClass="grid-half right" cssClassButton="retirer" text="RetirerCettePersonne" @click="retirerPersonne"></cogi-button>

            <cogi-regex
                cssClass="grid-half"
                @validated="validate"
                label="Prenom"
                :valideNow="valideNow"
                :required="!estChefMenage"
                v-model="personne.prenom"
                :disabled="disabled"></cogi-regex>
            <cogi-regex
                cssClass="grid-half"
                @validated="validate"
                :valideNow="valideNow"
                label="Nom"
                :required="!estChefMenage"
                v-model="personne.nom"
                :disabled="disabled"></cogi-regex>

            <cogi-date
                :visible="visible"
                :culture="culture"
                cssClass="grid-half"
                @validated="validate"
                label="DateDeNaissance"
                :valideNow="valideNow"
                v-model="personne.dateNaissance"
                :required="true"></cogi-date>
            <cogi-dropdownlist
                :visible="visible"
                :culture="culture"
                cssClass="grid-half"
                @validated="validate"
                :valideNow="valideNow"
                label="Sexe"
                :sourceItems="sourceItemsSexe"
                v-model="personne.sexe"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-nas
                :visible="visible"
                cssClass="grid-half"
                @validated="validate"
                :valideNow="valideNow"
                v-model="personne.nas"
                :required="false"
                label="NAS"
                tooltip="<img src='/images/del/NAS.png' />"></cogi-nas>
            <cogi-dropdownlist
                :visible="visible"
                :culture="culture"
                cssClass="grid-half"
                label="LienAvecLeChefDeMenage"
                @validated="validate"
                :valideNow="valideNow"
                :sourceItems="sourceItemsLienParente"
                v-model="personne.lien"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-dropdownlist
                :visible="visible"
                :culture="culture"
                cssClass="grid-half"
                label="StatutDeResidence"
                @validated="validate"
                :valideNow="valideNow"
                :sourceItems="sourceItemsStatutResidence"
                v-model="personne.statutDeResidence"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>
            <cogi-dropdownlist
                :visible="visible"
                :culture="culture"
                cssClass="grid-half"
                @validated="validate"
                :valideNow="valideNow"
                label="PaysDeNaissance"
                :sourceItems="sourceItemsPaysNaissance"
                v-model="personne.paysDeNaissance"
                :showEmptyItem="true"
                :required="true"></cogi-dropdownlist>

            <cogi-label
                class="grid-half verticalCenter"
                :required="estRevenuTotalObligatoire"
                label="RevenuTotal"
                :dontTranslateToolTip="true"
                :tooltip="tooltipPiecesJustificatives"></cogi-label>
            <cogi-monetaire
                cssClass="grid-half left PaddingMinCOGI"
                @validated="validate"
                :valideNow="valideNow"
                v-model="personne.revenuTotal!.value"
                :required="estRevenuTotalObligatoire"></cogi-monetaire>

            <cogi-label cssClass="grid-half" :label="libellePersonneAuxEtudes" tooltip="EstConsidereCommeEtudiantTempsPlein"></cogi-label>

            <cogi-checkbox cssClass="gridspan4" v-model="personne.etudiantTempsPlein" label="TempsPlein" :onChange="tempsPleinChange" />
            <cogi-checkbox cssClass="gridspan4" v-model="personne.etudiantTempsPartiel" label="TempsPartiel" :onChange="tempsPartielChange" />

            <cogi-label cssClass="grid-half PaddingMinCOGI" :label="libelleHandicapMoteur"></cogi-label>
            <cogi-checkbox cssClass="grid-half PaddingMinCOGI" v-model="personne.handicapMoteur" label="Oui" />

            <cogi-label :visible="sousTutelleChefOuConjointVisible" cssClass="grid-half PaddingMinCOGI" :label="libelleSousTutelleChefOuConjoint"></cogi-label>
            <cogi-checkbox
                :visible="sousTutelleChefOuConjointVisible"
                cssClass="grid-half PaddingMinCOGI"
                v-model="personne.sousTutelleChefOuConjoint"
                label="Oui" />

            <cogi-yesno
                :visible="afficherSectionGardePartagee"
                cssClass="grid-full"
                @validated="validate"
                :isLabelOnTop="true"
                :valideNow="valideNow"
                :label="obtenirLibelleGardePartage()"
                :dontTranslate="true"
                groupName="yesNoGardePartage"
                :required="true"
                v-model="personne.gardeA40Pct"></cogi-yesno>

            <cogi-yesno
                :visible="afficherSectionQuestionPensionAssurance"
                cssClass="grid-full"
                cssClassLabel="grid-half"
                cssClassRadio="grid-half left"
                @validated="validate"
                :valideNow="valideNow"
                :label="libellePensionAlimentaire"
                groupName="yesNoPensionAlimentaire"
                :required="true"
                v-model="personne.pensionAlimentaire"></cogi-yesno>
            <cogi-yesno
                :visible="afficherSectionQuestionPensionAssurance"
                cssClass="grid-full"
                cssClassLabel="grid-half"
                cssClassRadio="grid-half left"
                @validated="validate"
                :valideNow="valideNow"
                :label="libellePensionVeteran"
                groupName="pensionVeteran"
                :required="true"
                v-model="personne.pensionVeteran"></cogi-yesno>
            <cogi-yesno
                :visible="afficherSectionQuestionPensionAssurance"
                cssClass="grid-full"
                cssClassLabel="grid-half"
                cssClassRadio="grid-half left"
                @validated="validate"
                :valideNow="valideNow"
                :label="libelleRevenuAssuranceInvalidite"
                groupName="ysnAssuranceInvalidite"
                :required="true"
                v-model="personne.assuranceInvalidite"></cogi-yesno>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch, getCurrentInstance } from 'vue'
    import CogiRegex from './CogiRegex.vue'
    import CogiDropdownlist from './CogiDropdownlist.vue'
    import CogiLabel from './CogiLabel.vue'
    import CogiButton from './CogiButton.vue'
    import CogiLabelOnly from './CogiLabelOnly.vue'
    import CogiCheckbox from './CogiCheckbox.vue'
    import CogiNas from './CogiNas.vue'
    import CogiMonetaire from './CogiMonetaire.vue'
    import CogiDate from './CogiDate.vue'
    import CogiYesno from './CogiYesno.vue'
    import { ValidatorAccumulatorVueComponent, ValidatorAccumulator, ValidationStatutPoco } from './helpers/validatorAccumulator'
    import { DateUtil } from './helpers/dateutil'
    import { i18n } from '../../../locales/i18n-wc'
    import type { del } from './../clients/delclient'

    interface IPropsPersonne {
        sourceItemsSexe: del.ListItem[]
        sourceItemsLienParente: del.ListItem[]
        sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible: Array<string>
        sourceItemsPaysNaissance: del.ListItem[]
        sourceItemsStatutResidence: del.ListItem[]
        modelValue: del.Person
        valideNow: boolean
        cssClass: string
        estChefMenage?: boolean
        lienParentePourGardePartagee: string
        utiliseGardePartagee: boolean
        currentIndex?: number
        urlPiece: string
        pourcentageGardePartage: unknown
        culture: string
    }

    const props = withDefaults(defineProps<IPropsPersonne>(), {
        valideNow: false,
        cssClass: '',
        estChefMenage: false,
        lienParentePourGardePartagee: 'Invalide',
        utiliseGardePartagee: true,
        currentIndex: 0,
        urlPiece: '',
        culture: 'fr',
    })

    const personne = ref(props.modelValue)
    const validatorAccumulator = ref(new ValidatorAccumulatorVueComponent(new ValidatorAccumulator()))
    const instance = getCurrentInstance()

    const emit = defineEmits<{
        retirerPersonne: []
        validated: [boolean]
        'update:modelValue': [del.Person]
    }>()

    const visible = computed(() => {
        return !props.estChefMenage
    })

    const hrVisible = computed(() => {
        return props.currentIndex! > 0
    })

    const sousTutelleChefOuConjointVisible = computed(() => {
        return (
            personne.value.lien != undefined &&
            personne.value.lien != '' &&
            !props.sourceItemsLienParentesEstSansSousTutelleChefOuConjointPossible.includes(personne.value.lien)
        )
    })

    const disabled = computed(() => {
        return props.estChefMenage
    })

    const afficherSectionGardePartagee = computed(() => {
        return props.utiliseGardePartagee && !props.estChefMenage && personne.value.lien == props.lienParentePourGardePartagee
    })

    const estEtudiant = computed(() => {
        return personne.value.etudiantTempsPlein || personne.value.etudiantTempsPartiel
    })

    const estMajeur = computed(() => {
        return obtenirEstMajeur(personne.value.dateNaissance)
    })

    const estRevenuTotalObligatoire = computed(() => {
        return props.estChefMenage || (estMajeur.value && !estEtudiant.value)
    })

    const afficherSectionQuestionPensionAssurance = computed(() => {
        return !estEtudiant.value && (props.estChefMenage || estMajeur.value)
    })

    const tooltipPiecesJustificatives = computed(() => {
        return i18n.t('InscrireLeRevenuTotalApproximatif') + "<a target='_blank' href = '" + props.urlPiece + "'> " + i18n.t('CotisationProvinciale') + ' </a>'
    })

    const libellePensionAlimentaire = computed(() => {
        return props.estChefMenage ? 'RecevezVousUnePensionAlimentaire' : 'CettePersonneRecoitElleUnePensionAlmentaire'
    })

    const libellePensionVeteran = computed(() => {
        return props.estChefMenage ? 'RecevezVousUnePensionDeVeteran' : 'CettePersonneRecoitElleUnePensionDeVeteran'
    })

    const libelleRevenuAssuranceInvalidite = computed(() => {
        return props.estChefMenage ? 'RecevezVousUneAssuranceInvalidite' : 'CettePersonneRecoitElleUneAssuranceInvalidite'
    })

    const libellePersonneAuxEtudes = computed(() => {
        return props.estChefMenage ? 'SiVousEtesPresentementAuxEtudes' : 'SiCettePersonneEstPresentementAuxEtudes'
    })

    const libelleHandicapMoteur = computed(() => {
        return props.estChefMenage ? 'EtesVousAtteintDunHandicapMoteur' : 'CettePersonneEstElleAtteinteDunHandicapMoteur'
    })

    const libelleSousTutelleChefOuConjoint = computed(() => {
        return sousTutelleChefOuConjointVisible.value ? 'CettePersonneEstElleSousVotreTutelle' : ''
    })

    function retirerPersonne() {
        emit('retirerPersonne')
    }

    function validate(newValue: boolean) {
        let validationStatut: ValidationStatutPoco = validatorAccumulator.value.validated(newValue)

        if (!validationStatut.validationCompleted) return

        emitValidated(validationStatut.hasError)
    }

    function emitValidated(hasError: boolean) {
        emit('validated', hasError)
    }

    function obtenirEstMajeur(dateDeNaissance: Date | undefined): boolean {
        if (dateDeNaissance === undefined) return false
        return DateUtil.estMajeur(dateDeNaissance)
    }

    function tempsPleinChange(): void {
        if (!personne.value.etudiantTempsPlein) return
        personne.value.etudiantTempsPartiel = false
    }

    function tempsPartielChange(): void {
        if (!personne.value.etudiantTempsPartiel) return
        personne.value.etudiantTempsPlein = false
    }

    function obtenirLibelleGardePartage(): string {
        return i18n.t('SiVousEtesDansUneSituationDeGardePartagee') + ' ' + props.pourcentageGardePartage + i18n.t('PourcentDuTempsOuPlus')
    }

    watch(
        () => props.modelValue,
        newValue => {
            ;(personne.value = newValue), { deep: true }
        },
    )

    watch(
        () => personne.value,
        newValue => {
            emit('update:modelValue', newValue), { deep: true }
        },
    )

    watch(
        () => props.valideNow,
        newValue => {
            if (!newValue) return

            let startValidation = validatorAccumulator.value.startValidation(instance)

            if (!startValidation) emitValidated(false)
        },
    )
</script>
